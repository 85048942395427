import React, { FunctionComponent, useMemo } from 'react';
import { Box } from 'rebass';

import Add from './Add';
import Info from './Info';
import { Project } from '../../../models/project';
import { Account } from '../../../models/other-services';
import { FloatingCard } from '../../UI/FloatingCard';
import { usePermission } from '../../../hooks/use-permission';

interface AWSProps {
  project: Project;
  createAccount: (
    description: string,
    email?: string,
    accountType?: string
  ) => Promise<void>;
  inviteAccount: (accountId: string, description: string) => Promise<void>;
  updateDescription: (description: string, accountId: string) => Promise<void>;
  accounts: Account[];
}

const AWS: FunctionComponent<AWSProps> = props => {
  const canManage = usePermission('manage', 'awsaccount');
  const sortedAccounts = useMemo(() => {
    const accountsCopy = [...props.accounts];
    accountsCopy.sort((accountA, accountB) => {
      if (accountA.accountId > accountB.accountId) return 1;
      if (accountA.accountId < accountB.accountId) return -1;
      return 0;
    });
    const { managed, unmanaged } = accountsCopy.reduce(
      (accumulator: { managed: Account[]; unmanaged: Account[] }, account) => {
        if (account.accountType === 'managed')
          accumulator.managed.push(account);
        else accumulator.unmanaged.push(account);

        return accumulator;
      },
      { managed: [] as Account[], unmanaged: [] as Account[] }
    );

    return unmanaged.concat(managed);
  }, [props]);

  if (!canManage && sortedAccounts.length <= 0) {
    return (
      <FloatingCard title="No AWS Accounts">
        This project has no associated AWS accounts currently. Please file a
        ticket to request the creation or invitation of an account.
      </FloatingCard>
    );
  }

  return (
    <>
      {canManage && (
        <Box mb={4}>
          <Add
            hasManagedAccount={
              !!props.accounts.find(
                account => account.accountType === 'managed'
              )
            }
            createAccount={props.createAccount}
            inviteAccount={props.inviteAccount}
          />
        </Box>
      )}
      {sortedAccounts.map(account => (
        <Box mb={4} key={account.accountId}>
          <Info
            info={account}
            canManage={canManage}
            updateDescription={props.updateDescription}
          />
        </Box>
      ))}
    </>
  );
};

export default AWS;
