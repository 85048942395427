import { ProjectShortName } from '../project';

export enum Services {
  Jira = 'Jira',
  Confluence = 'Confluence',
  AWS = 'AWS',
  SlackIn = 'SlackIn'
}

export type ServicesTuple =
  | { type: Services.Jira; info: Jira }
  | { type: Services.Confluence; info: Confluence };

export interface Account {
  project: ProjectShortName;
  accountId: string;
  accountType: 'managed' | 'unmanaged';
  status: 'active' | 'suspended';
  email: string;
  description: string;
}

export enum AtlassianServiceStatuses {
  Pending = 'pending_creation',
  Accessible = 'accessible',
  NotAccessible = 'not_accessible'
}

export interface AtlassianService {
  project: ProjectShortName;
  id: string;
  url: string;
  jsdTicketUrl: string;
  status: AtlassianServiceStatuses;
  configured: boolean;
}

export interface Confluence extends AtlassianService {}

export interface ConfluenceSystemInfo {
  version: string;
}
export interface Jira extends AtlassianService {}

export interface JiraSystemInfo {
  version: string;
  license: JiraSiteLicenseInfo;
}

export interface JiraSiteLicenseInfo {
  valid: boolean;
  organization: string;
  licenseType: JiraLicenses;
  expiryDateString: string;
  maximumNumberOfUsers: number;
}

export enum JiraLicenses {
  Academic = 'Academic',
  Commercial = 'Commercial',
  Community = 'Community',
  Demonstration = 'Demonstration',
  Developer = 'Developer',
  Hosted = 'Hosted',
  NonProfit = 'Non_profit',
  OpenSource = 'Open_source',
  Personal = 'Personal',
  Starter = 'Starter',
  Testing = 'Testing'
}

export interface AtlassianBody {
  project: ProjectShortName;
}

export interface SlackIn {
  project: ProjectShortName;
  id: string;
  url: string;
  status: 'created' | 'pending_creation';
  team: string;
}
