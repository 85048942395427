import { Status, Actions } from './types';

/**
 * This constant describes the possible states of the PKCE Provider and the relationships between them.
 * The top-level key is the state name.
 * The sub-keys are the _only_ valid dispatchable actions during that state.
 * The sub-value is the resulting state of a particular action.
 *
 * This state graph is enforced by the reducer in this directory.
 */

export const AUTH_STATE_GRAPH: {
  [currentState: string]: {
    [actionType: string]: Status;
  };
} = {
  errored: {
    [Actions.RETRY]: 'authenticating'
  },
  authenticating: {
    [Actions.DENY]: 'unauthorized',
    [Actions.ALLOW]: 'authorized',
    [Actions.ERR]: 'errored'
  },
  authorized: {
    [Actions.DENY]: 'unauthorized',
    [Actions.ERR]: 'errored'
  },
  unauthorized: {
    [Actions.RETRY]: 'authenticating'
  }
};
