import React from 'react';

import CardArea from '../UI/CardArea';
import styled from '../../utils/styled';
import { List } from './List';
import { Invite } from './Invite';
import { usePermission } from '../../hooks/use-permission';

const ZoomLayout = styled.div<{ canManage?: boolean }>`
  display: grid;
  grid-template-areas: ${props =>
    props.canManage ? "'invite list'" : "'list'"};
  grid-template-columns: 1fr ${props => (props.canManage ? '2fr' : '')};
  gap: 36px;
`;

interface ZoomProps {
  projectName: string;
}

export function Zoom(props: ZoomProps) {
  const canManage = usePermission('manage', 'zoom');

  return (
    <ZoomLayout canManage={canManage}>
      {canManage && (
        <CardArea gridArea="invite">
          <Invite projectName={props.projectName} />
        </CardArea>
      )}
      <CardArea gridArea="list">
        <List projectName={props.projectName} showActions={canManage} />
      </CardArea>
    </ZoomLayout>
  );
}
