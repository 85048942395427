import React, { FC, useState, createContext } from 'react';
import { Box, Heading } from 'rebass';

import { About } from './About';
import Spinner from '../UI/Spinner';
import styled from '../../utils/styled';
import { Associate } from './Associate';
import { Org } from '../../models/github/org';
import { Project } from '../../models/project';
import { List, ListItemValue } from '../UI/List';
import { constructItemsFromOrgs } from './utils';
import { FloatingCard } from '../UI/FloatingCard';
import { useGet } from '../../hooks/use-itx-api';
import { useAsyncEffect } from '../../hooks/use-async-effect';
import { usePermission } from '../../hooks/use-permission';

const tooltips = {
  associatedOrgs: (
    <>
      <p>List of GitHub organizations associated to the project.</p>
      <ul>
        <li>Full: LF master member is an org owner</li>
        <li>Partial: LF master member is a non-owner member</li>
        <li>None: LF master member is not part of the org</li>
        <li>Not Created: Org does not exist</li>
      </ul>
    </>
  )
};

const GithubLayout = styled.div<{ hasAccess: boolean }>`
  width: 100%;
  display: grid;
  gap: 32px;
  grid-template-areas:
    ${props => (props.hasAccess ? "'associate associate'" : '')}
    'list about';
  grid-template-columns: 1fr 2fr;
`;

export const GithubContext = createContext<{
  hasAccess: boolean;
  reloadOrgs: () => Promise<void>;
  deselectOrg: () => void;
}>({
  hasAccess: false,
  reloadOrgs: () => Promise.resolve(),
  deselectOrg: () => undefined
});

export const Github: FC<{
  project: Project;
  selectedOrg?: string;
}> = props => {
  const canManage = usePermission('manage', 'github');
  const [orgs, setOrgs] = useState<Org[]>();
  const [items, setItems] = useState<ListItemValue[]>();
  const [selectedOrg, setSelectedOrg] = useState<Org>();
  const [getOrgs] = useGet<Org[]>(`github?project=${props.project.shortName}`);

  const reloadOrgs = async () => {
    try {
      const orgs = await getOrgs();
      setOrgs(orgs);
      setItems(constructItemsFromOrgs(orgs));
    } catch (error) {
      if (error.name === 'AbortError') return;
      console.log(error);
    }
  };

  const deselectOrg = () => {
    setSelectedOrg(undefined);
  };

  useAsyncEffect(reloadOrgs, []);

  return (
    <GithubContext.Provider
      value={{ hasAccess: canManage, reloadOrgs, deselectOrg }}
    >
      <GithubLayout hasAccess={canManage}>
        {canManage && (
          <Box sx={{ gridArea: 'associate' }}>
            <Associate project={props.project.shortName} />
          </Box>
        )}
        <Box sx={{ gridArea: 'list' }}>
          <FloatingCard title="Associated Orgs" tip={tooltips.associatedOrgs}>
            <Heading
              variant="subheading"
              fontSize={2}
              color="primary.grey"
              mb={1}
            >
              {orgs && items && items.length > 0
                ? 'Select an Org'
                : 'No associated orgs'}
            </Heading>
            {orgs && items ? (
              <List
                selectable={true}
                values={items}
                onSelect={name =>
                  setSelectedOrg(orgs.find(org => org.organization === name))
                }
              />
            ) : (
              <Spinner message="Fetching orgs..." />
            )}
          </FloatingCard>
        </Box>
        <Box sx={{ gridArea: 'about' }}>
          {selectedOrg && <About org={selectedOrg} />}
        </Box>
      </GithubLayout>
    </GithubContext.Provider>
  );
};
