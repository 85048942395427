import React, { createContext, FC, useState } from 'react';
import { Label, Input } from '@rebass/forms';
import { useFormState } from 'react-use-form-state';
import { Box, Button, Flex, Text, Link } from 'rebass';
import { HTTPError } from 'ky';

import { Add } from './Add';
import { List } from './List';
import Spinner from '../UI/Spinner';
import styled from '../../utils/styled';
import { Project } from '../../models/project';
import { emailRegex } from '../../utils/const';
import { MessageCard } from '../UI/MessageCard';
import { ServiceAdmins } from './ServiceAdmins';
import { useDialog } from '../../hooks/use-dialog';
import { Group, GroupService } from '../../models/groups';
import { useLoading } from '../../hooks/use-loading';
import { useGet, usePost } from '../../hooks/use-itx-api';
import { useAsyncEffect } from '../../hooks/use-async-effect';
import { usePermission } from '../../hooks/use-permission';

const GroupsIoLayout = styled.div<{ hasAccess?: boolean }>`
  width: 100%;
  display: grid;
  grid-gap: 32px;
  grid-template-areas:
'admins ${props => (props.hasAccess ? 'add' : 'list')}'
${props => (props.hasAccess ? "'admins list'" : '')};
  grid-template-columns: 1fr 2fr;
`;

export const GroupsIoContext = createContext<{
  hasAccess: boolean;
  project: Partial<Project>;
  reloadPage: () => Promise<void>;
  reloadGroups: () => Promise<void>;
}>({
  hasAccess: false,
  project: {},
  reloadPage: () => Promise.resolve(),
  reloadGroups: () => Promise.resolve()
});

export const GroupsIo: FC<{
  project: Project;
  // tslint:disable-next-line:max-func-body-length
}> = props => {
  const canManage = usePermission('manage', 'groupsio');
  const [isDelegated, setIsDelegated] = useState(false);
  const [{ message, isLoading }, { start, stop }] = useLoading(
    true,
    'Checking service status...'
  );
  const [isEnabled, setIsEnabled] = useState<boolean>();
  const [serviceAdmins, setServiceAdmins] = useState<string[]>([]);
  const [groups, setGroups] = useState<Group[]>([]);
  const [formState, { email, label }] = useFormState<{ genesisAdmin: string }>(
    undefined,
    { withIds: true }
  );
  const [getServiceStatus, serviceStatusLoading] = useGet<GroupService>(
    `groupsio/${props.project.shortName}`,
    false
  );
  const [getGroups] = useGet<Group[]>(
    `groupsio/${props.project.shortName}/lists`
  );
  const [enableService] = usePost<GroupService, void>('groupsio');

  const reloadGroups = async () => {
    try {
      const groups = await getGroups();
      setGroups(groups);
    } catch (error) {
      if (error.name === 'AbortError') return;
      console.log(error);
    }
  };

  const reloadPage = async () => {
    try {
      start('Checking service status...');
      const groupService = await getServiceStatus();
      setServiceAdmins(groupService.globalOwners);
      setIsEnabled(true);
      reloadGroups();
      stop();
    } catch (error) {
      if (error.name === 'AbortError') return;
      console.log(error);
      stop();
    }
  };

  function detectDelegationError(error: HTTPError) {
    switch (error.response.status) {
      case 500:
        if (
          error.response.statusText ===
          'error looking up ns records for externally-delegated domain'
        ) {
          setIsDelegated(true);
        }
        break;
      case 400:
        if (
          error.response.statusText ===
            'ns records for domain are incorrect, should point to dns1.groups.io and dns2.groups.io' ||
          error.response.statusText === 'incomplete NS records for domain'
        ) {
          setIsDelegated(true);
        }
        break;
      default:
        console.error(error);
    }
  }

  const enableServiceAndReload = async () => {
    try {
      start('Enabling service...');
      await enableService({
        project: props.project.shortName,
        globalOwners: [formState.values.genesisAdmin]
      });
      await reloadPage();
    } catch (error) {
      if (error.name === 'AbortError') return;
      detectDelegationError(error);
      stop();
    }
  };
  const [dialog, showDialog] = useDialog({
    title: 'Enable Groups.io',
    intent: 'warning',
    message:
      "Enabling Groups.io adds a monthly cost to the project's budget. Are you sure?",
    confirmLabel: 'Enable',
    onConfirm: enableServiceAndReload
  });

  useAsyncEffect(reloadPage, []);

  if (isLoading) return <Spinner message={message} />;

  if (!props.project.canonicalDomain) {
    return (
      <Flex width={1}>
        <MessageCard title="Not Available">
          In order to enable Groups.IO for this project, you first need to
          specify a primary domain. You can do this in the Domains tab.
        </MessageCard>
      </Flex>
    );
  }

  if (isDelegated) {
    return (
      <Flex width={1}>
        <MessageCard title="Error Enabling Service">
          <Text mb={3}>
            Please add NS records for{' '}
            <b>lists.{props.project.canonicalDomain}</b> to point to{' '}
            <b>dns1.groups.io</b> and <b>dns2.groups.io</b> before enabling the
            service.
          </Text>
          <Text>
            It may take some time for these records to propagate and be
            recognized in ITX. If you are still unable to enable the service,
            please file a ticket{' '}
            <Link href="https://jira.linuxfoundation.org/servicedesk/customer/portal/3/create/171">
              here.
            </Link>
          </Text>
        </MessageCard>
      </Flex>
    );
  }

  if (isEnabled) {
    return (
      <Flex width={1}>
        <GroupsIoContext.Provider
          value={{
            hasAccess: canManage,
            reloadPage,
            reloadGroups,
            project: props.project
          }}
        >
          <GroupsIoLayout hasAccess={canManage}>
            <Box sx={{ gridArea: 'admins' }}>
              <ServiceAdmins serviceAdmins={serviceAdmins} />
            </Box>
            {canManage && (
              <Box sx={{ gridArea: 'add' }}>
                <Add />
              </Box>
            )}
            <Box sx={{ gridArea: 'list' }}>
              <List groups={groups} />
            </Box>
          </GroupsIoLayout>
        </GroupsIoContext.Provider>
      </Flex>
    );
  }

  return (
    <Flex justifyContent="center" width={1}>
      {serviceStatusLoading ? (
        <Spinner message={message} />
      ) : (
        <MessageCard title="Not Enabled">
          <Text mb={2}>This project does not have Groups.Io enabled.</Text>
          <Text mb={4}>
            Enabling Groups.io adds <b>$100 per month</b> to the project's
            budget. This is an internal LF cost.
          </Text>
          {canManage ? (
            <Box as="form" onSubmit={showDialog}>
              {dialog}
              <Text mb={4}>
                To enable, please add an initial Service Administrator.
              </Text>
              <Label {...label('genesisAdmin')}>Service Admin Email</Label>
              <Input
                {...email('genesisAdmin')}
                placeholder="example@example.com"
                required
              />
              <Flex mt={2} justifyContent="flex-end">
                <Button
                  width={1}
                  minHeight="3rem"
                  variant={
                    emailRegex.test(formState.values.genesisAdmin)
                      ? 'confirm'
                      : 'disabled'
                  }
                >
                  Enable Groups.Io
                </Button>
              </Flex>
            </Box>
          ) : (
            <Flex justifyContent="center" mt={2}>
              <Text>To enable Groups.Io, you must be an admin.</Text>
            </Flex>
          )}
        </MessageCard>
      )}
    </Flex>
  );
};
