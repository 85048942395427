import React, { FC } from 'react';

import Repo from './Repo';
import { Config } from '../../../models/ci';
import styled from '../../../utils/styled';

interface ReposProps {
  configs: Config[];
  editConfig: (id: number) => void;
  deleteConfig: (id: number) => Promise<void>;
}

const ReposLayout = styled.div`
  width: 100%;
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(3, 1fr);
`;

const Repos: FC<ReposProps> = props => {
  return (
    <ReposLayout>
      {props.configs.map(config => (
        <Repo
          key={config.id}
          config={config}
          editConfig={props.editConfig}
          deleteConfig={props.deleteConfig}
        />
      ))}
    </ReposLayout>
  );
};

export default Repos;
