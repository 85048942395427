import { ProjectShortName } from '../project';

/**
 * Unique identifier for a CI configuration
 */
type ConfigID = number;

/**
 * Supported language for a pipeline.
 * Technically an enum, but we're using a string for forwards compatibility.
 */
type Language = string;

/**
 * Supported build tools for a pipeline.
 * Technically an enum, but we're using a string for forwards compatibility.
 */
type BuildTool = string;

/**
 * Supported CI/CD platform for a pipeline.
 * Technically an enum, but we're using a string for forwards compatibility.
 */
type Platform = string;

export interface Repository {
  // Provider name (GitHub, GitLab, BitBucket, etc.)
  provider: string;
  // Organization name
  org: string;
  // Repository name
  repo: string;
}

export enum ServiceTypes {
  Artifacts = 'artifacts',
  Documentation = 'documentation',
  Distribution = 'distribution',
  Containers = 'containers'
}

export interface Service {
  name: string;
  type: ServiceTypes[];
  ref: string;
  languages?: Language[];
  id: string;
}

interface Status {
  issues?: string[];
  state: 'submitted' | 'notready' | 'ready' | 'error' | 'merged' | 'verified';
}

/**
 * Interface of a pipeline configuration
 */
export interface Config {
  id: ConfigID;
  project: ProjectShortName;
  language: Language;
  buildtool: BuildTool;
  repository: Repository;
  platform: Platform;
  status: Status;
  services?: Service[];
}
