import React, { FormEvent } from 'react';
import { Flex, Button, Box } from 'rebass';
import { Input, Label } from '@rebass/forms';
import { useFormState } from 'react-use-form-state';

interface TrackFormValues {
  url: string;
}

interface TrackProps {
  trackConfluence: (url: string) => Promise<void>;
}

export default function Track(props: TrackProps) {
  const [formData, { text }] = useFormState<TrackFormValues>();

  async function onSubmit(e: FormEvent) {
    e.preventDefault();
    await props.trackConfluence(formData.values.url);
  }

  return (
    <Flex as="form" mt={3} onSubmit={onSubmit}>
      <Box width={2 / 3} pr={3}>
        <Label>Site URL</Label>
        <Input
          {...text('url')}
          required
          placeholder="https://confluence.example.com"
        />
      </Box>
      <Box width={1 / 3} pl={3}>
        <Button variant="confirm" width={1} height="100%">
          Track Site
        </Button>
      </Box>
    </Flex>
  );
}
