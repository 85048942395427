import React, { useReducer, useEffect } from 'react';
import { Flex } from 'rebass';

import Spinner from '../UI/Spinner';
import { reducer } from './reducer';
import { PKCEContext } from './context';
import { LoginPrompt } from '../LogInPrompt';
import { Actions, PKCEProviderProps } from './types';
import { client, canWe, cleanUrl } from '../../utils/auth';

export function PKCEProvider(props: PKCEProviderProps) {
  const [{ status, error, reason }, dispatch] = useReducer(reducer, {
    status: 'authenticating'
  });

  useEffect(() => {
    const verifyAuth = async () => {
      try {
        if (window.location.search?.includes('state')) {
          await client.handleRedirectCallback();
          const canView = await canWe('read', 'projects');

          if (canView) {
            cleanUrl();
            dispatch({ type: Actions.ALLOW });
          } else {
            dispatch({ type: Actions.DENY, reason: 'unauthorized' });
          }
        } else {
          const token = await client.getTokenSilently();

          if (token) {
            dispatch({ type: Actions.ALLOW });
          } else {
            client.loginWithRedirect();
          }
        }
      } catch (error) {
        if (
          error.error === 'login_required' ||
          error.error === 'consent_required'
        ) {
          client.loginWithRedirect();
        } else {
          dispatch({ type: Actions.ERR, error });
        }
      }
    };

    verifyAuth();
  }, []);

  function logout() {
    client.logout({ localOnly: true });
    dispatch({ type: Actions.DENY, reason: 'logout' });
  }

  if (status === 'errored') {
    return <LoginPrompt error={error} />;
  }

  if (status === 'unauthorized') {
    return <LoginPrompt unauthorized={reason === 'unauthorized'} />;
  }

  if (status === 'authorized') {
    return (
      <PKCEContext.Provider value={{ logout }}>
        {props.children}
      </PKCEContext.Provider>
    );
  }

  return (
    <Flex
      width="100vw"
      height="100vh"
      alignItems="center"
      justifyContent="center"
      sx={{ position: 'fixed' }}
    >
      <Spinner message="Authenticating..." />
    </Flex>
  );
}
