import React, { useState, useRef, useEffect, useCallback } from 'react';
import { css } from '@emotion/core';
import { Text, Card, Flex } from 'rebass';

import styled from '../../../utils/styled';

export interface ListItemValue {
  label: string;
  tags?: { name: string; color?: string }[];
}

export interface ListItemProps extends ListItemValue {
  onSave?: (newValue: string, oldValue: string) => void;
  selectable?: boolean;
  selected?: boolean;
  onSelect?: (value: string, selected?: boolean) => void;
}

export interface ListProps {
  values: ListItemValue[];
  // Function to run when an item is edited and saved. Called with the new string.
  onSave?: (newValue: string, oldValue: string) => void;
  // Whether items in the list can be selected.
  selectable?: boolean;
  // Function to run when an item is selected. Called with the item's label.
  onSelect?: (value: string, selected?: boolean) => void;
}

// eslint-disable-next-line
const ListItemBase = styled(Card)<{
  selectable?: boolean;
  onClick: () => void;
}>`
  transition: 0.15s ease-in-out;
  cursor: pointer;
  ${props =>
    props.selectable
      ? css`
          :hover {
            background: ${props.theme.colors.accent.blue};
          }
        `
      : ''}
`;

const Tag = styled.div`
  padding: 4px 10px;
  border-radius: 5px;
  font-size: 12px;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background: white;
  border: 1px solid ${props => props.color || props.theme.colors.intent.warning};
  color: ${props => props.theme.colors.black};
`;

// tslint:disable-next-line:max-func-body-length
export const ListItem = (props: ListItemProps) => {
  const { label, selected, tags } = props;
  // tslint:disable-next-line:no-null-keyword
  const cardRef = useRef(null);

  return (
    <ListItemBase
      selectable={props.selectable && !selected}
      ref={cardRef}
      p={3}
      bg={selected ? 'primary.blue' : 'white'}
      color={selected ? 'white' : 'black'}
      sx={{
        borderTop: '1px solid grey',
        borderColor: 'borderGrey'
      }}
      onClick={() => {
        if (props.onSelect && !selected) {
          props.onSelect(label, selected);
        }
      }}
    >
      <Flex justifyContent="space-between" alignItems="center">
        <Flex alignItems="center">
          {tags &&
            tags.map(({ name, color }) => (
              <Tag key={name} color={color}>
                <Text>{name}</Text>
              </Tag>
            ))}
          <Text>{label}</Text>
        </Flex>
      </Flex>
    </ListItemBase>
  );
};

export const List = (props: ListProps) => {
  const [activeItem, setActiveItem] = useState('');

  const removeSelectedItemIfDeleted = useCallback(() => {
    const keys = props.values.map(({ label }, index) => `${label}-${index}`);
    if (!keys.includes(activeItem)) {
      setActiveItem('');
    }
  }, [activeItem, props.values]);

  useEffect(removeSelectedItemIfDeleted, [props.values]);

  return (
    <>
      {props.values.map(({ label, tags }, index) => (
        <ListItem
          key={`${label}-${index}`}
          label={label}
          onSelect={(val: string, selected?: boolean) => {
            if (props.onSelect) props.onSelect(val, selected);
            setActiveItem(`${label}-${index}`);
          }}
          selectable={props.selectable}
          selected={props.selectable && activeItem === `${label}-${index}`}
          onSave={props.onSave}
          tags={tags}
        />
      ))}
    </>
  );
};
