import React, { FC, useState, useContext, FormEvent } from 'react';
import { motion } from 'framer-motion';
import { toaster } from 'evergreen-ui';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'react-feather';
import { Input, Select } from '@rebass/forms';
import { useFormState } from 'react-use-form-state';
import { Heading, Text, Flex, Button, Card, Box } from 'rebass';

import Spinner from '../UI/Spinner';
import styled from '../../utils/styled';
import { TooltipText } from '../UI/TooltipText';
import { usePut } from '../../hooks/use-itx-api';
import { ProjectPageContext } from '../../pages/Project';
import { itemVariants } from '../../utils/animation-configs';
import { Project, ProjectFormationStatus } from '../../models/project';

const WidthConstraint = styled(Flex)`
  flex-basis: 1280px;
`;

const tooltips = {
  primaryDomain:
    'The primary domain where project services exist (groups.io, email forwards, etc)',
  formationStatus:
    'Whether the project is formed and public or in formation and not yet announced',
  seriesName: '… a Series of LF Projects, LLC.',
  shortName: 'The project’s unique short name'
};

interface EditProjectInfoFormValues {
  prettyName: string;
  formationStatus: ProjectFormationStatus;
  seriesName: string;
}

// tslint:disable-next-line: max-func-body-length
export const ProjectInfo: FC<Project> = props => {
  const { hasAccess, reloadProject } = useContext(ProjectPageContext);
  const [editing, setEditing] = useState(false);
  const [formState, { text, select }] = useFormState<EditProjectInfoFormValues>(
    {
      prettyName: props.prettyName,
      formationStatus: props.formationStatus,
      seriesName: props.seriesName
    }
  );
  const [updateProject, loading] = usePut<Project, void>(
    `projects/${props.shortName}`
  );

  const updateProjectAndUI = async () => {
    try {
      await updateProject({
        shortName: props.shortName,
        prettyName: formState.values.prettyName,
        canonicalDomain: props.canonicalDomain,
        formationStatus: formState.values.formationStatus,
        seriesName: formState.values.seriesName
      });
      toaster.success('Project info updated successfully');
      await reloadProject();
    } catch (error) {
      if (error.name === 'AbortError') return;
      console.log(error);
    }
  };

  const toggleEditing = () => {
    if (editing) {
      setEditing(false);
    } else {
      formState.setField('seriesName', props.seriesName || '');
      formState.setField('prettyName', props.prettyName);
      formState.setField('formationStatus', props.formationStatus);
      setEditing(true);
    }
  };

  let editButton: JSX.Element;
  if (editing) {
    editButton = (
      <form
        id="editProject"
        onSubmit={(e: FormEvent) => {
          e.preventDefault();
          toggleEditing();
          if (
            formState.values.formationStatus !== props.formationStatus ||
            formState.values.prettyName !== props.prettyName ||
            formState.values.seriesName !== (props.seriesName || '')
          ) {
            updateProjectAndUI();
          }
        }}
      >
        <Button variant="confirm">Save Project Info</Button>
      </form>
    );
  } else {
    editButton = (
      <Button variant="outline" onClick={toggleEditing}>
        Edit Project Info
      </Button>
    );
  }

  return (
    <motion.div
      style={{ width: '100%' }}
      variants={itemVariants}
      initial="hidden"
      exit="hidden"
      animate="visible"
    >
      <Card
        bg="white"
        py={4}
        px={[2, null, 1]}
        sx={{
          borderBottom: '1px solid gainsboro',
          borderColor: 'borderGrey'
        }}
      >
        <Flex justifyContent="center">
          <WidthConstraint flexWrap="wrap">
            <Link style={{ color: 'grey', textDecoration: 'none' }} to="/">
              <Flex width={1} mb={15} flexDirection="row">
                <motion.div whileHover={{ x: '-7px' }}>
                  <ArrowLeft />
                </motion.div>
                <Text fontSize={2} ml={10} fontFamily="sans" color="grey">
                  Back to Project List
                </Text>
              </Flex>
            </Link>
            <Flex
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              width={1}
            >
              {hasAccess && editing ? (
                <Box pr={2} width={3 / 4}>
                  <Input
                    py={0}
                    px="9px"
                    fontSize={48}
                    sx={{ fontWeight: 600 }}
                    form="editProject"
                    {...text('prettyName')}
                  />
                </Box>
              ) : (
                <Heading variant="title" sx={{ lineHeight: '1.2' }}>
                  {props.prettyName}
                </Heading>
              )}
              {hasAccess && (loading ? <Spinner inline /> : editButton)}
            </Flex>
            <Flex flexDirection="column" width={[1, 2 / 3]} p={4} pb={0}>
              <TooltipText
                tip={tooltips.primaryDomain}
                color="primary.grey"
                fontSize={1}
              >
                Primary Domain
              </TooltipText>
              <Heading
                variant="subtitle"
                color={props.canonicalDomain ? 'black' : 'intent.warning'}
              >
                {props.canonicalDomain ? props.canonicalDomain : 'Unset'}
              </Heading>
            </Flex>
            <Flex flexDirection="column" width={[1, 1 / 3]} p={4} pb={0}>
              <TooltipText
                tip={tooltips.formationStatus}
                color="primary.grey"
                fontSize={1}
              >
                Formation Status
              </TooltipText>
              {hasAccess && editing ? (
                <Select
                  py={0}
                  px="7px"
                  fontSize={32}
                  sx={{ fontWeight: 600 }}
                  form="editProject"
                  {...select('formationStatus')}
                >
                  <option value={ProjectFormationStatus.Forming}>
                    Forming
                  </option>
                  <option value={ProjectFormationStatus.Formed}>Formed</option>
                </Select>
              ) : (
                <Heading
                  variant="subtitle"
                  color={
                    props.formationStatus === ProjectFormationStatus.Formed
                      ? 'primary.green'
                      : 'intent.warning'
                  }
                >
                  {props.formationStatus
                    .split('')
                    .map((l, i) => (i === 0 ? l.toUpperCase() : l))
                    .join('')}
                </Heading>
              )}
            </Flex>
            <Flex flexDirection="column" width={[1, 2 / 3]} p={4} pb={0}>
              <TooltipText
                tip={tooltips.seriesName}
                color="primary.grey"
                fontSize={1}
              >
                Series Name
              </TooltipText>
              {hasAccess && editing ? (
                <Box pr={2} width={3 / 4}>
                  <Input
                    py={0}
                    px="7px"
                    fontSize={32}
                    sx={{ fontWeight: 600 }}
                    form="editProject"
                    {...text('seriesName')}
                  />
                </Box>
              ) : (
                <Heading
                  variant="subtitle"
                  color={props.seriesName ? 'black' : 'intent.warning'}
                >
                  {props.seriesName || 'Unset'}
                </Heading>
              )}
            </Flex>
            <Flex flexDirection="column" width={[1, 1 / 3]} p={4} pb={0}>
              <TooltipText
                tip={tooltips.shortName}
                color="primary.grey"
                fontSize={1}
              >
                Short Name
              </TooltipText>
              <Heading variant="subtitle" color="black">
                {props.shortName}
              </Heading>
            </Flex>
          </WidthConstraint>
        </Flex>
      </Card>
    </motion.div>
  );
};
