import React, { FC, useContext } from 'react';
import { Box, Flex, Button, Text } from 'rebass';
import { toaster } from 'evergreen-ui';

import { GithubContext } from '.';
import { LinkStatus, Org } from '../../models/github/org';
import { useDelete } from '../../hooks/use-itx-api';
import { useDialog } from '../../hooks/use-dialog';
import { FloatingCard } from '../UI/FloatingCard';
import { Repos } from './Repos';
import { Tabs, Tab } from '../UI/Tabs';

const NotCreated = () => {
  return (
    <Text>
      This organization does not currently exist on Github. Please create it
      following the instructions{' '}
      <a
        href="https://help.github.com/en/articles/creating-a-new-organization-from-scratch"
        target="_blank"
        rel="noopener noreferrer"
      >
        here
      </a>
      .
    </Text>
  );
};

const None = () => {
  return (
    <Text>
      The Linux Foundation master user is not part of this organization. Please
      invite the 'thelinuxfoundation' user following the instructions{' '}
      <a
        href="https://help.github.com/en/articles/inviting-users-to-join-your-organization"
        target="_blank"
        rel="noopener noreferrer"
      >
        here
      </a>
      .
    </Text>
  );
};

const Partial = () => {
  return (
    <Text>
      The Linux Foundation master user is part of this organization, but they
      are not an owner. Please promote the 'thelinuxfoundation' user to Owner
      following the instructions{' '}
      <a
        href="https://help.github.com/en/articles/changing-a-persons-role-to-owner"
        target="_blank"
        rel="noopener noreferrer"
      >
        here
      </a>
      .
    </Text>
  );
};

const Full = () => {
  return <Text>This Github Organization is fully associated.</Text>;
};

export const About: FC<{
  org: Org;
}> = props => {
  const { hasAccess, reloadOrgs, deselectOrg } = useContext(GithubContext);
  const [dissociateGithub] = useDelete<undefined, void>(
    `github/{organization}`
  );
  const dissociateGithubAndReloadOrgs = async () => {
    try {
      const organization = encodeURIComponent(props.org.organization);
      await dissociateGithub(undefined, {
        organization
      });
      toaster.success(
        `Successfully dissociated '${props.org.organization}' from this project`
      );
      reloadOrgs();
      deselectOrg();
    } catch (error) {
      if (error.name === 'AbortError') return;
      console.log(error);
    }
  };
  const [dialog, showDialog] = useDialog({
    title: `Dissociate ${props.org.organization}`,
    intent: 'danger',
    message:
      'Dissociating this organization will not remove the Linux Foundation master member; this action must be taken seperately.',
    confirmLabel: 'Dissociate',
    async: true,
    onConfirm: dissociateGithubAndReloadOrgs
  });

  return (
    <FloatingCard
      key={props.org.organization}
      title={`About: ${props.org.organization}`}
    >
      {dialog}
      {hasAccess && (
        <Flex justifyContent="flex-end" mb={3} width={1}>
          <Button width="100%" variant="danger" onClick={showDialog}>
            Dissociate
          </Button>
        </Flex>
      )}
      <Box mb={2} p={3}>
        {props.org.linkedStatus === LinkStatus.Full && <Full />}
        {props.org.linkedStatus === LinkStatus.Partial && <Partial />}
        {props.org.linkedStatus === LinkStatus.None && <None />}
        {props.org.linkedStatus === LinkStatus.NotCreated && <NotCreated />}
      </Box>
      {props.org.linkedStatus !== LinkStatus.NotCreated && (
        <Tabs>
          <Tab label="Repositories">
            <Repos org={props.org} />
          </Tab>
        </Tabs>
      )}
    </FloatingCard>
  );
};
