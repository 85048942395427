const pillBase = {
  px: 2,
  py: 1,
  display: 'flex',
  lineHeight: 1,
  fontSize: 1,
  alignItems: 'center',
  borderStyle: 'solid',
  borderWidth: '2px',
  borderRadius: 0
};

const iconPillBase = {
  display: 'grid',
  gap: '5px',
  gridTemplateColumns: 'max-content max-content'
};

export const variants = {
  panel: {
    prompt: {
      display: 'flex',
      justifyContent: 'center',
      borderRadius: 0,
      alignItems: 'center',
      width: '100%',
      minHeight: '3em',
      bg: 'borderGrey',
      color: 'darkGrey'
    },
    invisible: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      minHeight: '3em'
    }
  },
  pill: {
    icon: {
      warning: {
        ...pillBase,
        ...iconPillBase,
        borderColor: 'intent.warning',
        color: 'intent.warning'
      },
      danger: {
        ...pillBase,
        ...iconPillBase,
        borderColor: 'intent.danger',
        color: 'intent.danger'
      },
      confirm: {
        ...pillBase,
        ...iconPillBase,
        borderColor: 'primary.green',
        color: 'primary.green'
      },
      ...pillBase,
      ...iconPillBase,
      borderColor: 'borderGrey',
      color: 'black'
    },
    warning: {
      ...pillBase,
      borderColor: 'intent.warning',
      color: 'intent.warning'
    },
    danger: {
      ...pillBase,
      borderColor: 'intent.danger',
      color: 'intent.danger'
    },
    confirm: {
      ...pillBase,
      borderColor: 'primary.green',
      color: 'primary.green'
    },
    ...pillBase,
    borderColor: 'borderGrey',
    color: 'black'
  },
  alert: {
    warning: {
      p: 4,
      fontWeight: 600,
      bg: 'intent.warning',
      borderRadius: 0
    }
  }
};
