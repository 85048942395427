import React, {
  FC,
  useContext,
  useEffect,
  useState,
  useCallback,
  FormEvent
} from 'react';
import { PlusCircle } from 'react-feather';
import { Label, Input } from '@rebass/forms';
import { useFormState } from 'react-use-form-state';
import { Flex, Button, Box, Heading, Text } from 'rebass';
import { Tooltip, IconButton, toaster } from 'evergreen-ui';

import styled from '../../utils/styled';
import { ForwardsContext } from './index';
import { Forward } from '../../models/forwards';
import { Recipient } from './Recipient';
import { emailRegex } from '../../utils/const';
import { FloatingCard } from '../UI/FloatingCard';
import { TooltipText } from '../UI/TooltipText';
import { useDialog } from '../../hooks/use-dialog';
import { usePut, useDelete } from '../../hooks/use-itx-api';

const tooltips = {
  alias: 'List of all the addresses of all email forwards.',
  recipients: 'List of the recipients next to each email forward.',
  actions: {
    edit: 'Edit Forward',
    delete: 'Delete Forward',
    save: 'Save changes',
    cancel: 'Abort changes'
  }
};

const Grid = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  grid-column-gap: 48px;
  grid-row-gap: 18px;
`;

const Alias = styled.div`
  display: flex;
  align-items: center;
`;

const Recipients = styled.div``;

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Divider = () => (
  <Box bg="borderGrey" height="1px" sx={{ gridColumnEnd: 'span 3' }} />
);

// tslint:disable-next-line:max-func-body-length
const ListItem: FC<{ forward: Forward }> = props => {
  const { hasAccess, project, reloadForwards } = useContext(ForwardsContext);
  const [editing, setEditing] = useState(false);
  const [recipients, setRecipients] = useState(props.forward.recipients);
  const [updateForward] = usePut<Forward, void>(
    `forwards/${project.shortName}/forwards/{alias}`
  );
  const [deleteForward] = useDelete<undefined, void>(
    `forwards/${project.shortName}/forwards/{alias}`
  );
  const [formState, { label, email }] = useFormState<{ recipient: string }>(
    {
      recipient: ''
    },
    { withIds: true }
  );
  const updateForwardAndRefresh = useCallback(async () => {
    if (recipients !== props.forward.recipients) {
      try {
        await updateForward(
          {
            recipients,
            alias: props.forward.alias
          },
          props.forward
        );
        toaster.success('Forward successfully updated');
        reloadForwards();
      } catch (error) {
        if (error.name === 'AbortError') return;
        console.log(error);
      }
    }
  }, [props.forward, recipients, reloadForwards, updateForward]);

  const deleteForwardAndRefresh = async () => {
    try {
      await deleteForward(undefined, props.forward);
      toaster.success('Forward successfully deleted');
      reloadForwards();
    } catch (error) {
      if (error.name === 'AbortError') return;
      console.log(error);
    }
  };
  const [dialog, showDialog] = useDialog({
    title: `Remove ${props.forward.alias}@${props.forward.domain}?`,
    intent: 'danger',
    message: `Are you sure you want to delete ${props.forward.alias}@${props.forward.domain}?`,
    async: true,
    onConfirm: deleteForwardAndRefresh
  });

  useEffect(() => {
    setRecipients(props.forward.recipients);
  }, [props.forward.recipients]);

  let actions: JSX.Element;
  if (editing) {
    actions = (
      <Actions>
        <Box mr={1}>
          <Tooltip content={tooltips.actions.save}>
            <IconButton
              onClick={() => {
                setEditing(false);
                updateForwardAndRefresh();
              }}
              appearance="minimal"
              iconSize={20}
              icon="floppy-disk"
            />
          </Tooltip>
        </Box>
        <Box ml={1}>
          <Tooltip content={tooltips.actions.cancel}>
            <IconButton
              icon="cross"
              appearance="minimal"
              intent="warning"
              iconSize={20}
              onClick={() => {
                setRecipients(props.forward.recipients);
                setEditing(false);
              }}
            />
          </Tooltip>
        </Box>
      </Actions>
    );
  } else {
    actions = (
      <Actions>
        <Box mr={1}>
          <Tooltip content={tooltips.actions.edit}>
            <IconButton
              onClick={() => {
                setEditing(true);
              }}
              appearance="minimal"
              iconSize={20}
              icon="edit"
            />
          </Tooltip>
        </Box>
        <Box ml={1}>
          {dialog}
          <Tooltip content={tooltips.actions.delete}>
            <IconButton
              appearance="minimal"
              intent="danger"
              iconSize={20}
              icon="trash"
              onClick={showDialog}
            />
          </Tooltip>
        </Box>
      </Actions>
    );
  }

  return (
    <>
      <Divider />
      <Alias>
        {props.forward.alias}@{project.canonicalDomain}
      </Alias>
      <Recipients>
        {recipients.map(r => (
          <Recipient
            key={r}
            deletable={editing}
            onDelete={recipient => {
              setRecipients(recipients.filter(r => r !== recipient));
            }}
          >
            {r}
          </Recipient>
        ))}
        {editing && (
          <Box
            as="form"
            mt={3}
            onSubmit={(e: FormEvent) => {
              e.preventDefault();
              setRecipients([formState.values.recipient, ...recipients]);
              formState.reset();
            }}
          >
            <Label {...label('recipient')}>New Recipient</Label>
            <Flex>
              <Input
                {...email('recipient')}
                placeholder="recipient@example.com"
                required
              />
              <Button
                ml={2}
                variant={
                  emailRegex.test(formState.values.recipient.toLowerCase())
                    ? 'confirm'
                    : 'disabled'
                }
              >
                <Flex justifyContent="center" alignItems="center">
                  <PlusCircle />
                </Flex>
              </Button>
            </Flex>
          </Box>
        )}
      </Recipients>
      {hasAccess && actions}
    </>
  );
};

export const List: FC<{ forwards: Forward[] }> = props => {
  const { hasAccess } = useContext(ForwardsContext);
  const newForwards = props.forwards;
  newForwards.sort((a: Forward, b: Forward) => {
    if (a.alias > b.alias) return 1;
    if (a.alias < b.alias) return -1;

    return 1;
  });

  return (
    <FloatingCard title="All Forwards">
      {props.forwards.length > 0 ? (
        <Grid>
          <Alias>
            <Heading fontSize={3}>
              <TooltipText tip={tooltips.alias}>Alias</TooltipText>
            </Heading>
          </Alias>
          <Recipients>
            <Heading fontSize={3}>
              <TooltipText tip={tooltips.recipients}>Recipients</TooltipText>
            </Heading>
          </Recipients>
          {hasAccess && (
            <Actions>
              <Heading fontFamily="sans" fontSize={3}>
                Actions
              </Heading>
            </Actions>
          )}
          {newForwards.map(f => (
            <ListItem forward={f} key={f.alias} />
          ))}
        </Grid>
      ) : (
        <Flex justifyContent="center">
          <Text fontFamily="sans" my={3}>
            This project has no forwards
          </Text>
        </Flex>
      )}
    </FloatingCard>
  );
};
