import React, { FC, ReactNode, ReactNodeArray } from 'react';
import { Card } from 'rebass';

export interface TabProps {
  children: ReactNode | ReactNodeArray;
  label: string;
}

export const Tab: FC<TabProps> = props => {
  return (
    <Card bg="white" sx={{ borderColor: 'borderGrey' }}>
      {props.children}
    </Card>
  );
};
