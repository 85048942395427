import { useState, useEffect } from 'react';

import { canWe } from '../utils/auth';

/**
 * @name usePermission
 * @description A React hook that returns whether a user is allowed to read or manage ITX API services.
 * @param action The type of action you would like to take. Either `read` or `manage`.
 * @param service The name of the service that you would like to check permissions for.
 */
export function usePermission(action: 'read' | 'manage', service: string) {
  const [canManage, setCanManage] = useState(false);

  useEffect(() => {
    canWe(action, service)
      .then(result => setCanManage(result))
      .catch(() => setCanManage(false));
  }, [action, service]);

  return canManage;
}
