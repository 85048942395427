import React, {
  useCallback,
  useState,
  useLayoutEffect,
  useMemo,
  ReactNode
} from 'react';
import { Input } from '@rebass/forms';
import { useFormState } from 'react-use-form-state';
import { Heading, Flex, Text, Button, Link as A } from 'rebass';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import Spinner from '../UI/Spinner';
import ListItems from './ListItems';
import { NavButtons } from './NavButtons';
import { Project } from '../../models/project';
import { TooltipText } from '../UI/TooltipText';
import { FloatingCard } from '../UI/FloatingCard';

export interface ProjectListProps extends RouteComponentProps {
  projects: Project[];
  pageSize: number;
  currentPage: number;
  isLoading: boolean;
  searchQuery: string | null;
  searchProjects: (query: string) => Promise<void>;
}

const tooltips = {
  search:
    "Search for projects based on Project Name, Project Short Name, and a project's associated domains.",
  projectName:
    'The full names of projects. Click to manage projects services (domains, email forwards, groups.io and github).',
  formationStatus: 'Project formation status, either forming or formed.',
  primaryDomain:
    'The primary domain where project services exist (groups.io, email forwards, etc)'
};

export const ProjectList = withRouter((props: ProjectListProps) => {
  const [pageItems, setPageItems] = useState<Project[]>();
  const params = useMemo(() => new URLSearchParams(props.location.search), [
    props.location.search
  ]);
  const [formState, { text }] = useFormState<{ query: string }>({
    query: props.searchQuery || ''
  });
  const pages = Math.ceil(props.projects.length / props.pageSize);

  const searchProjectsByDomain = useCallback(
    e => {
      e.preventDefault();
      setPageItems(undefined);
      props.searchProjects(formState.values.query);

      params.delete('page');
      if (formState.values.query) {
        params.set('q', formState.values.query);
      } else {
        params.delete('q');
      }
      props.history.push({
        pathname: '/',
        search: params.toString()
      });
    },
    [formState.values.query, params, props]
  );

  useLayoutEffect(() => {
    let page = parseInt(params.get('page') || '1');
    if (page < 1) {
      page = 1;
    } else if (page > Math.ceil(props.projects.length / props.pageSize)) {
      page = Math.ceil(props.projects.length / props.pageSize);
    }

    setPageItems(
      props.projects.slice((page - 1) * props.pageSize, page * props.pageSize)
    );
  }, [params, props.pageSize, props.projects, props.projects.length]);

  let results: ReactNode;
  if (props.isLoading) {
    results = (
      <Flex py={3} justifyContent="center">
        <Spinner />
      </Flex>
    );
  } else if (props.projects.length === 0) {
    results = (
      <Flex variant="panel.prompt">
        <Text>
          No results found.{' '}
          <A
            onClick={() => props.searchProjects('')}
            sx={{ textDecoration: 'underline' }}
          >
            Reset search
          </A>
        </Text>
      </Flex>
    );
  } else if (pageItems) {
    results = (
      <>
        <ListItems pageItems={pageItems} />
        {pages > 1 && (
          <Flex
            p={3}
            bg="white"
            justifyContent="center"
            sx={{ position: 'sticky', bottom: 0 }}
          >
            <NavButtons pages={pages} activePage={props.currentPage} />
          </Flex>
        )}
      </>
    );
  }

  return (
    <FloatingCard title="Projects">
      <Flex
        as="form"
        mb={2}
        alignItems="center"
        onSubmit={searchProjectsByDomain}
      >
        <Heading
          variant="subheading"
          fontSize={2}
          mr={4}
          sx={{ flexShrink: 0 }}
        >
          <TooltipText tip={tooltips.search}>Search Projects</TooltipText>
        </Heading>
        <Input {...text('query')} placeholder="Example" />
        <Button variant="confirm" ml={4}>
          Search
        </Button>
      </Flex>
      <Flex py={3} bg="white" sx={{ position: 'sticky', top: 0 }}>
        <Flex width={1 / 3}>
          <Heading fontSize={3}>
            <TooltipText tip={tooltips.projectName}>Project Name</TooltipText>
          </Heading>
        </Flex>
        <Flex width={1 / 3}>
          <Heading fontSize={3}>
            <TooltipText tip={tooltips.formationStatus}>
              Formation Status
            </TooltipText>
          </Heading>
        </Flex>
        <Flex width={1 / 3}>
          <Heading fontSize={3}>
            <TooltipText tip={tooltips.primaryDomain}>
              Primary Domain
            </TooltipText>
          </Heading>
        </Flex>
      </Flex>
      {results}
    </FloatingCard>
  );
});
