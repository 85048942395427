import React from 'react';

import Track from './Track';
import Create from './Create';
import { Tabs, Tab } from '../../../UI/Tabs';
import { FloatingCard } from '../../../UI/FloatingCard';

interface AddSiteProps {
  createJira: () => Promise<void>;
  trackJira: (url: string) => Promise<void>;
  hasPrimaryDomain: boolean;
  hasManagedAccount: boolean;
}

export default function AddSite(props: AddSiteProps) {
  return (
    <FloatingCard title="Add Jira Site">
      <Tabs>
        <Tab label="Create New">
          <Create
            createJira={props.createJira}
            hasPrimaryDomain={props.hasPrimaryDomain}
            hasManagedAccount={props.hasManagedAccount}
          />
        </Tab>
        <Tab label="Track Existing">
          <Track trackJira={props.trackJira} />
        </Tab>
      </Tabs>
    </FloatingCard>
  );
}
