import React, { FC, useState, useContext, useEffect, ChangeEvent } from 'react';
import { Flex, Box } from 'rebass';
import { toaster } from 'evergreen-ui';
import { Label, Select } from '@rebass/forms';
import { useFormState } from 'react-use-form-state';

import { ConfigureContext } from '../Configure';
import apiClient from '../../../utils/api-client';
import NextButton from './NextButton';
import { RepoDetails } from '..';
import { Service } from '../../../models/ci';
import { Project } from '../../../models/project';
import { TooltipText } from '../../UI/TooltipText';
import { useDialog } from '../../../hooks/use-dialog';
import { formatName } from '../../../utils/string-handlers';

interface ServicesProps {
  project: Project;
  language?: string;
  hasIssues: boolean;
  artifactService?: Service;
  distributionService?: Service;
  putServicesInfo: (
    artifactStore?: Service,
    distribution?: Service,
    updatedDetails?: Partial<RepoDetails>
  ) => Promise<void>;
  submitConfig: () => Promise<void>;
}

const tooltips = {
  artifactStorage:
    'The platform where build artifacts will be hosted for pre-release testing.',
  distributionPlatform: 'The platform where build artifacts will be released.'
};

const abortController = new AbortController();

async function getArtifactServices(project: string, language?: string) {
  const artifactServices: Service[] = await apiClient
    .get('ci/services', {
      signal: abortController.signal,
      searchParams: {
        project,
        type: 'artifacts'
      }
    })
    .json();
  artifactServices.filter(service => {
    if (service.languages && language)
      return service.languages.includes(language);
    return service;
  });

  return artifactServices;
}

async function getDistributionServices(project: string, language?: string) {
  const distributionServices: Service[] = await apiClient
    .get('ci/services', {
      signal: abortController.signal,
      searchParams: {
        project,
        type: 'distribution'
      }
    })
    .json();
  distributionServices.filter(service => {
    if (service.languages && language)
      return service.languages.includes(language);
    return service;
  });

  return distributionServices;
}

const Services: FC<ServicesProps> = props => {
  const [artifactServices, setArtifactServices] = useState<Service[]>([]);
  const [distributionServices, setDistributionServices] = useState<Service[]>(
    []
  );
  const { start, stop, isValid, validate, modifiedData } = useContext(
    ConfigureContext
  );
  const [formState, { select }] = useFormState({
    artifactService: props.artifactService?.ref || '',
    distributionService: props.distributionService?.ref || ''
  });

  useEffect(() => {
    start('Fetching available services...');
    Promise.all([
      getArtifactServices(props.project.shortName, props.language),
      getDistributionServices(props.project.shortName, props.language)
    ])
      .then(([artifactRes, distributionRes]) => {
        setArtifactServices(artifactRes);
        setDistributionServices(distributionRes);
        stop();
      })
      .catch(error => {
        if (error.name === 'AbortError') return;
        stop();
        toaster.danger(`Error retrieving services: ${error.description}`);
      });
    return () => abortController.abort();
  }, [start, stop, props.project.shortName, props.language]);

  async function onClick() {
    start('Updating configuration...');
    const artifactStore = artifactServices.find(
      service => service.ref === formState.values.artifactService
    );
    const distribution = distributionServices.find(
      service => service.ref === formState.values.distributionService
    );

    let updatedDetails: { [key: string]: string } = {};
    if (!isValid) {
      modifiedData?.forEach((value, key) => (updatedDetails[key] = value));
    }

    await props.putServicesInfo(
      artifactStore,
      distribution,
      updatedDetails as Partial<RepoDetails>
    );
  }

  function onArtifactServiceChange(e: ChangeEvent<HTMLSelectElement>) {
    const { value: artifactService } = e.currentTarget;
    if (artifactService === props.artifactService?.ref) validate();
  }

  function onDistributionServiceChange(e: ChangeEvent<HTMLSelectElement>) {
    const { value: distributionService } = e.currentTarget;
    if (distributionService === props.distributionService?.ref) validate();
  }

  const [dialog, showDialog] = useDialog({
    title: 'Submit Configuration',
    message: `Are you sure you want to submit this configuration? Doing so will open a pull request for you to review the generated configuration before committing to the default branch.`,
    confirmLabel: 'Submit',
    async: true,
    intent: 'success',
    onConfirm: async () => {
      start('Submitting configuration...');
      await props.submitConfig();
      stop();
    }
  });

  return (
    <Box>
      {dialog}
      <Flex mb={3}>
        <Box mr={2} width={1 / 2}>
          <Label>
            <TooltipText tip={tooltips.artifactStorage}>
              Artifact Storage
            </TooltipText>
          </Label>
          <Select
            {...select({
              name: 'artifactService',
              onChange: onArtifactServiceChange
            })}
          >
            <option value="">None</option>
            {artifactServices.map(service => {
              const label = `${formatName(service.name)} – ${service.id}`;
              return (
                <option key={label} value={service.ref}>
                  {label}
                </option>
              );
            })}
          </Select>
        </Box>
        <Box ml={2} width={1 / 2}>
          <Label>
            <TooltipText tip={tooltips.distributionPlatform}>
              Distribution Platform
            </TooltipText>
          </Label>
          <Select
            {...select({
              name: 'distributionService',
              onChange: onDistributionServiceChange
            })}
          >
            <option value="" disabled hidden>
              Choose a service
            </option>
            {distributionServices.map(service => {
              const label = `${formatName(service.name)} – ${service.id}`;

              return (
                <option key={label} value={service.ref}>
                  {label}
                </option>
              );
            })}
          </Select>
        </Box>
      </Flex>
      {props.hasIssues || !isValid ? (
        <NextButton variant="confirm" onClick={onClick}>
          Update and Validate
        </NextButton>
      ) : (
        <NextButton variant="confirm" onClick={showDialog}>
          Submit Configuration
        </NextButton>
      )}
    </Box>
  );
};

export default Services;
