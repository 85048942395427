import React, { FunctionComponent } from 'react';
import { Box } from 'rebass';

import Info from './Info';
import AddSite from './Add';
import { FloatingCard } from '../../UI/FloatingCard';
import { Jira as JiraModel } from '../../../models/other-services';
import { usePermission } from '../../../hooks/use-permission';

interface JiraProps {
  sites: JiraModel[];
  trackJira: (url: string) => Promise<void>;
  createJira: () => Promise<void>;
  hasManagedAccount: boolean;
  primaryDomain: string;
}

const Jira: FunctionComponent<JiraProps> = props => {
  const canManage = usePermission('manage', 'jira');

  if (props.sites.length > 0) {
    return (
      <>
        {props.sites.map(site => (
          <Box mb={4} key={site.id}>
            <Info info={site} />
          </Box>
        ))}
      </>
    );
  }

  if (canManage) {
    return (
      <AddSite
        trackJira={props.trackJira}
        createJira={props.createJira}
        hasPrimaryDomain={!!props.primaryDomain}
        hasManagedAccount={props.hasManagedAccount}
      />
    );
  }

  return (
    <FloatingCard title="Not Configured">
      This service has not been configured. Please file a ticket to add a Jira
      site to this project.
    </FloatingCard>
  );
};

export default Jira;
