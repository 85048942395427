import React from 'react';
import { Flex } from 'rebass';
import { Icon } from 'evergreen-ui';
import { withTheme } from 'emotion-theming';
import ReactTooltip from 'react-tooltip';
import nanoid from 'nanoid';

interface TooltipIconProps {
  icon: string;
  theme: any;
  tip: string | JSX.Element;
  color?: string;
  size?: number;
}

export const TooltipIcon = withTheme((props: TooltipIconProps) => {
  const id = nanoid();

  return (
    <Flex alignItems="center" justifyContent="center">
      <span data-tip data-for={id}>
        <Icon
          icon={props.icon as 'font'}
          size={props.size || 12}
          color={props.theme!.colors![props.color || ''] || 'gainsboro'}
        />
      </span>
      <ReactTooltip id={id} effect="solid">
        {props.tip}
      </ReactTooltip>
    </Flex>
  );
});
