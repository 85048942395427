import React, { Fragment } from 'react';
import { IconButton, Tooltip } from 'evergreen-ui';
import { Heading, Text, Flex } from 'rebass';

import Spinner from '../UI/Spinner';
import styled from '../../utils/styled';
import { useZoom } from '../../hooks/zoom';
import { MessageCard } from '../UI/MessageCard';
import { FloatingCard } from '../UI/FloatingCard';
import { useDialog } from '../../hooks/use-dialog';
import { ProjectShortName } from '../../models/project';

interface ListProps {
  projectName: ProjectShortName;
  showActions?: boolean;
}

const UserGrid = styled.div<{ showActions?: boolean }>`
  display: grid;
  gap: 18px 32px;
  grid-template-columns: max-content max-content 1fr ${props =>
      props.showActions ? 'max-content' : ''};
`;

const Divider = styled.div<{ withActions?: boolean }>`
  height: 1px;
  grid-column-end: span ${props => (props.withActions ? '4' : '3')};
  background: ${props => props.theme.colors.borderGrey};
`;

export function List(props: ListProps) {
  const {
    users,
    deleteUser,
    status: { query: status }
  } = useZoom(props.projectName);
  const [dialog, showDialog] = useDialog({
    title: 'Delete Zoom User',
    async: true,
    intent: 'danger',
    message:
      'This action will delete the Zoom user, including all of their scheduled meetings and recordings. Are you sure want to continue?',
    onConfirm: deleteUser
  });

  if (users) {
    if (users.length === 0) {
      return (
        <MessageCard title="No Users">
          There are no Zoom users currently associated with this project.
        </MessageCard>
      );
    } else {
      return (
        <FloatingCard
          title="Users"
          tip={
            <>
              <b>Note:</b> Changes to names or emails in Zoom may take a few
              minutes to be reflected here.
            </>
          }
        >
          {dialog}
          <UserGrid showActions={props.showActions}>
            <Heading fontSize={2}>First Name</Heading>
            <Heading fontSize={2}>Last Name</Heading>
            <Heading fontSize={2}>Email</Heading>
            {props.showActions && <Heading fontSize={2}>Actions</Heading>}
            {users.map(user => (
              <Fragment key={user.id}>
                <Divider withActions={props.showActions} />
                <Text>{user.firstName}</Text>
                <Text>{user.lastName}</Text>
                <Text>{user.email}</Text>
                {props.showActions && (
                  <Flex justifyContent="flex-end">
                    <Tooltip content="Delete User">
                      <IconButton
                        icon="trash"
                        intent="danger"
                        appearance="minimal"
                        onClick={() => showDialog(undefined, { id: user.id })}
                      />
                    </Tooltip>
                  </Flex>
                )}
              </Fragment>
            ))}
          </UserGrid>
        </FloatingCard>
      );
    }
  }

  if (status === 'error') {
    return (
      <MessageCard title="Error">
        Something went wrong fetching users. Please refresh the page to try
        again.
      </MessageCard>
    );
  }

  return <Spinner message="Fetching users..." />;
}
