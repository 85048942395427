import React, { FC } from 'react';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { Flex, Box, Text, Card } from 'rebass';

import { theme } from '../../theme';
import { Project } from '../../models/project';

interface ListItemProps extends RouteComponentProps {
  pageItems: Project[];
}

const ListItems: FC<ListItemProps> = props => {
  return (
    <>
      {props.pageItems.map(
        ({ prettyName, shortName, formationStatus, canonicalDomain }) => (
          <Card
            py={3}
            key={shortName}
            sx={{
              borderTop: '1px solid gainsboro',
              borderColor: 'borderGrey'
            }}
          >
            <Flex>
              <Box width={1 / 3}>
                <Link
                  to={`${props.match.url}project/${shortName}`}
                  style={{
                    color: theme.colors.primary.blue,
                    textDecoration: 'none'
                  }}
                >
                  <Text fontSize={1}>{prettyName}</Text>
                </Link>
              </Box>
              <Flex width={1 / 3}>
                <Text fontSize={1} color="paragraph">
                  {formationStatus}
                </Text>
              </Flex>
              <Flex width={1 / 3}>
                <Text
                  fontSize={1}
                  color={canonicalDomain ? 'paragraph' : 'primary.grey'}
                >
                  {canonicalDomain ? canonicalDomain : 'Unset'}
                </Text>
              </Flex>
            </Flex>
          </Card>
        )
      )}
    </>
  );
};

export default withRouter(ListItems);
