import React, { ReactNode, useMemo } from 'react';
import nanoid from 'nanoid';
import { Text } from 'rebass';
import ReactTooltip from 'react-tooltip';

interface TooltipProps {
  tip: string | JSX.Element;
  children: ReactNode;
}

function Tooltip(props: TooltipProps) {
  const id = useMemo(() => nanoid(), []);

  return (
    <>
      <span data-tip data-for={id}>
        {props.children}
      </span>
      <ReactTooltip id={id} effect="solid">
        <Text variant="tooltip">{props.tip}</Text>
      </ReactTooltip>
    </>
  );
}

export default Tooltip;
