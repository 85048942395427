import { createContext, Dispatch } from 'react';

import { State, Action } from './reducer';

export const TabContext = createContext<{
  state: State;
  dispatch: Dispatch<Action>;
}>({
  state: { tabs: [], active: '' },
  dispatch: () => {}
});
