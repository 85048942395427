import React, { useState, FormEvent, ReactNode } from 'react';
import { Dialog } from 'evergreen-ui';

interface UseDialogOptions {
  title: string;
  message: ReactNode;
  intent?: 'success' | 'danger' | 'warning';
  confirmLabel?: string;
  async?: boolean;
  onConfirm: (args?: any) => void | Promise<any>;
}

export const useDialog = (
  options: UseDialogOptions
): [JSX.Element, (e?: FormEvent, args?: any) => void] => {
  const [isShowing, setIsShowing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [args, setArgs] = useState<any>(undefined);

  const dialog = (
    <Dialog
      isShown={isShowing}
      title={options.title}
      intent={options.intent}
      confirmLabel={options.confirmLabel}
      isConfirmLoading={isLoading}
      hasCancel={!isLoading}
      onConfirm={async () => {
        if (options.async) {
          setIsLoading(true);
          await options.onConfirm(args);
          setIsLoading(false);
        } else {
          options.onConfirm(args);
        }
        setIsShowing(false);
      }}
      onCloseComplete={() => setIsShowing(false)}
    >
      <p>{options.message}</p>
    </Dialog>
  );

  const showDialog = (e?: FormEvent, rest?: any) => {
    if (e) e.preventDefault();
    setArgs(rest);
    setIsShowing(true);
  };

  return [dialog, showDialog];
};
