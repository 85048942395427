import { useEffect } from 'react';

export const useAsyncEffect = (
  effect: () => Promise<void>,
  dependencies?: any[]
) => {
  useEffect(() => {
    effect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
};
