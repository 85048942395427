import React, { Component } from 'react';
import Fuse from 'fuse.js';
import { Box } from 'rebass';
import { toaster } from 'evergreen-ui';
import { withRouter, RouteComponentProps } from 'react-router';

import { canWe } from '../../utils/auth';
import styled from '../../utils/styled';
import apiClient from '../../utils/api-client';
import Spinner from '../../components/UI/Spinner';
import { ProjectList } from '../../components/ProjectList';
import {
  ReadOnlyDisclaimer,
  AdminDisclaimer
} from '../../components/UI/Disclaimer';
import { Domain } from '../../models/domain';
import { Project } from '../../models/project';

const Container = styled(Box)`
  max-width: 960px;
`;

const projectsPerPage = parseInt(
  process.env.REACT_APP_SEARCH_PAGE_SIZE || '25'
);

const hiddenProjects = ['lcom'];

interface SearchState {
  projects: Project[] | undefined;
  canManage?: boolean;
  isSearching: boolean;
}

const sortProjectsByName = (a: Project, b: Project) => {
  const nameA = a.prettyName.toUpperCase();
  const nameB = b.prettyName.toUpperCase();

  if (nameA < nameB) {
    return -1;
  }

  if (nameA > nameB) {
    return 1;
  }

  return 0;
};

const filterProjectsByDomains = (projects: Project[], domains: Domain[]) => {
  return projects.filter(project => {
    const found = domains.find(domain => {
      return domain.project === project.shortName;
    });

    return !!found;
  });
};

const fuseConfig = {
  shouldSort: true,
  threshold: 0.2,
  keys: ['prettyName', 'shortName']
};

class Search extends Component<RouteComponentProps, SearchState> {
  abortController = new AbortController();

  state = {
    projects: undefined,
    canManage: undefined,
    isSearching: false
  };

  fetchProjects = async () => {
    try {
      const projects: Project[] = await apiClient
        .get('projects', { signal: this.abortController.signal })
        .json();
      projects.sort(sortProjectsByName);
      return projects.filter(
        project => !hiddenProjects.includes(project.shortName)
      );
    } catch (error) {
      if (error.name === 'AbortError') return;
      toaster.danger(`Error fetching projects: ${error.message}`);
      return undefined;
    }
  };

  searchProjects = async (query: string) => {
    this.setState({ isSearching: true });
    const projects = (await this.fetchProjects()) || this.state.projects || [];
    if (query) {
      try {
        const domains: Domain[] = await apiClient
          .get('domains', {
            signal: this.abortController.signal,
            searchParams: {
              nameLike: query
            }
          })
          .json();
        const fuse = new Fuse(projects, fuseConfig);
        const domainMatchedProjects = filterProjectsByDomains(
          projects,
          domains
        );
        const fuzzyMatchedProjects = fuse.search(query);
        const mergedResults = domainMatchedProjects.reduce(
          (accumulator, current) => {
            if (!fuzzyMatchedProjects.includes(current))
              return [...accumulator, current];
            return accumulator;
          },
          [...fuzzyMatchedProjects]
        );
        mergedResults.sort(sortProjectsByName);
        this.setState({ projects: mergedResults, isSearching: false });
      } catch (error) {
        if (error.name === 'AbortError') return;
        toaster.danger(`Error getting search results: ${error.message}`);
        this.setState({ isSearching: false });
      }
    } else {
      this.setState({ projects, isSearching: false });
    }
  };

  async componentDidMount() {
    const canManage = await canWe('manage', 'projects');
    this.setState({ canManage });
    const params = new URLSearchParams(this.props.location.search);
    const query = params.get('q');
    if (query) {
      await this.searchProjects(query);
    } else {
      const projects = await this.fetchProjects();
      this.setState({ projects: projects || [] });
    }
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  render() {
    const { canManage, projects, isSearching } = this.state;
    const params = new URLSearchParams(this.props.location.search);
    const searchQuery = params.get('q');
    const currentPage = parseInt(params.get('page') || '1');

    return (
      <Container mt={4}>
        {typeof canManage === 'undefined' ? (
          <Spinner message="Checking permissions..." />
        ) : (
          <>{canManage ? <AdminDisclaimer /> : <ReadOnlyDisclaimer />}</>
        )}

        {projects ? (
          <ProjectList
            searchQuery={searchQuery}
            projects={projects || ([] as Project[])}
            pageSize={projectsPerPage}
            currentPage={currentPage}
            searchProjects={this.searchProjects}
            isLoading={isSearching}
          />
        ) : (
          <Spinner message="Fetching projects..." />
        )}
      </Container>
    );
  }
}

export default withRouter(Search);
