import React from 'react';

import Track from './Track';
import Create from './Create';
import { Tabs, Tab } from '../../../UI/Tabs';
import { FloatingCard } from '../../../UI/FloatingCard';

interface AddSiteProps {
  createConfluence: () => Promise<void>;
  trackConfluence: (url: string) => Promise<void>;
  hasPrimaryDomain: boolean;
  hasManagedAccount: boolean;
}

export default function AddSite(props: AddSiteProps) {
  return (
    <FloatingCard title="Add Confluence Site">
      <Tabs>
        <Tab label="Create New">
          <Create
            createConfluence={props.createConfluence}
            hasPrimaryDomain={props.hasPrimaryDomain}
            hasManagedAccount={props.hasManagedAccount}
          />
        </Tab>
        <Tab label="Track Existing">
          <Track trackConfluence={props.trackConfluence} />
        </Tab>
      </Tabs>
    </FloatingCard>
  );
}
