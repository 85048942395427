import React, { useMemo, FunctionComponent } from 'react';
import { Heading } from 'rebass';

import Spinner from '../UI/Spinner';
import { List as ListBase } from '../UI/List';
import { FloatingCard } from '../UI/FloatingCard';
import { Services } from '../../models/other-services';

interface ListProps {
  loading: boolean;
  services: {
    name: Services;
    configured: boolean;
  }[];
  onSelect: (service: string) => void;
}

const List: FunctionComponent<ListProps> = props => {
  const listValues = useMemo(() => {
    const services = [...props.services];
    services.sort((serviceA, serviceB) => {
      if (serviceA.configured) return -1;
      if (serviceB.configured) return 1;

      return 0;
    });
    return services.map(service => ({
      label: service.name as string,
      tags: service.configured
        ? [
            {
              name: 'Configured',
              color: 'green'
            }
          ]
        : undefined
    }));
  }, [props.services]);

  return (
    <FloatingCard title="Services">
      <Heading variant="subheading" color="primary.grey" mb={2}>
        Select a Service
      </Heading>
      {props.loading ? (
        <Spinner message="Fetching services..." />
      ) : (
        <ListBase values={listValues} selectable onSelect={props.onSelect} />
      )}
    </FloatingCard>
  );
};

export default List;
