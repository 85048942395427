import React, { ReactNode, FC } from 'react';
import { motion } from 'framer-motion';
import { Card, Heading, Flex, Box } from 'rebass';
import { TooltipText } from '../UI/TooltipText';

interface FloatingCardProps {
  title: string;
  tags?: ReactNode[];
  tip?: string | JSX.Element;
  children: ReactNode;
}

export const FloatingCard: FC<FloatingCardProps> = ({
  title,
  tags,
  children,
  tip
}) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={{ width: '100%' }}
    >
      <Card width={1} bg="white" sx={{ boxShadow: 0 }}>
        <Card
          py={3}
          px={4}
          sx={{
            borderBottom: '1px solid gainsboro',
            borderColor: 'borderGrey'
          }}
        >
          <Flex
            justifyContent={tags ? 'space-between' : 'flex-start'}
            alignItems="center"
          >
            <Heading variant="heading">
              {tip ? <TooltipText tip={tip}>{title}</TooltipText> : title}
            </Heading>
            <Box
              sx={{
                display: 'grid',
                gap: '8px',
                alignItems: 'center',
                gridAutoColumns: 'fit-content',
                gridAutoFlow: 'column'
              }}
            >
              {tags}
            </Box>
          </Flex>
        </Card>
        <Card py={3} px={4}>
          {children}
        </Card>
      </Card>
    </motion.div>
  );
};
