export const listVariants = {
  visible: {
    height: 'auto',
    transition: {
      type: 'tween',
      duration: 0.2,
      ease: 'easeInOut',
      when: 'beforeChildren',
      delayChildren: 0.07
    }
  },
  hidden: {
    height: '4em',
    transition: {
      type: 'spring',
      damping: 30,
      stiffness: 150,
      when: 'afterChildren'
    }
  }
};

export const itemVariants = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 }
};
