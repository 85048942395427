import React, { FunctionComponent } from 'react';
import { Button, Box } from 'rebass';

import Spinner from '../../../UI/Spinner';
import { useLoading } from '../../../../hooks/use-loading';

interface CreateProps {
  hasManagedAccount: boolean;
  createAccount: (
    description: string,
    email?: string,
    accountType?: 'managed' | 'unmanaged'
  ) => Promise<void>;
}

const Create: FunctionComponent<CreateProps> = props => {
  const [{ isLoading }, { start, stop }] = useLoading(false);

  async function onClick() {
    start();
    await props.createAccount('ITX Managed AWS Account', undefined, 'managed');
    stop();
  }

  if (isLoading) {
    return (
      <Box mt={3}>
        <Spinner />
      </Box>
    );
  }

  if (props.hasManagedAccount) {
    return (
      <Box mt={3} variant="panel.prompt" p={4}>
        An account cannot be created at this time because this project already
        has an ITX Managed AWS account. You can instead invite an existing
        account in the next tab.
      </Box>
    );
  }

  return (
    <Button
      onClick={onClick}
      variant="confirm"
      mt={3}
      p={3}
      minHeight="3em"
      width={1}
    >
      Create Managed Account
    </Button>
  );
};

export default Create;
