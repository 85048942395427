import React, { FormEvent } from 'react';
import { Box, Button, Text } from 'rebass';
import { Label, Input } from '@rebass/forms';
import { useFormState } from 'react-use-form-state';

import Spinner from '../UI/Spinner';
import { useZoom } from '../../hooks/zoom';
import { FloatingCard } from '../UI/FloatingCard';

interface InviteProps {
  projectName: string;
}

interface InviteFormData {
  firstName: string;
  lastName: string;
  email: string;
}

export function Invite(props: InviteProps) {
  const {
    inviteUser,
    status: { invite: status }
  } = useZoom(props.projectName);
  const [formState, { text, email }] = useFormState<InviteFormData>({
    firstName: '',
    lastName: '',
    email: ''
  });

  async function onSubmit(e: FormEvent) {
    e.preventDefault();
    const user = {
      firstName: formState.values.firstName,
      lastName: formState.values.lastName,
      email: formState.values.email
    };

    await inviteUser(user);

    formState.reset();
  }

  return (
    <FloatingCard title="Invite User">
      <Box as="form" onSubmit={onSubmit}>
        <Label htmlFor="firstName">First Name</Label>
        <Input {...text('firstName')} required placeholder="First" mb={3} />
        <Label htmlFor="lastName">Last Name</Label>
        <Input {...text('lastName')} required placeholder="Last" mb={3} />
        <Label htmlFor="email">Email</Label>
        <Input
          {...email('email')}
          required
          placeholder="example@example.com"
          mb={3}
        />
        <Text
          p={3}
          mb={3}
          fontSize={1}
          bg="intent.warning"
          sx={{ borderRadius: 0 }}
        >
          <b>Note:</b> Invited users will not show any first or last name until
          the invite has been accepted.
        </Text>
        {status === 'loading' ? (
          <Spinner />
        ) : (
          <Button variant="confirm" width={1} minHeight="3em">
            Invite User
          </Button>
        )}
      </Box>
    </FloatingCard>
  );
}
