import React, { FC, useState, ReactNode, useContext } from 'react';
import { Box, Flex, Text, Heading, Link } from 'rebass';
import { Label, Checkbox, Input, Textarea } from '@rebass/forms';
import { toaster, IconButton } from 'evergreen-ui';
import { useFormState } from 'react-use-form-state';

import Spinner from '../../UI/Spinner';
import styled from '../../../utils/styled';
import { Repo } from '../../../models/github/repo';
import { usePut } from '../../../hooks/use-itx-api';
import { RepoContext } from '.';
import { LinkStatus } from '../../../models/github/org';

interface ListProps {
  repos: Repo[];
}

interface ListItemProps {
  repo: Repo;
}

interface ListItemFormValues {
  homepage: string;
  description: string;
  archived: boolean;
  dcoEnabled: boolean;
}

const ListItemLayout = styled(Box)<{ canEdit?: boolean }>`
  display: grid;
  grid-template-areas:
    'name homepage toggles ${props => (props.canEdit ? 'actions' : '')}'
    'description description toggles ${props =>
      props.canEdit ? 'actions' : ''}';
  grid-template-columns: max-content 1fr max-content ${props =>
    props.canEdit ? 'max-content' : ''};
  align-items: center;
  column-gap: 32px;
`;

const ListItem: FC<ListItemProps> = props => {
  const { org, fetchRepos, hasAccess } = useContext(RepoContext);
  const [editing, setEditing] = useState(false);
  const [formState, { url, label, textarea, checkbox }] = useFormState<
    ListItemFormValues
  >(
    {
      homepage: props.repo.homepage,
      description: props.repo.description,
      archived: props.repo.archived,
      dcoEnabled: props.repo.dcoEnabled
    },
    { withIds: true }
  );
  const [updateRepo, loading] = usePut(
    `github/${props.repo.organization}/repos/${props.repo.name}`
  );

  let canEdit = false;
  if (org && hasAccess) {
    if (org.linkedStatus === LinkStatus.Full) canEdit = true;
  }

  const saveEdits = async () => {
    try {
      await updateRepo({
        ...props.repo,
        ...formState.values
      });
      toaster.success(`Repository ${props.repo.name} updated successfully`);
      if (fetchRepos) await fetchRepos();
    } catch (error) {
      if (error.name === 'AbortError') return;
      console.error(error);
    }
  };

  let homepage: ReactNode;
  let description: ReactNode;
  if (editing) {
    homepage = (
      <Box width={1}>
        <Label {...label('homepage')}>Homepage</Label>
        <Input {...url('homepage')} fontSize={1} />
      </Box>
    );
    description = (
      <>
        <Label {...label('description')}>Description</Label>
        <Textarea {...textarea('description')} />
      </>
    );
  } else {
    homepage = props.repo.homepage ? (
      <Link
        href={props.repo.homepage}
        color="primary.blue"
        fontSize="10px"
        sx={{ textDecoration: 'underline' }}
      >
        {props.repo.homepage}
      </Link>
    ) : (
      <Text fontSize="10px" color="darkGrey" sx={{ fontStyle: 'italic' }}>
        No homepage
      </Text>
    );
    description = props.repo.description ? (
      <Text pt={2} px={2} fontSize={1}>
        {props.repo.description}
      </Text>
    ) : (
      <Text
        pt={2}
        px={2}
        fontSize={1}
        color="darkGrey"
        sx={{ fontStyle: 'italic' }}
      >
        No description
      </Text>
    );
  }

  return (
    <ListItemLayout
      canEdit={canEdit}
      py={3}
      px={2}
      sx={{ borderBottom: '1px solid gainsboro', borderColor: 'borderGrey' }}
    >
      <Box sx={{ gridArea: 'name' }}>
        {editing && <Label>Name</Label>}
        <Heading
          fontFamily="sans"
          py={editing ? '7px' : 0}
          fontSize={editing ? 1 : 2}
          sx={{
            overflowWrap: 'break-word',
            borderBottom: '2px solid transparent'
          }}
        >
          {props.repo.name}
        </Heading>
      </Box>
      <Flex
        alignItems="center"
        justifyContent="flex-end"
        sx={{ gridArea: 'homepage' }}
      >
        {homepage}
      </Flex>
      <Box sx={{ gridArea: 'description' }}>{description}</Box>
      <Flex
        flexDirection="column"
        justifyContent="space-evenly"
        sx={{ gridArea: 'toggles' }}
      >
        <Label>
          <Checkbox
            variant={editing ? 'checkbox' : 'checkbox.display'}
            disabled={!editing}
            {...checkbox('archived')}
          />
          Archived
        </Label>
        <Label>
          <Checkbox
            variant={
              !editing || props.repo.dcoEnabled
                ? 'checkbox.display'
                : 'checkbox'
            }
            disabled={!editing || props.repo.dcoEnabled}
            {...checkbox('dcoEnabled')}
          />
          DCO Enabled
        </Label>
      </Flex>
      {canEdit && (
        <Flex
          flexDirection="column"
          alignItems="center"
          sx={{ gridArea: 'actions' }}
        >
          {editing ? (
            <>
              {loading ? (
                <Spinner inline />
              ) : (
                <IconButton
                  icon="floppy-disk"
                  intent="success"
                  appearance="minimal"
                  onClick={saveEdits}
                />
              )}
              <IconButton
                icon="cross"
                intent="danger"
                appearance="minimal"
                onClick={() => {
                  setEditing(false);
                  formState.reset();
                }}
              />
            </>
          ) : (
            <IconButton
              icon="edit"
              appearance="minimal"
              onClick={() => setEditing(true)}
            />
          )}
        </Flex>
      )}
    </ListItemLayout>
  );
};

export const List: FC<ListProps> = props => {
  if (props.repos.length === 0) {
    return (
      <Box variant="panel.prompt">
        <Text>No repos found</Text>
      </Box>
    );
  }

  return (
    <>
      {props.repos.map((r, i) => (
        <ListItem key={`${r.organization}-${r.name}`} repo={r} />
      ))}
    </>
  );
};
