import React, { useState } from 'react';
import { Box } from 'rebass';
import { RouteComponentProps, Redirect } from 'react-router';

import CI from '../../components/CI';
import Domains from '../../components/Domains';
import { Github } from '../../components/Github';
import { Zoom } from '../../components/Zoom';
import Spinner from '../../components/UI/Spinner';
import { Forwards } from '../../components/Forwards';
import { GroupsIo } from '../../components/GroupsIo';
import { ServiceTabs } from '../../components/ServiceTabs';
import { ProjectInfo } from '../../components/ProjectInfo';
import OtherServices from '../../components/OtherServices';

import { useGet } from '../../hooks/use-itx-api';
import { useAsyncEffect } from '../../hooks/use-async-effect';
import { Project as ProjectModel } from '../../models/project';
import { usePermission } from '../../hooks/use-permission';

interface ProjectRouterProps {
  id: string;
}

const constructTabs = (
  project: ProjectModel,
  reloadProject: () => Promise<void>
) => {
  const tabs = [
    {
      label: 'Domains',
      render: () => <Domains project={project} reloadProject={reloadProject} />
    },
    { label: 'Email Forwards', render: () => <Forwards project={project} /> }
  ];

  if (process.env.REACT_APP_HIDE_GROUPS !== 'true') {
    tabs.push({
      label: 'Groups.io',
      render: () => <GroupsIo project={project} />
    });
  }
  if (process.env.REACT_APP_HIDE_GITHUB !== 'true') {
    tabs.push({ label: 'GitHub', render: () => <Github project={project} /> });
  }
  if (process.env.REACT_APP_HIDE_CI_CD !== 'true') {
    tabs.push({ label: 'CI/CD', render: () => <CI project={project} /> });
  }
  if (process.env.REACT_APP_HIDE_ZOOM !== 'true') {
    tabs.push({
      label: 'Zoom',
      render: () => <Zoom projectName={project.shortName} />
    });
  }
  if (process.env.REACT_APP_HIDE_OTHER_SERVICES !== 'true') {
    tabs.push({
      label: 'Other Services',
      render: () => <OtherServices project={project} />
    });
  }

  return tabs;
};

export const ProjectPageContext = React.createContext<{
  hasAccess: boolean;
  reloadProject: () => Promise<void>;
  project?: ProjectModel;
}>({
  hasAccess: false,
  reloadProject: () => Promise.resolve()
});

export const Project = ({ match }: RouteComponentProps<ProjectRouterProps>) => {
  const canManage = usePermission('manage', 'projects');
  const [project, setProject] = useState<ProjectModel>();
  const [getProject, loading] = useGet<ProjectModel>(
    `projects/${match.params.id}`
  );

  const reloadProject = async () => {
    try {
      const project = await getProject();
      setProject(project);
    } catch (error) {
      if (error.name === 'AbortError') return;
      console.log(error);
    }
  };

  useAsyncEffect(reloadProject, []);

  return (
    <>
      {loading ? (
        <Spinner message="Fetching project info..." />
      ) : (
        <ProjectPageContext.Provider
          value={{
            hasAccess: canManage,
            reloadProject,
            project
          }}
        >
          {project && (
            <Box width={1}>
              <ProjectInfo
                shortName={project.shortName}
                prettyName={project.prettyName}
                formationStatus={project.formationStatus}
                canonicalDomain={project.canonicalDomain}
                seriesName={project.seriesName}
              />
              {/* Open the default tab for the project screen — in this case, the Domains tab */}
              {match.isExact && <Redirect to={`${match.url}/domains`} />}
              <ServiceTabs tabs={constructTabs(project, reloadProject)} />
            </Box>
          )}
        </ProjectPageContext.Provider>
      )}
    </>
  );
};
