import React, { FC, useContext } from 'react';
import { useFormState } from 'react-use-form-state';
import { Input, Label } from '@rebass/forms';
import { Flex, Button } from 'rebass';

import { GithubContext } from './index';
import { usePost } from '../../hooks/use-itx-api';
import { TooltipText } from '../UI/TooltipText';
import { FloatingCard } from '../UI/FloatingCard';
import { useDialog } from '../../hooks/use-dialog';
import { AssociateOrg } from '../../models/github/org';

const tooltips = {
  organizationName:
    'Enter the GitHub organization that should be associated with this project'
};

export const Associate: FC<{ project: string }> = props => {
  const { reloadOrgs } = useContext(GithubContext);
  const [formState, { text, label }] = useFormState<{ org: string }>(
    { org: '' },
    { withIds: true }
  );
  const [associateOrg] = usePost<AssociateOrg, void>(`github`);

  const associateOrgReloadOrgs = async () => {
    try {
      await associateOrg({
        project: `${props.project}`,
        organization: formState.values.org,
        createIfNotExists: false
      });
      reloadOrgs();
      formState.reset();
    } catch (error) {
      if (error.name === 'AbortError') return;
      console.log(error);
    }
  };
  const [dialog, showDialog] = useDialog({
    title: `Associate ${formState.values.org}`,
    message: `Would you like to associate ${formState.values.org} with this project?`,
    intent: 'danger',
    async: true,
    onConfirm: associateOrgReloadOrgs,
    confirmLabel: 'Associate'
  });
  return (
    <FloatingCard title="Associate an Org">
      {dialog}
      <form onSubmit={showDialog}>
        <Label {...label('org')}>
          <TooltipText tip={tooltips.organizationName}>
            Organization Name
          </TooltipText>
        </Label>
        <Input {...text('org')} placeholder="linuxfoundation" required />
        <Flex justifyContent="flex-end" mt={2}>
          <Button variant="confirm">Associate</Button>
        </Flex>
      </form>
    </FloatingCard>
  );
};
