import React from 'react';
import { Flex, Button, Box } from 'rebass';

import { useDialog } from '../../../../hooks/use-dialog';

interface CreateFormValues {
  url: string;
}

interface CreateProps {
  hasPrimaryDomain: boolean;
  hasManagedAccount: boolean;
  createConfluence: () => Promise<void>;
}

export default function Create(props: CreateProps) {
  const [dialog, showDialog] = useDialog({
    title: 'Create Confluence Site',
    async: true,
    message: (
      <>
        Are you sure you want to create a Confluence instance for this project?
        <br />
        <b>This will have a significant financial impact on the project.</b>
      </>
    ),
    intent: 'danger',
    onConfirm: props.createConfluence
  });

  if (!props.hasPrimaryDomain) {
    return (
      <Box mt={3} variant="alert.warning">
        This service is unavailable because this project does not have a primary
        domain. Set a primary domain in the Domains tab, or file a ticket to
        have a primary domain added to this project.
      </Box>
    );
  }

  if (!props.hasManagedAccount) {
    return (
      <Box mt={3} variant="alert.warning">
        This service is unavailable because this project does not have a managed
        AWS account. Create an account in the AWS tab to the left, or file a
        ticket to have an account created for this project.
      </Box>
    );
  }

  return (
    <Flex as="form" mt={3} onSubmit={showDialog}>
      {dialog}
      <Button variant="warning" width={1}>
        Create Site
      </Button>
    </Flex>
  );
}
