import { readableColor, lighten } from 'polished';

export function stringToColors(str: string) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    color += ('00' + value.toString(16)).substr(-2);
  }

  const lightenedColor = lighten(0.3, color);

  return {
    bg: lightenedColor,
    color: readableColor(lightenedColor, '#333', '#FFF')
  };
}

export function capitalize(str: string) {
  return str
    .split('')
    .map((char, idx) => (idx === 0 ? char.toUpperCase() : char))
    .join('');
}

export function formatName(name: string) {
  return name
    .split('-')
    .map(word => {
      if (word === `ci`) return 'CI';
      if (word === `github`) return 'GitHub';
      return capitalize(word);
    })
    .join(' ');
}

export function compareNames(a: string, b: string) {
  if (a < b) return -1;
  if (a > b) return 1;

  return 0;
}
