import React from 'react';
import { Icon } from 'evergreen-ui';
import { Button, Flex } from 'rebass';
import { withRouter, RouteComponentProps } from 'react-router-dom';

interface NavButtonsProps extends RouteComponentProps {
  pages: number;
  activePage: number;
}

export const NavButtons = withRouter((props: NavButtonsProps) => {
  const params = new URLSearchParams(props.location.search);

  const goToPage = (page: number) => {
    if (page < 1 || page > props.pages) return;

    params.set('page', page.toString());
    props.history.push({
      pathname: '/',
      search: params.toString()
    });
  };

  return (
    <Flex alignItems="center">
      <Button
        mr={1}
        p={1}
        color="primary.grey"
        variant="flat"
        onClick={() => {
          goToPage(props.activePage - 1);
        }}
      >
        <Flex alignItems="center" justifyContent="center">
          <Icon icon="chevron-left" />
        </Flex>
      </Button>
      {[...Array(props.pages).keys()].map(v => (
        <Button
          mx={1}
          key={v}
          variant={v + 1 === props.activePage ? 'flat.active' : 'flat'}
          sx={{ lineHeight: 1 }}
          onClick={() => {
            goToPage(v + 1);
          }}
        >
          {v + 1}
        </Button>
      ))}
      <Button
        ml={1}
        p={1}
        color="primary.grey"
        variant="flat"
        onClick={() => {
          goToPage(props.activePage + 1);
        }}
      >
        <Flex alignItems="center" justifyContent="center">
          <Icon icon="chevron-right" />
        </Flex>
      </Button>
    </Flex>
  );
});
