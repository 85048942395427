import React, { Component, Suspense, lazy } from 'react';
import { toaster } from 'evergreen-ui';

import { canWe } from '../../utils/auth';
import styled from '../../utils/styled';
import apiClient from '../../utils/api-client';
import Spinner from '../UI/Spinner';
import CardArea from '../UI/CardArea';
import { Project } from '../../models/project';
import { TLDDetails } from '../../models/domain/tld';
import { Domain, DomainAvailability, DomainPrice } from '../../models/domain';

const Add = lazy(() => import('./Add'));
const List = lazy(() => import('./List'));
const Info = lazy(() => import('./Info'));

const DomainsLayout = styled.div<{ canAdd?: boolean }>`
  width: 100%;
  display: grid;
  justify-items: stretch;
  grid-template-areas:
    ${props => (props.canAdd ? "'add add'" : '')}
    'list info';
  grid-template-columns: 1fr 2fr;
  grid-gap: 32px;
`;

export interface DomainRegistrationInfo {
  availability: DomainAvailability;
  price?: DomainPrice;
  hasWhoisSupport?: boolean;
}

interface DomainsProps {
  project: Project;
  reloadProject: () => void;
}

interface DomainsState {
  canManage?: boolean;
  isLoadingDomains: boolean;
  domains: Domain[];
  selectedDomain: Domain | undefined;
}

class Domains extends Component<DomainsProps, DomainsState> {
  displayName = 'DomainsServiceTab';
  abortController = new AbortController();
  state: DomainsState = {
    canManage: undefined,
    isLoadingDomains: true,
    domains: new Array<Domain>(),
    selectedDomain: undefined
  };

  fetchDomains = async () => {
    this.setState({ isLoadingDomains: true });
    const domains: Domain[] = await apiClient
      .get('domains', {
        searchParams: { project: this.props.project.shortName },
        signal: this.abortController.signal
      })
      .json();
    domains.sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();

      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });

    this.setState({ domains, isLoadingDomains: false });
  };

  checkWhoisSupport = async (domainName: string) => {
    const parts = domainName.split('.');
    if (parts) {
      const tld = parts[parts.length - 1];
      const { details }: TLDDetails = await apiClient
        .get(`domains/tlds/${tld}`)
        .json();
      return details.whoisPrivacy;
    }

    return false;
  };

  checkDomainAvailability = async (domainName: string) => {
    const availability: DomainAvailability = await apiClient
      .get(`domains/${domainName}/availability`, {
        signal: this.abortController.signal
      })
      .json();
    let price: DomainPrice | undefined;
    let hasWhoisSupport: boolean | undefined;
    if (availability.available) {
      price = await apiClient
        .get(`domains/${domainName}/price`, {
          signal: this.abortController.signal
        })
        .json();
      hasWhoisSupport = await this.checkWhoisSupport(domainName);
    }

    return {
      availability,
      price,
      hasWhoisSupport
    };
  };

  registerDomain = async (
    registrationInfo: DomainRegistrationInfo,
    autoRenew: boolean,
    whoisPrivacy: boolean
  ) => {
    const domainName = registrationInfo.availability.domain;
    if (!this.state.domains.find(domain => domain.name === domainName)) {
      await apiClient.post(`domains`, {
        json: {
          name: domainName,
          project: this.props.project.shortName
        },
        signal: this.abortController.signal
      });
    }

    const {
      price,
      availability: { premium }
    } = registrationInfo;
    await apiClient.post(`domains/${domainName}/register`, {
      json: {
        autoRenew,
        whoisPrivacy,
        premiumPrice: premium && price ? price.price : undefined
      },
      signal: this.abortController.signal
    });
    toaster.success(`Domain '${domainName}' successfully registered`);
    this.fetchDomains();
  };

  setPrimaryDomain = async (domainName: string) => {
    await apiClient.put(`projects/${this.props.project.shortName}`, {
      json: {
        ...this.props.project,
        canonicalDomain: domainName
      },
      signal: this.abortController.signal
    });
    toaster.success(`Primary domain successfully set to ${domainName}`);
  };

  selectDomain = (domainName: string) => {
    const selectedDomain = this.state.domains.find(
      domain => domain.name === domainName
    );
    if (selectedDomain) {
      this.setState({ selectedDomain });
    }
  };

  async componentDidMount() {
    try {
      const canManage = await canWe('manage', 'domains');
      this.setState({ canManage });
      this.fetchDomains();
    } catch (error) {
      if (error.name === 'AbortError') return;
      toaster.danger(`Error fetching domains: ${error.message}`);
    }
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  render() {
    const { canManage, domains, selectedDomain, isLoadingDomains } = this.state;

    return (
      <DomainsLayout canAdd={canManage}>
        {canManage && (
          <CardArea gridArea="add">
            <Suspense fallback={<Spinner />}>
              <Add
                fetchDomains={this.fetchDomains}
                checkDomainAvailability={this.checkDomainAvailability}
                registerDomain={this.registerDomain}
              />
            </Suspense>
          </CardArea>
        )}
        <CardArea gridArea="list">
          <Suspense fallback={<Spinner />}>
            <List
              domains={domains}
              setSelectedDomain={this.selectDomain}
              isLoading={isLoadingDomains}
              primaryDomain={this.props.project.canonicalDomain}
            />
          </Suspense>
        </CardArea>
        <CardArea gridArea="info">
          {selectedDomain && (
            <Suspense
              fallback={<Spinner message={'Loading domain information'} />}
            >
              <Info
                canManage={canManage}
                primaryDomain={this.props.project.canonicalDomain}
                setPrimaryDomain={this.setPrimaryDomain}
                checkDomainAvailability={this.checkDomainAvailability}
                registerDomain={this.registerDomain}
                domain={selectedDomain}
              />
            </Suspense>
          )}
        </CardArea>
      </DomainsLayout>
    );
  }
}

export default Domains;
