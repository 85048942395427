import React, { FC, useState } from 'react';
import { Box, Heading, Button } from 'rebass';

import styled from '../../utils/styled';
import { Config } from '../../models/ci';
import { FloatingCard } from '../UI/FloatingCard';
import { formatName } from '../../utils/string-handlers';

interface ListProps {
  configs: Config[];
  filterConfigs: (provider?: string, org?: string) => Promise<void>;
}

const ListItem = styled.li<{ active: boolean }>`
  display: block;
  transition: 0.15s ease-in-out;
  cursor: pointer;
  padding: ${props => props.theme.space[3]}px;
  border-top: 1px solid ${props => props.theme.colors.borderGrey};
  color: ${props =>
    props.active ? props.theme.colors.white : props.theme.colors.black};
  background: ${props =>
    props.active ? props.theme.colors.primary.blue : props.theme.colors.white};

  :hover {
    color: ${props => props.theme.colors.black};
    background: ${props => props.theme.colors.accent.blue};
  }
`;

const List: FC<ListProps> = props => {
  const [activeItem, setActiveItem] = useState('');
  const providers = new Set(
    props.configs.map(config => config.repository.provider)
  );

  const providerOrgsMap: Map<string, Set<string>> = new Map();

  for (let provider of providers) {
    const configsWithProvider = props.configs.filter(
      config => config.repository.provider === provider
    );
    providerOrgsMap.set(
      provider,
      new Set(configsWithProvider.map(config => config.repository.org))
    );
  }

  function selectOrg(org?: string, provider?: string) {
    props.filterConfigs(provider, org);

    if (org && provider) {
      setActiveItem(`${provider}-${org}`);
    } else {
      setActiveItem('');
    }
  }

  return (
    <FloatingCard title="Organizations">
      {activeItem && (
        <Button variant="outline" width={1} mb={3} onClick={() => selectOrg()}>
          Reset Filter
        </Button>
      )}
      {[...providerOrgsMap.entries()].map(([provider, orgs], idx) => (
        <Box mb={idx + 1 === providerOrgsMap.size ? 0 : 3} key={provider}>
          <Heading color="primary.grey" fontSize={2} mb={1}>
            {formatName(provider)}
          </Heading>
          <ul style={{ margin: 0, padding: 0 }}>
            {[...orgs].map(org => (
              <ListItem
                key={org}
                active={activeItem === `${provider}-${org}`}
                onClick={() => selectOrg(org, provider)}
              >
                {org}
              </ListItem>
            ))}
          </ul>
        </Box>
      ))}
    </FloatingCard>
  );
};

export default List;
