import { HTTPError } from 'ky';
import { useEffect } from 'react';
import { toaster } from 'evergreen-ui';

export function useSuccessToast(
  message: string,
  status: 'error' | 'loading' | 'success' | 'idle',
  reset: () => void
) {
  useEffect(() => {
    if (status === 'success') {
      toaster.success(message);
      reset();
    }
  }, [status, message, reset]);
}

export function useHTTPErrorToast(prefix: string, error?: HTTPError) {
  useEffect(() => {
    if (error?.response) {
      if (!error.response.bodyUsed) {
        error.response
          .json()
          .then(({ message }) => toaster.danger(`${prefix}: ${message}`));
      }
    } else if (error) {
      toaster.danger(`${error.name}: ${error.message}`);
    }
  }, [prefix, error]);
}
