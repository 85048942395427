export const fonts = {
  sans: "Poppins, Roboto, 'Helvetica Neue', Arial, sans-serif"
};

export const baseHeadingStyles = {
  color: 'black',
  fontFamily: 'sans',
  fontWeight: 600
};

export const text = {
  title: {
    ...baseHeadingStyles,
    fontSize: '48px'
  },
  subtitle: {
    ...baseHeadingStyles,
    fontSize: '32px'
  },
  heading: {
    ...baseHeadingStyles,
    fontSize: '24px'
  },
  subheading: {
    ...baseHeadingStyles,
    fontSize: '18px'
  },
  label: {
    ...baseHeadingStyles,
    color: 'primary.grey',
    fontSize: '12px',
    width: '100%'
  },
  tooltip: {
    fontFamily: 'sans',
    fontWeight: 400
  }
};
