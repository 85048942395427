import React, { ReactNode } from 'react';
import slugify from '@sindresorhus/slugify';
import { motion, AnimatePresence } from 'framer-motion';
import {
  NavLink,
  Route,
  Switch,
  withRouter,
  RouteComponentProps
} from 'react-router-dom';
import { Flex, Card } from 'rebass';

import styled from '../../utils/styled';

export interface Tab {
  label: string;
  render: () => ReactNode;
}

export interface ServiceTabsProps extends RouteComponentProps {
  tabs: Tab[];
}

const Tab = styled(NavLink)`
  padding: 22px 12px;
  font-size: 14px;
  box-sizing: border-box;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.primary.grey};
  white-space: nowrap;
  font-weight: 600;
  border-bottom: 3px solid white;
  transition: 0.2s ease-in-out;
`;

const TabListWidthConstraint = styled(Flex)`
  flex-basis: 1280px;
`;

const TabContentWidthConstraint = styled(Flex)`
  flex-basis: 1280px;
`;

export const ServiceTabs = withRouter(({ tabs, match }: ServiceTabsProps) => {
  return (
    <Flex flexDirection="column" width={1} justifyContent="center">
      <Card bg="white" sx={{ boxShadow: 0 }}>
        <Flex justifyContent="center">
          <TabListWidthConstraint flexDirection="row">
            {tabs.map(({ label }) => (
              <Tab
                key={`${slugify(label, { decamelize: false })}-link`}
                to={`${match.url}/${slugify(label, {
                  decamelize: false
                })}`}
                activeStyle={{
                  color: 'black',
                  borderBottom: '3px solid rgb(108, 188, 232)',
                  textDecoration: 'none'
                }}
              >
                {label}
              </Tab>
            ))}
          </TabListWidthConstraint>
        </Flex>
      </Card>
      <Flex justifyContent="center">
        <TabContentWidthConstraint pt={4}>
          <AnimatePresence exitBeforeEnter initial={false}>
            <Switch>
              {tabs.map(({ label, render }) => (
                <Route
                  key={`${slugify(label, { decamelize: false })}-route`}
                  path={`${match.url}/${slugify(label, {
                    decamelize: false
                  })}`}
                  exact
                  render={() => (
                    <motion.div
                      animate={{ opacity: 1, height: 'auto' }}
                      exit={{ opacity: 0, height: 0 }}
                      style={{
                        width: '100%'
                      }}
                    >
                      {render()}
                    </motion.div>
                  )}
                />
              ))}
            </Switch>
          </AnimatePresence>
        </TabContentWidthConstraint>
      </Flex>
    </Flex>
  );
});
