import React, { FunctionComponent, useCallback, useState } from 'react';
import { Textarea } from '@rebass/forms';
import { IconButton, Tooltip } from 'evergreen-ui';
import { useFormState } from 'react-use-form-state';
import { Box, Flex, Text, Heading } from 'rebass';

import { FloatingCard } from '../../UI/FloatingCard';
import { Account } from '../../../models/other-services';

interface InfoProps {
  info: Account;
  canManage: boolean;
  updateDescription: (description: string, accountId: string) => Promise<void>;
}

function getAccountTypeLabel(type: string) {
  switch (type) {
    case 'managed':
      return 'ITX Managed';
    case 'it-managed':
      return 'IT Managed';
    case 'unmanaged':
      return 'IT Unmanaged';
  }
}

const Info: FunctionComponent<InfoProps> = props => {
  const [editing, setEditing] = useState(false);
  const [formState, { textarea }] = useFormState({
    description: props.info.description
  });

  const saveDescription = useCallback(() => {
    setEditing(false);
    if (formState.values.description !== props.info.description) {
      props.updateDescription(
        formState.values.description,
        props.info.accountId
      );
    }
  }, [formState.values.description, props]);

  let button = (
    <Tooltip content="Edit Description">
      <IconButton
        appearance="minimal"
        icon="edit"
        onClick={() => setEditing(true)}
      >
        Edit
      </IconButton>
    </Tooltip>
  );
  let description = (
    <Text color={props.info.description ? 'black' : 'primary.grey'}>
      {props.info.description || '(none provided)'}
    </Text>
  );
  if (editing) {
    button = (
      <Tooltip content="Save Description">
        <IconButton
          appearance="minimal"
          icon="floppy-disk"
          onClick={saveDescription}
        >
          Save
        </IconButton>
      </Tooltip>
    );
    description = (
      <Textarea
        {...textarea('description')}
        placeholder="Account used for..."
      />
    );
  }

  return (
    <FloatingCard
      title="Account Info"
      tags={[
        <Box
          key="status"
          variant={
            props.info.accountType === 'managed' ? 'pill.confirm' : 'pill'
          }
        >
          {getAccountTypeLabel(props.info.accountType)}
        </Box>
      ]}
    >
      <Box width={1} mb={3}>
        <Flex
          justifyContent={props.canManage ? 'space-between' : 'flex-start'}
          alignItems="center"
          mb={2}
        >
          <Heading variant="subheading">Description</Heading>
          {props.canManage && props.info.accountType === 'unmanaged' && button}
        </Flex>
        {description}
      </Box>
      <Box width={1} height="1px" bg="borderGrey" my={3} />
      <Flex width={1} flexWrap="wrap">
        <Box width={1} mb={3}>
          <Text color="primary.grey" fontSize={1}>
            Email
          </Text>
          <Heading
            variant="heading"
            color={props.info.email ? 'black' : 'primary.grey'}
          >
            <Text>{props.info.email || 'None'}</Text>
          </Heading>
        </Box>
        <Box width={1 / 2} mb={3}>
          <Text color="primary.grey" fontSize={1}>
            Account ID
          </Text>
          <Heading variant="heading">
            <Text>{props.info.accountId}</Text>
          </Heading>
        </Box>
        <Box width={1 / 2} mb={3}>
          <Text color="primary.grey" fontSize={1}>
            Status
          </Text>
          <Heading
            variant="heading"
            color={
              props.info.status.toLowerCase() === 'active'
                ? 'primary.green'
                : 'intent.warning'
            }
          >
            <Text>{props.info.status}</Text>
          </Heading>
        </Box>
      </Flex>
    </FloatingCard>
  );
};

export default Info;
