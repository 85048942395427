import { ZoneTemplateValues } from '../models/domain/zone';
import { JiraLicenses } from '../models/other-services';

export const getServiceTemplateLabel = (service: ZoneTemplateValues) => {
  switch (service) {
    case ZoneTemplateValues.Pantheon:
      return 'Pantheon Wordpress Multisite';
    case ZoneTemplateValues.GitHubPages:
      return 'GitHub Pages';
    case ZoneTemplateValues.Netlify:
      return 'Netlify';
    default:
  }
};

export const getJiraLicenseLabel = (license: JiraLicenses) => {
  switch (license) {
    case JiraLicenses.NonProfit:
      return 'Non-Profit';
    case JiraLicenses.OpenSource:
      return 'Open Source';
    default:
      return license as string;
  }
};
