import React, { FC, useContext, useEffect, useMemo } from 'react';
import { Link, Flex, Text } from 'rebass';

import { TabContext } from './context';

interface TabLabelProps {
  tabs: string[];
  activeTab: string;
}

export const TabLabels: FC<TabLabelProps> = props => {
  const { dispatch, state } = useContext(TabContext);
  const labels = useMemo(() => Array.from(props.tabs), [props.tabs]);

  useEffect(() => {
    dispatch({ type: 'setActive', label: labels[0] });
  }, [dispatch, labels]);

  return (
    <Flex
      sx={{
        borderTop: '1px solid gainsboro',
        borderBottom: '1px solid gainsboro',
        borderColor: 'borderGrey'
      }}
    >
      {labels.map(label => (
        <Link
          as="div"
          py="22px"
          px="12px"
          key={label}
          color={label === state.active ? 'black' : 'primary.grey'}
          sx={{
            borderBottom: '2px solid grey',
            borderColor:
              label === state.active ? 'rgb(108, 188, 232)' : 'white',
            cursor: 'pointer',
            transition: '0.2s ease-in-out'
          }}
          onClick={() => dispatch({ type: 'setActive', label })}
        >
          <Text fontSize={1} fontWeight={600}>
            {label}
          </Text>
        </Link>
      ))}
    </Flex>
  );
};
