import { User } from '../../models/zoom';
import apiClient from '../../utils/api-client';
import { compareNames } from '../../utils/string-handlers';

export async function getUsersByProject(
  _: string,
  project: string
): Promise<User[]> {
  const users: User[] = await apiClient
    .get('zoom/users', {
      searchParams: {
        project
      }
    })
    .json();
  return users.sort((userA, userB) =>
    compareNames(userA.firstName, userB.firstName)
  );
}

export async function deleteUserById({ id }: { id: string }): Promise<void> {
  await apiClient.delete(`zoom/users/${id}`);
}

export function inviteUserWithInfo(project: string) {
  return async ({
    firstName,
    lastName,
    email
  }: {
    firstName: string;
    lastName: string;
    email: string;
  }) => {
    await apiClient.post(`zoom/users`, {
      json: {
        firstName,
        lastName,
        email,
        project
      }
    });
  };
}
