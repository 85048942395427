import React, { FC, useState, useContext, FormEvent } from 'react';
import { Input } from '@rebass/forms';
import { toaster } from 'evergreen-ui';
import { useFormState } from 'react-use-form-state';
import { Flex, Heading, Box, Button, Text } from 'rebass';

import Spinner from '../UI/Spinner';
import { ForwardsContext } from '.';
import { Forward } from '../../models/forwards';
import { usePost } from '../../hooks/use-itx-api';
import { FloatingCard } from '../UI/FloatingCard';
import { TooltipText } from '../UI/TooltipText';
import { Recipient } from './Recipient';
import { emailRegex } from '../../utils/const';
import { useLoading } from '../../hooks/use-loading';

const tooltips = {
  from: 'Enter the alias for the email forward you would like to create.',
  to:
    'Enter the full email address of a recipient who should receive the email forward.'
};

interface AddRecipientFormValues {
  alias: string;
  recipient: string;
}

// tslint:disable-next-line:max-func-body-length
export const Add: FC = () => {
  const [{ isLoading, message }, { start, stop }] = useLoading();
  const [recipients, setRecipients] = useState<string[]>([]);
  const { project, reloadForwards } = useContext(ForwardsContext);
  const [formState, { text, email }] = useFormState<AddRecipientFormValues>({
    alias: '',
    recipient: ''
  });
  const [addForward] = usePost<Forward, void>(
    `forwards/${project.shortName}/forwards`
  );

  const addForwardAndRefresh = async (e: FormEvent) => {
    e.preventDefault();
    const { alias } = formState.values;
    try {
      start('Adding forward...');
      await addForward({
        alias,
        recipients
      });
      toaster.success(`Forward with alias "${alias}" successfully added`);
      start('Fetching forwards...');
      await reloadForwards();
      formState.reset();
      setRecipients([]);
    } catch (error) {
      if (error.name === 'AbortError') return;
      console.log(error);
    }

    stop();
  };

  const addRecipientToList = (e: FormEvent) => {
    e.preventDefault();
    setRecipients([formState.values.recipient, ...recipients]);
    formState.resetField('recipient');
  };

  return (
    <FloatingCard title="Add a Forward">
      {isLoading ? (
        <Spinner message={message} />
      ) : (
        <>
          <Heading fontSize={3}>
            <TooltipText tip={tooltips.from}>From</TooltipText>
          </Heading>
          <Flex>
            <Input
              {...text('alias')}
              form="addForward"
              placeholder="info"
              required
            />
            <Text
              ml={3}
              pt={2}
              sx={{ minWidth: 'initial' }}
            >{`@${project.canonicalDomain}`}</Text>
          </Flex>
          <Heading fontSize={3} mt={4}>
            <TooltipText tip={tooltips.to}>To</TooltipText>
          </Heading>
          {recipients.length > 0 ? (
            <Box my={1}>
              {recipients.map(r => (
                <Recipient
                  key={r}
                  deletable
                  onDelete={recipientToDelete => {
                    setRecipients(
                      recipients.filter(r => r !== recipientToDelete)
                    );
                  }}
                >
                  {r}
                </Recipient>
              ))}
            </Box>
          ) : (
            <Flex justifyContent="center" mb={3}>
              <Text color="intent.warning">Add recipients below</Text>
            </Flex>
          )}
          <Flex as="form" onSubmit={addRecipientToList}>
            <Input
              {...email('recipient')}
              placeholder="example@example.com"
              required
            />
            <Button
              ml={3}
              variant={
                emailRegex.test(formState.values.recipient.toLowerCase())
                  ? 'confirm'
                  : 'disabled'
              }
            >
              Add
            </Button>
          </Flex>
          <Flex
            as="form"
            id="addForward"
            onSubmit={addForwardAndRefresh}
            justifyContent="flex-end"
            mt={3}
          >
            <Button
              variant={
                formState.values.alias && recipients.length > 0
                  ? 'confirm'
                  : 'disabled'
              }
            >
              Add Forward
            </Button>
          </Flex>
        </>
      )}
    </FloatingCard>
  );
};
