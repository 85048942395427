import React, { FC, useContext } from 'react';
import { Link } from 'react-router-dom';
import { HelpCircle } from 'react-feather';
import { Button, Flex, Image, Text } from 'rebass';

import { PKCEContext } from '../PKCEProvider/context';
import logo from '../../assets/lf-logo-new-w.png';

export const Navbar: FC = () => {
  const { logout } = useContext(PKCEContext);

  return (
    <Flex
      as="nav"
      width={1}
      bg="brand"
      p={4}
      justifyContent="center"
      alignItems="center"
    >
      <Flex alignItems="center" justifyContent="space-between" px={3} width={1}>
        <Flex flexDirection="row" alignItems="center">
          <Flex width={130} mr={4} justifyContent="center" alignItems="center">
            <Image width={1} src={logo} height="auto" />
          </Flex>
          <Link style={{ color: 'white', textDecoration: 'none' }} to="/">
            <Text fontSize={3}>IT Automation</Text>
          </Link>
        </Flex>
        <Flex alignItems="center">
          <Button
            bg="white"
            color="brand"
            fontSize={1}
            sx={{
              borderRadius: 0,
              ':hover': {
                bg: 'borderGrey'
              }
            }}
            onClick={logout}
          >
            Log Out
          </Button>
          {process.env.REACT_APP_HELP_URL && (
            <a
              href={process.env.REACT_APP_HELP_URL}
              style={{ height: 'fit-content' }}
            >
              <Flex justifyContent="center" alignItems="center" ml={4}>
                <HelpCircle size={36} color="white" />
              </Flex>
            </a>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};
