import React, { useContext, FormEvent } from 'react';
import { Input, Select, Label } from '@rebass/forms';
import { useFormState } from 'react-use-form-state';
import { toaster } from 'evergreen-ui';
import { Box, Button, Flex } from 'rebass';

import Spinner from '../UI/Spinner';
import { Group, Type, Visibility } from '../../models/groups';
import { GroupsIoContext } from './index';
import { usePost } from '../../hooks/use-itx-api';
import { useDialog } from '../../hooks/use-dialog';
import { TooltipText } from '../UI/TooltipText';
import { FloatingCard } from '../UI/FloatingCard';

const tooltips = {
  add: {
    name: 'Enter a name for the group',
    visibility: (
      <>
        <p>
          The visibility settings for the list. Private lists cannot be changed
          back to public lists.
        </p>
        <ul>
          <li>Public: Anyone can discover, join, or view list emails.</li>
          <li>
            Private: The list and its emails are only visible to members, and
            new members must be added or approved by the moderators.
          </li>
        </ul>
      </>
    ),
    type: (
      <>
        <p>The list type:</p>
        <ul>
          <li>
            Moderated Discussion: New members cannot post to the list without
            their emails being approved by a moderator.
          </li>
          <li>
            Open Discussion: New members can immediately post to the list.
          </li>
          <li>Announcement: Only moderators can post to the list.</li>
        </ul>
      </>
    )
  }
};

interface AddGroupFormValues {
  groupName: string;
  visibility: Visibility;
  type: Type;
  description: string;
}

// tslint:disable-next-line:max-func-body-length
export const Add = () => {
  const { project, reloadGroups } = useContext(GroupsIoContext);
  const emptyGroup = {
    groupName: '',
    visibility: Visibility.Public,
    type: Type.DiscussionModerated,
    description: ''
  };
  const [formState, { text, select, label }] = useFormState<AddGroupFormValues>(
    emptyGroup,
    { withIds: true }
  );
  const [addGroup, loading] = usePost<Group, void>(
    `groupsio/${project.shortName}/lists`
  );

  const addGroupAndReloadGroups = async () => {
    try {
      await addGroup(formState.values);
      toaster.success(
        `Group "${formState.values.groupName}" successfully created`
      );
      reloadGroups();
      formState.reset();
    } catch (error) {
      if (error.name === 'AbortError') return;
      console.log(error);
    }
  };
  const [dialog, showDialog] = useDialog({
    title: `Add "${formState.values.groupName}" as Private`,
    intent: 'warning',
    async: true,
    message: (
      <>
        Are you sure you want to make this group "{formState.values.groupName}
        " Private?
        <br />
        Private groups cannot be changed to Public later.
      </>
    ),
    onConfirm: addGroupAndReloadGroups
  });

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (formState.values.visibility === Visibility.Private) {
      showDialog();
    } else {
      addGroupAndReloadGroups();
    }
  };

  return (
    <FloatingCard title="Add a Group">
      {dialog}
      <form onSubmit={handleSubmit}>
        <Flex alignItems="center">
          <Box width={1 / 3} pr={2}>
            <Label {...label('groupName')}>
              <TooltipText tip={tooltips.add.name}>Group name</TooltipText>
            </Label>
            <Input {...text('groupName')} placeholder="dev" required />
          </Box>
          <Box width={1 / 3} px={2}>
            <Label {...label('visibility')}>
              <TooltipText tip={tooltips.add.visibility}>
                Visibility
              </TooltipText>
            </Label>
            <Select {...select('visibility')} required>
              <option value={Visibility.Public}>Public</option>
              <option value={Visibility.Private}>Private</option>
            </Select>
          </Box>
          <Box width={1 / 3} pl={2}>
            <Label {...label('type')}>
              <TooltipText tip={tooltips.add.type}>Type</TooltipText>
            </Label>
            <Select {...select('type')} required>
              <option value={Type.DiscussionModerated}>
                Moderated Discussion
              </option>
              <option value={Type.DiscussionOpen}>Open Discussion</option>
              <option value={Type.Announcement}>Announcement</option>
            </Select>
          </Box>
        </Flex>
        <Box width={1} mt={2}>
          <Label {...label('description')}>Description</Label>
          <Input
            {...text('description')}
            required
            placeholder="This group is used for..."
          />
        </Box>
        <Flex justifyContent="flex-end" mt={2}>
          {loading ? (
            <Spinner inline />
          ) : (
            <Button variant="confirm">Add Group</Button>
          )}
        </Flex>
      </form>
    </FloatingCard>
  );
};
