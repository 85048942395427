import React, { createContext, FC, useContext, useState } from 'react';
import { Button, Flex, Text, Link } from 'rebass';

import { Add } from './Add';
import { List } from './List';
import Spinner from '../UI/Spinner';
import CardArea from '../UI/CardArea';
import styled from '../../utils/styled';
import { Project } from '../../models/project';
import { MessageCard } from '../UI/MessageCard';
import { useDialog } from '../../hooks/use-dialog';
import { ProjectPageContext } from '../../pages/Project';
import { useLoading } from '../../hooks/use-loading';
import { Forward, ServiceStatus } from '../../models/forwards';
import { useGet, usePost } from '../../hooks/use-itx-api';
import { usePermission } from '../../hooks/use-permission';
import { useAsyncEffect } from '../../hooks/use-async-effect';

const ForwardsLayout = styled.div<{ hasAccess?: boolean }>`
width: 100%;
  display: grid;
  grid-gap: 32px;
  grid-template-areas:
'${props => (props.hasAccess ? 'add' : '')} list';
  grid-template-columns: ${props => (props.hasAccess ? '1fr 2fr' : 'auto')};
`;

export const ForwardsContext = createContext<{
  hasAccess: boolean;
  project: Partial<Project>;
  reloadForwards: () => Promise<void>;
}>({
  hasAccess: false,
  project: {},
  reloadForwards: () => Promise.resolve()
});

export const Forwards: FC<{
  project: Project;
  // tslint:disable-next-line:max-func-body-length
}> = props => {
  const { reloadProject } = useContext(ProjectPageContext);
  const canManage = usePermission('manage', 'forwards');
  const [forwards, setForwards] = useState<Forward[]>([]);
  const [{ message, isLoading }, { start, stop }] = useLoading(
    true,
    'Checking service status'
  );
  const [isEnabled, setIsEnabled] = useState(false);
  const [cannotEnable, setCannotEnable] = useState(true);
  const [enableService, enableServiceLoading] = usePost<
    { project: string },
    void
  >(`forwards`);
  const [getServiceStatus] = useGet<ServiceStatus>(
    `forwards/${props.project.shortName}`
  );
  const [getForwards] = useGet<Forward[]>(
    `forwards/${props.project.shortName}/forwards`
  );

  const checkServiceStatus = async () => {
    try {
      const status = await getServiceStatus();
      if (status.enabled) {
        setIsEnabled(true);
        const res = await getForwards();
        setForwards(res);
      } else if (status.availabilityToEnable) {
        setCannotEnable(false);
      }
    } catch (error) {
      if (error.name === 'AbortError') return;
      console.error(error);
    }

    stop();
  };

  useAsyncEffect(checkServiceStatus, []);

  const enableServiceAndFetchForwards = async () => {
    try {
      start('Enabling service...');
      await enableService({
        project: props.project.shortName
      });
      reloadProject();
      setIsEnabled(true);
    } catch (error) {
      if (error.name === 'AbortError') return;
      console.log(error);
    }

    stop();
  };
  const [dialog, showDialog] = useDialog({
    title: 'Enable Email Forwards',
    intent: 'warning',
    message:
      "Enabling email forwarding adds a monthly cost to the project's budget. Are you sure?",
    async: true,
    onConfirm: enableServiceAndFetchForwards
  });

  const reloadForwards = async () => {
    try {
      const forwards = await getForwards();
      setForwards(forwards);
    } catch (error) {
      if (error.name === 'AbortError') return;
      console.log(error);
    }
  };

  if (isLoading) {
    return <Spinner message={message} />;
  }

  if (isEnabled) {
    return (
      <Flex width={1}>
        <ForwardsContext.Provider
          value={{
            hasAccess: canManage,
            reloadForwards,
            project: props.project
          }}
        >
          <ForwardsLayout hasAccess={canManage}>
            {canManage && (
              <CardArea gridArea="add">
                <Add />
              </CardArea>
            )}
            <CardArea gridArea="list">
              <List forwards={forwards} />
            </CardArea>
          </ForwardsLayout>
        </ForwardsContext.Provider>
      </Flex>
    );
  }

  if (!props.project.canonicalDomain) {
    return (
      <MessageCard title="Not Available">
        <Text>
          In order to enable email forwarding for this project, you first need
          to specify a primary domain. You can do this in the Domains tab.
        </Text>
      </MessageCard>
    );
  }

  if (cannotEnable) {
    return (
      <MessageCard title="Not Available">
        <Text>
          Your project is using another service that conflicts with our mail
          forward service. If you would like to enable/manage it in ITX, please
          contact us by filing a ticket{' '}
          <Link href="https://jira.linuxfoundation.org/servicedesk/customer/portal/2/group/19">
            here
          </Link>
          .
        </Text>
      </MessageCard>
    );
  }

  return (
    <MessageCard title="Not Enabled">
      <Text mb={2}>This project does not have basic email forwarding.</Text>
      <Text mb={4}>
        Email forwarding adds <b>$5 per month</b> to the project's budget. This
        is an internal LF cost.
      </Text>
      <Flex justifyContent="center">
        {enableServiceLoading ? (
          <Spinner message={message} />
        ) : (
          canManage && (
            <>
              {dialog}
              <Button
                variant="confirm"
                width={1}
                minHeight="3rem"
                onClick={showDialog}
              >
                Enable email forwarding
              </Button>
            </>
          )
        )}
      </Flex>
    </MessageCard>
  );
};
