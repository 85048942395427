import React from 'react';
import { render } from 'react-dom';
import { Flex } from 'rebass';
import { HashRouter } from 'react-router-dom';
import { Global, css } from '@emotion/core';
import { ThemeProvider } from 'emotion-theming';
import { Navbar } from './components/Navbar/index';

import { App } from './App';
import { theme } from './theme';
import * as serviceWorker from './serviceWorker';
import { PKCEProvider } from './components/PKCEProvider';

const globalStyle = css`
  body {
    margin: 0;
    color: #333;
    background: #f3f3f3;
    font-family: Poppins, Roboto, 'Helvetica Neue', Arial, sans-serif;
    padding-bottom: 6em;
  }
`;

render(
  <>
    <Global styles={globalStyle} />
    <ThemeProvider theme={theme}>
      <PKCEProvider>
        <HashRouter>
          <Navbar />
          <Flex justifyContent="center">
            <App />
          </Flex>
        </HashRouter>
      </PKCEProvider>
    </ThemeProvider>
  </>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
