import React, { FC, useCallback, useState } from 'react';
import { Heading, Text, Box, Link, Flex } from 'rebass';

import Spinner from '../../UI/Spinner';
import { LinkPill } from '../../UI/LinkPill';
import { StatusPill } from '../../UI/StatusPill';
import { FloatingCard } from '../../UI/FloatingCard';
import { useGet } from '../../../hooks/use-itx-api';
import { getJiraLicenseLabel } from '../../../utils/label-helpers';
import { useAsyncEffect } from '../../../hooks/use-async-effect';
import {
  Jira as JiraModel,
  JiraSystemInfo
} from '../../../models/other-services';

interface InfoProps {
  info: JiraModel;
}

const Info: FC<InfoProps> = props => {
  const [info, setInfo] = useState<JiraSystemInfo>();
  const [getJiraServiceInfo, loading] = useGet<JiraSystemInfo>(
    'jira/{id}/systeminfo'
  );

  const fetchJiraSystemInfo = useCallback(async () => {
    if (props.info.configured) {
      try {
        const res = await getJiraServiceInfo(undefined, props.info);
        setInfo(res);
      } catch (error) {
        if (error.name === 'AbortError') return;
        console.error(error);
      }
    }
  }, [getJiraServiceInfo, props.info]);
  useAsyncEffect(fetchJiraSystemInfo, [props.info]);

  return (
    <FloatingCard
      title="About Jira Site"
      tags={[
        <StatusPill key="status" status={props.info.status} />,
        props.info.jsdTicketUrl && (
          <LinkPill key="link" href={props.info.jsdTicketUrl} />
        )
      ]}
    >
      <Box width={1} mb={3}>
        <Text color="primary.grey" fontSize={1}>
          URL
        </Text>
        <Heading variant="heading">
          <Link href={props.info.url}>{props.info.url}</Link>
        </Heading>
      </Box>
      {loading ? (
        <Spinner message="Loading system info..." />
      ) : (
        info && (
          <>
            <Box width={1} height="1px" bg="borderGrey" my={3} />
            <Flex width={1} flexWrap="wrap">
              <Box width={1 / 2} mb={3}>
                <Text color="primary.grey" fontSize={1}>
                  Version
                </Text>
                <Heading variant="heading">
                  <Text>{info.version}</Text>
                </Heading>
              </Box>
              <Box width={1 / 2} mb={3}>
                <Text color="primary.grey" fontSize={1}>
                  Organization
                </Text>
                <Heading
                  variant="heading"
                  color={info.license.organization ? 'black' : 'intent.warning'}
                >
                  <Text>{(info && info.license.organization) || 'None'}</Text>
                </Heading>
              </Box>
              <Box width={1 / 2} mb={3}>
                <Text color="primary.grey" fontSize={1}>
                  License Type
                </Text>
                <Heading variant="heading">
                  <Text>{getJiraLicenseLabel(info.license.licenseType)}</Text>
                </Heading>
              </Box>
              <Box width={1 / 2} mb={3}>
                <Text color="primary.grey" fontSize={1}>
                  Expiry Date
                </Text>
                <Heading variant="heading">
                  <Text>{info.license.expiryDateString}</Text>
                </Heading>
              </Box>
              <Box width={1 / 2}>
                <Text color="primary.grey" fontSize={1}>
                  Maximum Number of Users
                </Text>
                <Heading variant="heading">
                  <Text>
                    {info.license.maximumNumberOfUsers < 0
                      ? 'Unlimited'
                      : info.license.maximumNumberOfUsers}
                  </Text>
                </Heading>
              </Box>
            </Flex>
          </>
        )
      )}
    </FloatingCard>
  );
};

export default Info;
