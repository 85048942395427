import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  FormEvent
} from 'react';
import { PlusCircle } from 'react-feather';
import { Input, Label } from '@rebass/forms';
import { toaster } from 'evergreen-ui';
import { Flex, Box, Button, Text } from 'rebass';
import { useFormState } from 'react-use-form-state';

import Spinner from '../UI/Spinner';
import { GroupsIoContext } from '.';
import { emailRegex } from '../../utils/const';
import { FloatingCard } from '../UI/FloatingCard';
import { GroupService } from '../../models/groups';
import { DeletableItem } from '../UI/DeletableItem';
import { usePut } from '../../hooks/use-itx-api';
import { useLoading } from '../../hooks/use-loading';

const tooltips = {
  serviceAdmins:
    "Service Administrators are owners of the main group and have administrative access to this project's entire Groups.io Service."
};

// tslint:disable-next-line:max-func-body-length
export const ServiceAdmins = (props: { serviceAdmins: string[] }) => {
  const { hasAccess, project, reloadPage } = useContext(GroupsIoContext);
  const [editing, setEditing] = useState(false);
  const [serviceAdmins, setServiceAdmins] = useState(props.serviceAdmins);
  const [{ message, isLoading }, { start, stop }] = useLoading();
  const [formState, { email, label }] = useFormState<{ newAdmin: string }>(
    { newAdmin: '' },
    { withIds: true }
  );
  const [updateGroupService] = usePut<GroupService, void>(
    `groupsio/${project.shortName}`
  );

  const updateServiceAndReloadPage = useCallback(async () => {
    start('Updating service administrators...');
    try {
      await updateGroupService({
        project: project.shortName,
        globalOwners: serviceAdmins
      });
      toaster.success('Service Administrators successfully updated');
      reloadPage();
    } catch (error) {
      if (error.name === 'AbortError') return;
      console.log(error);
    }

    stop();
  }, [
    project.shortName,
    reloadPage,
    serviceAdmins,
    start,
    stop,
    updateGroupService
  ]);

  useEffect(() => {
    setServiceAdmins(props.serviceAdmins);
  }, [props.serviceAdmins]);

  let buttons: JSX.Element;
  if (editing) {
    buttons = (
      <>
        <Button
          variant="confirm"
          mr={2}
          onClick={() => {
            if (serviceAdmins !== props.serviceAdmins) {
              updateServiceAndReloadPage();
            }
            setEditing(true);
          }}
        >
          Save
        </Button>
        <Button
          variant="warning"
          onClick={() => {
            setServiceAdmins(props.serviceAdmins);
            setEditing(false);
          }}
        >
          Cancel
        </Button>
      </>
    );
  } else {
    buttons = (
      <Button variant="outline" onClick={() => setEditing(true)}>
        Edit
      </Button>
    );
  }

  const addNewAdminToList = (e: FormEvent) => {
    e.preventDefault();
    setServiceAdmins([formState.values.newAdmin, ...serviceAdmins]);
    formState.reset();
  };

  return (
    <FloatingCard title="Service Administrators" tip={tooltips.serviceAdmins}>
      {isLoading ? (
        <Spinner message={message} />
      ) : (
        <>
          <Box my={1}>
            {serviceAdmins.map(o => (
              <DeletableItem
                key={o}
                deletable={editing}
                onDelete={adminToDelete => {
                  if (serviceAdmins.length > 1) {
                    setServiceAdmins(
                      serviceAdmins.filter(o => o !== adminToDelete)
                    );
                  } else {
                    toaster.danger(
                      'There must be at least one Service Administrator'
                    );
                  }
                }}
              >
                {o}
              </DeletableItem>
            ))}
          </Box>
          {hasAccess && editing && (
            <>
              <Box as="form" onSubmit={addNewAdminToList} mt={3}>
                <Label {...label('newAdmin')}>New Service Admin Email</Label>
                <Flex>
                  <Input
                    {...email('newAdmin')}
                    required
                    placeholder="example@example.com"
                  />
                  <Button
                    ml={2}
                    variant={
                      emailRegex.test(formState.values.newAdmin.toLowerCase())
                        ? 'confirm'
                        : 'disabled'
                    }
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <PlusCircle />
                  </Button>
                </Flex>
              </Box>
              <Text fontSize={1} my={3}>
                <b>Note:</b> Removing a user's global administrative privileges
                does not remove their membership or privileges on each
                individual list, which must be done separately.
              </Text>
            </>
          )}
          {hasAccess && (
            <Flex mt={3} justifyContent="center">
              {buttons}
            </Flex>
          )}
        </>
      )}
    </FloatingCard>
  );
};
