import React, { FC } from 'react';
import { Icon } from 'evergreen-ui';
import { Text, Heading, Flex, Box, Button } from 'rebass';

interface IssuesProps {
  issues?: string[];
  clearForm?: () => void;
}

const Issues: FC<IssuesProps> = props => {
  return (
    <Flex>
      <Box width={3 / 4}>
        {props.issues && (
          <>
            <Heading>Issues</Heading>
            {props.issues.map(issue => (
              <Flex key={issue} alignItems="center" mt={2} ml={2}>
                <Flex alignItems="center" height="100%" mr={3}>
                  <Icon color="warning" icon="warning-sign" />
                </Flex>
                <Text>{issue}</Text>
              </Flex>
            ))}
          </>
        )}
      </Box>
      <Box width={1 / 4}>
        <div id="next-button-root"></div>
        {props.clearForm && (
          <Button
            mt={2}
            width={1}
            variant="outline"
            onClick={() => props.clearForm && props.clearForm()}
          >
            Clear Form
          </Button>
        )}
      </Box>
    </Flex>
  );
};

export default Issues;
