import React from 'react';
import { Route } from 'react-router-dom';
import { Flex } from 'rebass';

import Search from './pages/Search';
import { Project } from './pages/Project';

export const App = () => (
  <Flex width={1} justifyContent="center">
    <Route path="/" exact component={Search} />
    <Route path="/project/:id" component={Project} />
  </Flex>
);
