import React from 'react';
import { Box, Flex } from 'rebass';
import { XCircle } from 'react-feather';

export const DeletableItem = (props: {
  deletable?: boolean;
  onDelete?: (recipient: string) => void;
  children: string;
}) => {
  return (
    <Box
      m="4px"
      px="10px"
      py="6px"
      fontSize="14px"
      color="white"
      bg="primary.blue"
      sx={{
        display: 'inline-block',
        borderRadius: 0
      }}
    >
      <Flex alignItems="center" justifyContent="center">
        {props.children}
        <Flex justifyContent="center" alignItems="center" ml={2}>
          {props.deletable && (
            <XCircle
              size={18}
              onClick={
                props.onDelete
                  ? () => props.onDelete && props.onDelete(props.children)
                  : undefined
              }
            />
          )}
        </Flex>
      </Flex>
    </Box>
  );
};
