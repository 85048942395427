import { space } from './space';
import { forms } from './forms';
import { colors } from './colors';
import { buttons } from './buttons';
import { text, fonts } from './text';
import { variants } from './variants';
import { breakpoints } from './breakpoints';

export const theme = {
  text,
  space,
  fonts,
  forms,
  colors,
  buttons,
  variants,
  breakpoints,
  radii: ['8px'],
  shadows: ['0px 2px 3px rgba(0, 0, 0, 0.102)']
};
