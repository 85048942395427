import { ReactNode } from 'react';

export type Status =
  | 'authenticating'
  | 'authorized'
  | 'unauthorized'
  | 'errored';

export enum Actions {
  RETRY = 'RETRY',
  ALLOW = 'ALLOW',
  DENY = 'DENY',
  ERR = 'ERR'
}

export type Action =
  | { type: Actions.RETRY }
  | { type: Actions.ALLOW }
  | { type: Actions.DENY; reason: 'logout' | 'unauthorized' }
  | { type: Actions.ERR; error: Error };

export interface State {
  status: Status;
  error?: Error;
  reason?: 'logout' | 'unauthorized';
}

export interface PKCEProviderProps {
  children: ReactNode;
}
