export const colors = {
  brand: '#003561',
  primary: {
    blue: '#0e6dd4',
    green: '#1b8845',
    grey: '#6e6e6e'
  },
  accent: {
    green: '#25b55b',
    red: '#e94927',
    blue: '#95dbe4'
  },
  intent: {
    warning: '#ffc100',
    danger: '#f24e2a'
  },
  paragraph: '#444444',
  black: '#333',
  white: '#FFF',
  grey: '#999',
  borderGrey: '#e4e6e6'
};
