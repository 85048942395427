import React, { FC, useCallback, useState } from 'react';
import { Heading, Text, Box, Link, Flex } from 'rebass';

import Spinner from '../../UI/Spinner';
import {
  Confluence as ConfluenceModel,
  ConfluenceSystemInfo
} from '../../../models/other-services';
import { LinkPill } from '../../UI/LinkPill';
import { StatusPill } from '../../UI/StatusPill';
import { FloatingCard } from '../../UI/FloatingCard';
import { useGet } from '../../../hooks/use-itx-api';
import { useAsyncEffect } from '../../../hooks/use-async-effect';

interface InfoProps {
  info: ConfluenceModel;
}

const Info: FC<InfoProps> = props => {
  const [info, setInfo] = useState<ConfluenceSystemInfo>();
  const [getConfluenceInfo, loading] = useGet<ConfluenceSystemInfo>(
    'confluence/{id}/systeminfo'
  );

  const fetchConfluenceInfo = useCallback(async () => {
    if (props.info.configured) {
      try {
        const res = await getConfluenceInfo(undefined, props.info);
        setInfo(res);
      } catch (error) {
        if (error.name === 'AbortError') return;
        console.error(error);
      }
    }
  }, [getConfluenceInfo, props.info]);

  useAsyncEffect(fetchConfluenceInfo, [props.info]);

  return (
    <FloatingCard
      title="About Confluence Site"
      tags={[
        <StatusPill key="status" status={props.info.status} />,
        props.info.jsdTicketUrl && (
          <LinkPill key="link" href={props.info.jsdTicketUrl} />
        )
      ]}
    >
      <Box width={1} mb={3}>
        <Text color="primary.grey" fontSize={1}>
          URL
        </Text>
        <Heading variant="heading">
          <Link href={props.info.url}>{props.info.url}</Link>
        </Heading>
      </Box>
      {loading ? (
        <Spinner message="Loading system info..." />
      ) : (
        info && (
          <>
            <Box width={1} height="1px" bg="borderGrey" my={3} />
            <Flex
              width={1}
              height={loading ? '3em' : undefined}
              justifyContent={loading ? 'center' : 'flex-start'}
            >
              <Box>
                <Text color="primary.grey" fontSize={1}>
                  Version
                </Text>
                <Heading variant="heading">
                  <Text>{info.version}</Text>
                </Heading>
              </Box>
            </Flex>
          </>
        )
      )}
    </FloatingCard>
  );
};

export default Info;
