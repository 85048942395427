import React, {
  FC,
  createContext,
  useCallback,
  useState,
  useContext
} from 'react';

import { List } from './List';
import { GithubContext } from '..';
import Spinner from '../../UI/Spinner';
import { Repo } from '../../../models/github/repo';
import { useGet } from '../../../hooks/use-itx-api';
import { Org, LinkStatus } from '../../../models/github/org';
import { useAsyncEffect } from '../../../hooks/use-async-effect';

interface RepoProps {
  org: Org;
}

export const RepoContext = createContext<{
  org?: Org;
  fetchRepos?: () => Promise<void>;
  hasAccess?: boolean;
}>({});

export const Repos: FC<RepoProps> = props => {
  const { hasAccess } = useContext(GithubContext);
  const [getRepos, loading] = useGet<Repo[]>(
    `github/${props.org.organization}/repos`,
    false
  );
  const [repos, setRepos] = useState<Repo[]>([]);

  const fetchRepos = useCallback(async () => {
    if (props.org.linkedStatus !== LinkStatus.NotCreated) {
      try {
        const res = await getRepos();
        res.sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });
        setRepos(res);
      } catch (error) {
        if (error.name === 'AbortError') return;
        console.error(error);
      }
    }
  }, [props.org, getRepos]);

  useAsyncEffect(fetchRepos, [props.org]);

  return (
    <RepoContext.Provider value={{ org: props.org, fetchRepos, hasAccess }}>
      {loading ? (
        <Spinner message="Fetching repositories..." />
      ) : (
        <List repos={repos} />
      )}
    </RepoContext.Provider>
  );
};
