import React, {
  FC,
  createContext,
  useEffect,
  useState,
  useReducer
} from 'react';
import { Spinner } from 'evergreen-ui';

import Issues from './Issues';
import RepoInfo from './RepoInfo';
import Services from './Services';
import { RepoDetails } from '..';
import { Project } from '../../../models/project';
import { FloatingCard } from '../../UI/FloatingCard';
import { useLoading } from '../../../hooks/use-loading';
import { Config, ServiceTypes, Service, Repository } from '../../../models/ci';

interface ConfigureProps {
  project: Project;
  config?: Config;
  configuredRepos: Repository[];
  clearForm: () => void;
  updateConfig: (details: RepoDetails) => Promise<void>;
  putServicesInfo: (
    artifactStore?: Service,
    distribution?: Service,
    updatedDetails?: Partial<RepoDetails>
  ) => Promise<void>;
  submitConfig: (id: number) => Promise<void>;
}

type State = {
  isValid: boolean;
  modifiedData: Map<string, string>;
};

type ValidationKey =
  | 'scm'
  | 'org'
  | 'repo'
  | 'language'
  | 'buildtool'
  | 'platform';

type Action =
  | { type: 'validate'; key?: ValidationKey }
  | { type: 'invalidate'; key: ValidationKey; value: string };

function validationReducer(state: State, action: Action): State {
  switch (action.type) {
    case 'validate':
      const modifiedData = state.modifiedData;
      if (action.key) modifiedData.delete(action.key);
      else modifiedData.clear();

      return {
        isValid: modifiedData.size === 0,
        modifiedData
      };
    case 'invalidate':
      return {
        isValid: false,
        modifiedData: state.modifiedData.set(action.key, action.value)
      };
    default:
      return state;
  }
}

export const ConfigureContext = createContext<{
  start: (message?: string) => void;
  stop: () => void;
  isValid: boolean;
  validate: (key?: ValidationKey) => void;
  invalidate: (key: ValidationKey, value: string) => void;
  modifiedData?: Map<string, string>;
}>({
  start: (message?: string) => {},
  stop: () => {},
  isValid: false,
  validate: key => {},
  invalidate: (key, value) => {},
  modifiedData: undefined
});

function configStatus(
  config?: Config
): {
  build: boolean;
} {
  if (!config) return { build: false };
  return {
    build: !!(config.platform && config.language && config.buildtool)
  };
}

const Configure: FC<ConfigureProps> = props => {
  const [showServices, setShowServices] = useState(false);
  const [{ isValid, modifiedData }, dispatch] = useReducer(validationReducer, {
    isValid: false,
    modifiedData: new Map<string, string>()
  });
  const [{ message, isLoading }, { start, stop }] = useLoading(false);

  useEffect(() => {
    const status = configStatus(props.config);
    if (status.build) setShowServices(true);
    if (!props.config?.status.issues) dispatch({ type: 'validate' });
  }, [props.config]);

  const hasIssues =
    (props.config?.status.issues && props.config.status.issues.length > 0) ||
    false;

  return (
    <FloatingCard
      title={
        props.config
          ? `Edit Config: ${props.config.repository.repo}`
          : 'Configure CI'
      }
      tags={
        isLoading
          ? [
              <span key="message">{message}</span>,
              <Spinner key="spinner" size={20} />
            ]
          : undefined
      }
    >
      <ConfigureContext.Provider
        value={{
          start,
          stop,
          isValid,
          validate: (key?: ValidationKey) =>
            dispatch({ type: 'validate', key }),
          invalidate: (key: ValidationKey, value: string) =>
            dispatch({ type: 'invalidate', key, value }),
          modifiedData
        }}
      >
        <RepoInfo
          project={props.project}
          configuredRepos={props.configuredRepos}
          editingConfig={props.config}
          updateConfig={props.updateConfig}
        />
        {showServices && props.config && (
          <Services
            hasIssues={hasIssues}
            project={props.project}
            language={props.config.language}
            artifactService={
              props.config.services?.filter(service =>
                service.type.includes(ServiceTypes.Artifacts)
              )[0]
            }
            distributionService={
              props.config.services?.filter(service =>
                service.type.includes(ServiceTypes.Distribution)
              )[0]
            }
            putServicesInfo={props.putServicesInfo}
            submitConfig={async () =>
              props.config && props.submitConfig(props.config.id)
            }
          />
        )}
      </ConfigureContext.Provider>
      <Issues
        issues={props.config?.status.issues}
        clearForm={props.config ? props.clearForm : undefined}
      />
    </FloatingCard>
  );
};

export default Configure;
