import React from 'react';
import { Flex, Box, Heading, Text, Button } from 'rebass';

import styled from '../../utils/styled';
import { client } from '../../utils/auth';

interface LoginProps {
  error?: any;
  unauthorized?: boolean;
}

const Container = styled(Flex)`
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #333;
`;

export function LoginPrompt(props: LoginProps) {
  if (props.error) {
    return (
      <Container p={3}>
        <Box mb={3}>
          <Heading variant="heading">An error occurred.</Heading>
        </Box>
        <Flex mb={4} flexDirection="column" alignItems="center">
          <Text>
            <code>
              {props.error.error || props.error.name}:{' '}
              {props.error.error_description ||
                props.error.message ||
                props.error.description}
            </code>
          </Text>
        </Flex>
        <Flex>
          <Button
            variant="confirm"
            onClick={() => {
              client.loginWithRedirect();
            }}
          >
            Log In
          </Button>
        </Flex>
      </Container>
    );
  }

  if (props.unauthorized) {
    return (
      <Container p={3}>
        <Box mb={3}>
          <Heading variant="heading">Unauthorized</Heading>
        </Box>
        <Flex mb={4} flexDirection="column" alignItems="center">
          <Text>
            You may use the button below to sign in with a different account.
          </Text>
        </Flex>
        <Flex>
          <Button
            variant="confirm"
            onClick={() => {
              client.logout({ localOnly: true });
              client.loginWithRedirect();
            }}
          >
            Log In
          </Button>
        </Flex>
      </Container>
    );
  }

  return (
    <Container p={3}>
      <Box mb={3}>
        <Heading variant="heading">You have been logged out.</Heading>
      </Box>
      <Flex mb={4} flexDirection="column" alignItems="center">
        <Text>Close this tab, or click the button below to log back in.</Text>
      </Flex>
      <Flex alignItems="center">
        <Button
          variant="confirm"
          onClick={() => {
            client.loginWithRedirect();
          }}
        >
          Log In
        </Button>
      </Flex>
    </Container>
  );
}
