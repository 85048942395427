import { ProjectShortName } from '../project';

/**
 * We'll be serializing/deserializing object properties on request and on
 * response so we can maintain the camelcase convention within our source
 * code, but use snakecase in our requests.
 */

export enum Visibility {
  Custom = 'custom',
  Private = 'private',
  Public = 'public'
}

export enum Type {
  Announcement = 'announcement',
  Custom = 'custom',
  DiscussionModerated = 'discussion_moderated',
  DiscussionOpen = 'discussion_open'
}

// tslint:disable is for `type` attribute
// tslint:disable:no-reserved-keywords
export interface Group {
  groupName: string;
  visibility: Visibility;
  type: Type;
  flags: string[];
  description: string;
  url: string;
}

export enum Status {
  Created = 'created',
  Enterprise = 'enterprise'
}

export interface GroupService {
  project: ProjectShortName;
  status: Status;
  globalOwners: string[];
}
