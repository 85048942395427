import { theme } from '../../theme';
import { LinkStatus, Org } from '../../models/github/org';
import { ListItemValue } from '../UI/List';

function getLinkStatusColor(linkedStatus: string) {
  switch (linkedStatus) {
    case LinkStatus.Full:
      return theme.colors.primary.green;
    case LinkStatus.Partial:
      return theme.colors.intent.warning;
    case LinkStatus.None:
      return theme.colors.intent.danger;
    case LinkStatus.NotCreated:
    default:
      return theme.colors.borderGrey;
  }
}

export function constructItemsFromOrgs(orgs: Org[]): ListItemValue[] {
  return orgs.map(({ organization, linkedStatus }) => {
    return {
      label: organization,
      tags: [
        {
          name: linkedStatus.replace('_', ' '),
          color: getLinkStatusColor(linkedStatus)
        }
      ]
    };
  });
}
