import React, { FC } from 'react';
import { motion } from 'framer-motion';
import { Box, Heading, Text } from 'rebass';

export const MessageCard: FC<{
  title: string;
  warning?: boolean;
}> = ({ title, warning, children }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
    >
      <Box
        p={4}
        maxWidth="650px"
        bg={warning ? 'intent.warning' : 'white'}
        sx={{ boxShadow: 0 }}
      >
        <Heading mb={3}>{title}</Heading>
        <Text mb={2}>{children}</Text>
      </Box>
    </motion.div>
  );
};
