import React, { Component, ReactNode } from 'react';
import { toaster } from 'evergreen-ui';
import { Box } from 'rebass';

import AWS from './AWS';
import List from './List';
import Jira from './Jira';
import Confluence from './Confluence';
import {
  Services,
  Confluence as ConfluenceModel,
  Jira as JiraModel,
  Account,
  SlackIn as SlackInModel
} from '../../models/other-services';
import styled from '../../utils/styled';
import apiClient from '../../utils/api-client';
import { Project } from '../../models/project';
// import SlackIn from './SlackIn';

const OtherServicesLayout = styled.div`
  width: 100%;
  display: grid;
  gap: 32px;
  grid-template-areas: 'list service';
  grid-template-columns: 1fr 2fr;
`;

interface OtherServicesProps {
  project: Project;
}

interface OtherServicesState {
  selectedService?: string;
  availableServices: {
    name: Services;
    configured: boolean;
  }[];
  loadingServices: boolean;
  jiraSites: JiraModel[];
  confluenceSites: ConfluenceModel[];
  awsAccounts: Account[];
  slackInSites: SlackInModel[];
  hasManagedAccount: boolean;
}

export default class OtherServices extends Component<
  OtherServicesProps,
  OtherServicesState
> {
  state = {
    selectedService: '',
    availableServices: new Array<{ name: Services; configured: boolean }>(),
    loadingServices: false,
    jiraSites: [] as JiraModel[],
    confluenceSites: [] as ConfluenceModel[],
    awsAccounts: new Array<Account>(),
    slackInSites: [] as SlackInModel[],
    hasManagedAccount: false
  };
  abortController = new AbortController();

  getAvailableServices = async () => {
    this.setState({ loadingServices: true });
    return Promise.all([
      apiClient
        .get('jira', {
          signal: this.abortController.signal,
          searchParams: {
            project: this.props.project.shortName
          }
        })
        .json() as Promise<JiraModel[]>,
      apiClient
        .get('confluence', {
          signal: this.abortController.signal,
          searchParams: {
            project: this.props.project.shortName
          }
        })
        .json() as Promise<ConfluenceModel[]>,
      apiClient
        .get('awsaccount', {
          signal: this.abortController.signal,
          searchParams: {
            project: this.props.project.shortName
          }
        })
        .json() as Promise<Account[]>
      // apiClient
      //   .get('slackin', {
      //     signal: this.abortController.signal,
      //     searchParams: {
      //       project: this.props.project.shortName
      //     }
      //   })
      //   .json() as Promise<SlackInModel[]>
    ])
      .catch(error => {
        if (error.name === 'AbortError') throw error;
      })
      .then(res => {
        if (res) {
          const [
            jiraSites,
            confluenceSites,
            awsAccounts /*, slackInSites */
          ] = res;

          this.setState({
            availableServices: [
              {
                name: Services.Jira,
                configured: jiraSites.length > 0
              },
              {
                name: Services.Confluence,
                configured: confluenceSites.length > 0
              },
              {
                name: Services.AWS,
                configured: awsAccounts.length > 0
              }
              // {
              //   name: Services.SlackIn,
              //   configured: slackInSites.length > 0
              // }
            ],
            jiraSites,
            confluenceSites,
            awsAccounts,
            // slackInSites,
            hasManagedAccount: awsAccounts.reduce(
              (found, account) => found || account.accountType === 'managed',
              false as boolean
            ),
            loadingServices: false
          });
        }
      })
      .catch(error => {
        if (error.name === 'AbortError') return;
        this.setState({ loadingServices: false });
        toaster.danger(`Error getting other services: ${error.description}`);
      });
  };

  selectService = (service: string) => {
    this.setState({ selectedService: service as Services });
  };

  trackJira = async (url: string) => {
    try {
      await apiClient.post('jira', {
        signal: this.abortController.signal,
        json: {
          project: this.props.project.shortName,
          url
        }
      });
      toaster.success('Jira site tracked successfully');
      await this.getAvailableServices();
    } catch (error) {
      if (error.name === 'AbortError') return;
      toaster.danger(
        `An error occurred while attempting to track the specified Jira site`
      );
    }
  };

  createJira = async () => {
    try {
      await apiClient.post('jira', {
        signal: this.abortController.signal,
        json: {
          project: this.props.project.shortName
        }
      });
      toaster.success('Jira site created successfully');
      await this.getAvailableServices();
    } catch (error) {
      if (error.name === 'AbortError') return;
      toaster.danger(`Error creating Jira: ${error.description}`);
    }
  };

  trackConfluence = async (url: string) => {
    try {
      await apiClient.post('confluence', {
        signal: this.abortController.signal,
        json: {
          project: this.props.project.shortName,
          url
        }
      });
      toaster.success('Confluence site tracked successfully');
      await this.getAvailableServices();
    } catch (error) {
      if (error.name === 'AbortError') return;
      toaster.danger(
        `An error occurred while attempting to track the specified Confluence site`
      );
    }
  };

  createConfluence = async () => {
    try {
      await apiClient.post('confluence', {
        signal: this.abortController.signal,
        json: {
          project: this.props.project.shortName
        }
      });
      toaster.success('Confluence site created successfully');
      await this.getAvailableServices();
    } catch (error) {
      if (error.name === 'AbortError') return;
      toaster.danger(`Error creating Confluence: ${error.description}`);
    }
  };

  inviteAccount = async (accountId: string, description: string) => {
    try {
      await apiClient.post('awsaccount/requestinvite', {
        signal: this.abortController.signal,
        json: {
          project: this.props.project.shortName,
          accountId,
          description
        }
      });
      toaster.success('AWS account invited successfully');
      await this.getAvailableServices();
    } catch (error) {
      if (error.name === 'AbortError') return;
      toaster.danger(`Error inviting account: ${error.description}`);
    }
  };

  createAccount = async (
    description: string,
    email?: string,
    accountType: string = 'managed'
  ) => {
    try {
      await apiClient.post('awsaccount', {
        signal: this.abortController.signal,
        json: {
          project: this.props.project.shortName,
          accountType,
          email,
          description
        }
      });
      toaster.success('AWS account created successfully');
      await this.getAvailableServices();
    } catch (error) {
      if (error.name === 'AbortError') return;
      toaster.danger(`Error creating account: ${error.message}`);
    }
  };

  updateDescription = async (description: string, accountId: string) => {
    try {
      await apiClient.patch(`awsaccount/${accountId}`, {
        signal: this.abortController.signal,
        json: {
          description
        }
      });
      toaster.success('Account description updated successfully');
      await this.getAvailableServices();
    } catch (error) {
      if (error.name === 'AbortError') return;
      toaster.danger(`Error updating description: ${error.description}`);
    }
  };

  createSlackIn = async (team: string, apiKey: string) => {
    try {
      await apiClient.post(`slackin`, {
        signal: this.abortController.signal,
        json: {
          project: this.props.project.shortName,
          team,
          apiKey
        }
      });
      toaster.success(`SlackIn site created successfully`);
      await this.getAvailableServices();
    } catch (error) {
      if (error.name === `AbortError`) return;
      toaster.danger(`Error creating SlackIn: ${error.message}`);
    }
  };

  async componentDidMount() {
    try {
      await this.getAvailableServices();
    } catch (error) {
      if (error.name === 'AbortError') return;
      console.error(error);
    }
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  render() {
    let selectedService: ReactNode;
    if (this.state.selectedService) {
      switch (this.state.selectedService) {
        case Services.Jira:
          selectedService = (
            <Jira
              sites={this.state.jiraSites}
              trackJira={this.trackJira}
              createJira={this.createJira}
              hasManagedAccount={this.state.hasManagedAccount}
              primaryDomain={this.props.project.canonicalDomain}
            />
          );
          break;
        case Services.Confluence:
          selectedService = (
            <Confluence
              sites={this.state.confluenceSites}
              trackConfluence={this.trackConfluence}
              createConfluence={this.createConfluence}
              hasManagedAccount={this.state.hasManagedAccount}
              primaryDomain={this.props.project.canonicalDomain}
            />
          );
          break;
        case Services.AWS:
          selectedService = (
            <AWS
              accounts={this.state.awsAccounts}
              inviteAccount={this.inviteAccount}
              createAccount={this.createAccount}
              updateDescription={this.updateDescription}
              project={this.props.project}
            />
          );
          break;
        // case Services.SlackIn:
        //   selectedService = (
        //     <SlackIn
        //       slackIns={this.state.slackInSites}
        //       createSlackIn={this.createSlackIn}
        //       hasManagedAccount={this.state.hasManagedAccount}
        //       hasPrimaryDomain={!!this.props.project.canonicalDomain}
        //     />
        //   );
      }
    }

    return (
      <OtherServicesLayout>
        <Box sx={{ gridArea: 'list' }}>
          <List
            loading={this.state.loadingServices}
            onSelect={this.selectService}
            services={this.state.availableServices}
          />
        </Box>
        <Box sx={{ gridArea: 'service' }}>{selectedService}</Box>
      </OtherServicesLayout>
    );
  }
}
