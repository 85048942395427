import { State, Action, Actions } from './types';
import { AUTH_STATE_GRAPH } from './state-graph';

export function reducer(current: State, next: Action): State {
  const nextStatus = AUTH_STATE_GRAPH[current.status][next.type];
  if (nextStatus) {
    switch (next.type) {
      case Actions.ERR:
        return {
          status: nextStatus,
          error: next.error
        };
      case Actions.DENY:
        return {
          status: nextStatus,
          reason: next.reason
        };
      default:
        return { status: nextStatus };
    }
  }

  return current;
}
