import React, {
  FC,
  useEffect,
  useState,
  ChangeEvent,
  useLayoutEffect,
  useContext
} from 'react';
import { Flex, Box } from 'rebass';
import { toaster } from 'evergreen-ui';
import { Label, Select } from '@rebass/forms';
import { useFormState } from 'react-use-form-state';

import NextButton from './NextButton';
import { ConfigureContext } from '.';
import { TooltipText } from '../../UI/TooltipText';
import { Config, Repository } from '../../../models/ci';
import { RepoDetails } from '..';
import { Org, LinkStatus } from '../../../models/github/org';
import { Repo } from '../../../models/github/repo';
import apiClient from '../../../utils/api-client';
import { Project } from '../../../models/project';
import { formatName, compareNames } from '../../../utils/string-handlers';

type PlatformRes = {
  platform: string;
  languages: string[];
};

type LanguageRes = {
  language: string;
  buildtools: string[];
};

interface RepoInfoProps {
  project: Project;
  configuredRepos: Repository[];
  editingConfig?: Config;
  updateConfig: (config: RepoDetails) => Promise<void>;
}

const tooltips = {
  platform: 'The CI/CD platform where builds will run.',
  languageTemplate: 'Which language the repository code is written in.',
  buildtool:
    'The software being used to build, compile, and/or package the code.'
};

const abortController = new AbortController();

async function getPlatforms() {
  const platforms: PlatformRes[] = await apiClient
    .get('ci/platforms', { signal: abortController.signal })
    .json();
  return platforms;
}

async function getLanguages() {
  const languages: LanguageRes[] = await apiClient
    .get('ci/languages', { signal: abortController.signal })
    .json();
  return languages;
}

async function getBuildtools(language: string) {
  const languages: LanguageRes[] = await apiClient
    .get('ci/languages', { signal: abortController.signal })
    .json();
  return languages.find(lang => lang.language === language)?.buildtools ?? [];
}

async function getLinkedOrgs(projectName: string): Promise<Org[]> {
  const orgs: Org[] = await apiClient
    .get('github', {
      signal: abortController.signal,
      searchParams: { project: projectName }
    })
    .json();

  return orgs.filter(org => org.linkedStatus === LinkStatus.Full);
}

async function getRepos(orgName: string): Promise<Repo[]> {
  const repos: Repo[] = await apiClient
    .get(`github/${orgName}/repos`, { signal: abortController.signal })
    .json();
  repos.sort((a, b) => compareNames(a.name, b.name));
  return repos;
}

const RepoInfo: FC<RepoInfoProps> = props => {
  const [orgs, setOrgs] = useState<Org[]>([]);
  const [repos, setRepos] = useState<Repo[]>([]);
  const [platforms, setPlatforms] = useState<PlatformRes[]>([]);
  const [languages, setLanguages] = useState<string[]>([]);
  const [buildtools, setBuildtools] = useState<string[]>([]);
  const [showButton, setShowButton] = useState(true);
  const [{ values, setField }, { select }] = useFormState({
    provider: 'github',
    org: props.editingConfig?.repository.org || '',
    repo: props.editingConfig?.repository.repo || '',
    platform: props.editingConfig?.platform || '',
    language: props.editingConfig?.language || '',
    buildtool: props.editingConfig?.buildtool || ''
  });
  const { start, stop, validate, invalidate } = useContext(ConfigureContext);

  useLayoutEffect(() => {
    if (props.editingConfig) {
      setShowButton(false);
    }
  }, [props.editingConfig]);

  useEffect(() => {
    start('Populating form options...');
    Promise.all([
      getPlatforms(),
      getLinkedOrgs(props.project.shortName),
      ...((props.editingConfig
        ? [
            getRepos(props.editingConfig.repository.org),
            getLanguages(),
            getBuildtools(props.editingConfig.language)
          ]
        : []) as unknown[])
    ])
      .then(results => {
        for (let i = 0; i < results.length; i++) {
          switch (i) {
            case 0:
              setPlatforms(results[i] as PlatformRes[]);
              break;
            case 1:
              setOrgs(results[i] as Org[]);
              break;
            case 2:
              const res = results[i] as Repo[];
              setRepos(res);
              break;
            case 3:
              const langs = (results[0] as PlatformRes[]).find(
                platform => platform.platform === props.editingConfig?.platform
              )?.languages;
              if (langs) setLanguages(langs);
              else {
                setLanguages(
                  (results[i] as LanguageRes[]).map(lang => lang.language)
                );
              }
              break;
            case 4:
              setBuildtools(results[i] as string[]);
              break;
            default:
              return;
          }
        }
        stop();
      })
      .catch(error => {
        if (error.name === 'AbortError') return;
        stop();
        toaster.danger(`Error configuration options: ${error.description}`);
      });
    return () => abortController.abort();
  }, [props.editingConfig, props.project.shortName, start, stop]);

  async function onProviderChange(e: ChangeEvent<HTMLSelectElement>) {
    if (orgs.length > 0) setOrgs([]);
    start('Fetching organizations...');
    try {
      const res = await getLinkedOrgs(props.project.shortName);
      setOrgs(res.filter(org => org.linkedStatus === 'full'));
      stop();
    } catch (error) {
      if (error.name === 'AbortError') return;
      toaster.danger(`Error getting orgs: ${error.description}`);
      stop();
    }
  }

  const onOrgChange = async (e: ChangeEvent<HTMLSelectElement>) => {
    if (repos.length > 0) setRepos([]);
    setField('repo', '');
    start('Fetching repositories...');
    const { value: orgName } = e.target;
    if (orgName === props.editingConfig?.repository.org) validate('org');
    else invalidate('org', orgName);
    try {
      const res = await getRepos(orgName);
      setRepos(res);
      stop();
    } catch (error) {
      if (error.name === 'AbortError') return;
      stop();
      toaster.danger(`Error getting repos: ${error.description}`);
    }
  };

  function onRepoChange(e: ChangeEvent<HTMLSelectElement>) {
    const { value: repo } = e.currentTarget;
    if (repo === props.editingConfig?.repository.repo) validate('repo');
    else invalidate('repo', repo);
  }

  async function onPlatformChange(e: ChangeEvent<HTMLSelectElement>) {
    const { value } = e.currentTarget;
    if (value === props.editingConfig?.platform) validate('platform');
    else invalidate('platform', value);
    const langs = platforms.find(platform => platform.platform === value)
      ?.languages;
    if (langs) setLanguages(langs);
    else {
      try {
        start('Fetching language options...');
        const res: LanguageRes[] = await getLanguages();
        setLanguages(res.map(language => language.language));
        stop();
      } catch (error) {
        if (error.name === 'AbortError') return;
        stop();
        toaster.danger(`Error retrieving languages: ${error.description}`);
      }
    }
  }

  async function onLanguageChange(e: ChangeEvent<HTMLSelectElement>) {
    setField('buildtool', '');
    const { value } = e.currentTarget;
    if (value === props.editingConfig?.language) validate('language');
    else invalidate('language', value);
    try {
      start('Fetching build tool options...');
      const res = await getBuildtools(value);
      setBuildtools(res);
      stop();
    } catch (error) {
      if (error.name === 'AbortError') return;
      toaster.danger(`Error retrieving build tools: ${error.description}`);
      stop();
    }
  }

  function onBuildtoolChange(e: ChangeEvent<HTMLSelectElement>) {
    const { value } = e.currentTarget;
    if (value === props.editingConfig?.buildtool) validate('buildtool');
    else invalidate('buildtool', value);
  }

  async function onSubmit() {
    start('Submitting information...');
    try {
      await props.updateConfig(values as RepoDetails);
      stop();
    } catch (error) {
      if (error.name === 'AbortError') return;
      stop();
      toaster.danger(`Error saving config: ${error.description}`);
    }
  }

  const disableButton = !Object.values(values).every(val => !!val);

  const orgConfiguredRepos: string[] = props.configuredRepos.reduce(
    (accumulator, currentRepo) => {
      if (currentRepo.org === values.org) {
        return [...accumulator, currentRepo.repo];
      }
      return accumulator;
    },
    [] as string[]
  );
  const displayRepos = repos.filter(
    repo =>
      !orgConfiguredRepos.includes(repo.name) ||
      repo.name === props.editingConfig?.repository.repo
  );

  return (
    <Box>
      <Flex mb={3}>
        <Box mr={2} width={1 / 3}>
          <Label>SCM</Label>
          <Select
            {...select({
              name: 'provider',
              onChange: onProviderChange
            })}
            required
          >
            <option value="" disabled hidden>
              Select a provider
            </option>
            <option value="github">GitHub</option>
          </Select>
        </Box>
        <Box mx={2} width={1 / 3}>
          <Label>Organization</Label>
          <Select
            {...select({
              name: 'org',
              onChange: onOrgChange
            })}
            required
            disabled={orgs.length === 0}
          >
            <option value="" disabled hidden>
              Select an organization
            </option>
            {orgs.map(org => (
              <option key={org.organization}>{org.organization}</option>
            ))}
          </Select>
        </Box>
        <Box ml={1 / 3} width={1 / 3}>
          <Label>Repository</Label>
          <Select
            {...select({ name: 'repo', onChange: onRepoChange })}
            required
            disabled={repos.length === 0}
          >
            <option value="" disabled hidden>
              Select a repository
            </option>
            {displayRepos.map(repo => (
              <option key={repo.name}>{repo.name}</option>
            ))}
          </Select>
        </Box>
      </Flex>
      <Flex mb={3}>
        <Box mr={2} width={1 / 3}>
          <Label>
            <TooltipText tip={tooltips.platform}>Platform</TooltipText>
          </Label>
          <Select
            {...select({
              name: 'platform',
              onChange: onPlatformChange
            })}
            disabled={!values.repo}
          >
            <option value="" hidden disabled>
              Select a platform
            </option>
            {platforms.map(platform => (
              <option key={platform.platform} value={platform.platform}>
                {formatName(platform.platform)}
              </option>
            ))}
          </Select>
        </Box>
        <Box mx={2} width={1 / 3}>
          <Label>
            <TooltipText tip={tooltips.languageTemplate}>
              Language Template
            </TooltipText>
          </Label>
          <Select
            {...select({ name: 'language', onChange: onLanguageChange })}
            disabled={languages.length === 0}
          >
            <option value="" hidden disabled>
              Select a language
            </option>
            {languages.map(language => (
              <option key={language} value={language}>
                {formatName(language)}
              </option>
            ))}
          </Select>
        </Box>
        <Box mr={2} width={1 / 3}>
          <Label>
            <TooltipText tip={tooltips.buildtool}>Build Tool</TooltipText>
          </Label>
          <Select
            {...select({ name: 'buildtool', onChange: onBuildtoolChange })}
            disabled={buildtools.length === 0}
          >
            <option value="" hidden disabled>
              Select a build tool
            </option>
            {buildtools.map(buildtool => (
              <option key={buildtool} value={buildtool}>
                {formatName(buildtool)}
              </option>
            ))}
          </Select>
        </Box>
      </Flex>
      {showButton && (
        <NextButton
          variant={disableButton ? 'disabled' : 'confirm'}
          onClick={disableButton ? undefined : onSubmit}
        >
          Create and Validate
        </NextButton>
      )}
    </Box>
  );
};

export default RepoInfo;
