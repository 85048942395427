import React, { Component } from 'react';
import { Box } from 'rebass';
import { toaster } from 'evergreen-ui';

import List from './List';
import Repos from './Repos';
import Configure from './Configure';
import Spinner from '../UI/Spinner';
import styled from '../../utils/styled';
import apiClient from '../../utils/api-client';
import { Project } from '../../models/project';
import { Org, LinkStatus } from '../../models/github/org';
import { MessageCard } from '../UI/MessageCard';
import { Config, Service } from '../../models/ci';

interface CIProps {
  project: Project;
}

interface CIState {
  canManage: boolean;
  configs: Config[];
  orgs?: Org[];
  editingConfig?: Config;
  filteredConfigs: Config[];
}

export interface RepoDetails {
  provider: string;
  org: string;
  repo: string;
  platform: string;
  language: string;
  buildtool: string;
}

const CILayout = styled.div<{ canManage?: boolean }>`
  width: 100%;
  display: grid;
  gap: 32px;
  grid-template-areas: ${props =>
      props.canManage
        ? "'configure configure configure'"
        : ''} 'list divider repos';
  grid-template-columns: 1fr auto 3fr;
`;

class CI extends Component<CIProps, CIState> {
  abortController = new AbortController();

  state = {
    canManage: true,
    orgs: undefined as Org[] | undefined,
    configs: [] as Config[],
    editingConfig: undefined as Config | undefined,
    filteredConfigs: [] as Config[]
  };

  fetchConfigs = async (id?: number) => {
    try {
      const configs: Config[] = await apiClient
        .get('ci', {
          signal: this.abortController.signal,
          searchParams: {
            project: this.props.project.shortName
          }
        })
        .json();

      const editingConfig = id
        ? configs.find(config => config.id === id)
        : undefined;
      this.setState({ configs, filteredConfigs: configs, editingConfig });
    } catch (error) {
      if (error.name === 'AbortError') return;
    }
  };

  updateConfig = async ({
    provider,
    org,
    repo,
    platform,
    language,
    buildtool
  }: RepoDetails) => {
    const config: Partial<Config> = {
      project: this.props.project.shortName,
      ...(this.state.editingConfig || {}),
      repository: {
        provider,
        org,
        repo
      },
      language,
      buildtool,
      platform
    };

    if (config.id) {
      const { id }: Config = await apiClient
        .put(`ci/${config.id}`, {
          signal: this.abortController.signal,
          json: config
        })
        .json();

      await this.fetchConfigs(id);
    } else {
      const { id }: Config = await apiClient
        .post(`ci`, {
          signal: this.abortController.signal,
          json: config
        })
        .json();

      await this.fetchConfigs(id);
    }

    toaster.success('Config created successfully');
  };

  putServicesInfo = async (
    artifactStore?: Service,
    distribution?: Service,
    updatedDetails?: Partial<RepoDetails>
  ) => {
    if (this.state.editingConfig) {
      const services = [];
      if (artifactStore) services.push(artifactStore);
      if (distribution) services.push(distribution);
      const config = {
        ...this.state.editingConfig,
        ...(updatedDetails?.provider ||
        updatedDetails?.org ||
        updatedDetails?.repo
          ? {
              repository: {
                provider:
                  updatedDetails.provider ||
                  this.state.editingConfig.repository.provider,
                org:
                  updatedDetails.org || this.state.editingConfig.repository.org,
                repo:
                  updatedDetails.repo ||
                  this.state.editingConfig.repository.repo
              }
            }
          : {}),
        platform: updatedDetails?.platform || this.state.editingConfig.platform,
        language: updatedDetails?.language || this.state.editingConfig.language,
        buildtool:
          updatedDetails?.buildtool || this.state.editingConfig.buildtool,
        services
      };

      await apiClient.put(`ci/${config.id}`, {
        signal: this.abortController.signal,
        json: config
      });
      toaster.success(
        updatedDetails && Object.keys(updatedDetails).length > 0
          ? 'Configuration updated successfully'
          : 'Artifact store and distribution service info added successfully'
      );
      await this.fetchConfigs(config.id);
    }
  };

  submitConfig = async (id: number) => {
    await apiClient.put(`ci/${id}/submit`, {
      signal: this.abortController.signal
    });
    toaster.success('CI/CD configuration submitted successfully');
    await this.fetchConfigs();
  };

  filterConfigs = async (provider?: string, org?: string) => {
    if (provider && org) {
      this.setState({
        filteredConfigs: this.state.configs.filter(
          config =>
            config.repository.provider === provider &&
            config.repository.org === org
        )
      });
    } else {
      await this.fetchConfigs(this.state.editingConfig?.id);
    }
  };

  editConfig = (id: number) => {
    this.setState(state => {
      const selectedConfig = state.configs.find(config => config.id === id);
      if (selectedConfig) {
        return {
          ...state,
          editingConfig: selectedConfig
        };
      }

      return state;
    });
  };

  clearForm = () => {
    this.setState({ editingConfig: undefined });
  };

  deleteConfig = async (id: number) => {
    await apiClient.delete(`ci/${id}`, {
      signal: this.abortController.signal
    });
    toaster.success('CI/CD configuration deleted successfully');
    await this.fetchConfigs(
      id === this.state.editingConfig?.id
        ? undefined
        : this.state.editingConfig?.id
    );
  };

  async componentDidMount() {
    this.fetchConfigs();

    try {
      const orgs: Org[] = await apiClient
        .get(`github`, {
          signal: this.abortController.signal,
          searchParams: {
            project: this.props.project.shortName
          }
        })
        .json();

      this.setState({
        orgs
      });
    } catch (error) {
      if (this.abortController.signal.aborted) return;
      console.error(error);
    }
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  render() {
    const {
      orgs,
      canManage,
      configs,
      filteredConfigs,
      editingConfig
    } = this.state;

    const hasFullyLinkedOrgs = orgs?.find(
      org => org.linkedStatus === LinkStatus.Full
    );

    let configure = (
      <MessageCard title="Not Available" warning>
        This service is unavailable because there are no organizations
        associated with this project. Associate an organization in the GitHub
        tab, or open a ticket requesting one to be associated with this project.
      </MessageCard>
    );
    if (orgs && orgs.length > 0) {
      if (hasFullyLinkedOrgs) {
        configure = (
          <Configure
            key={
              editingConfig
                ? Object.values(editingConfig)
                    .concat(Object.values(editingConfig.repository))
                    .concat(editingConfig.services?.map(service => service.ref))
                    .join('-')
                : undefined
            }
            configuredRepos={configs.map(config => config.repository)}
            config={editingConfig}
            project={this.props.project}
            clearForm={this.clearForm}
            updateConfig={this.updateConfig}
            putServicesInfo={this.putServicesInfo}
            submitConfig={this.submitConfig}
          />
        );
      } else {
        configure = (
          <MessageCard title="Not Available" warning>
            This service is unavailable because there are no fully-linked
            organizations associated with this project. Please select an
            organization in the GitHub tab and follow the instructions specified
            to promote an organization to become fully-linked.
          </MessageCard>
        );
      }
    }

    return (
      <CILayout canManage={canManage}>
        <Box sx={{ gridArea: 'configure' }}>
          {orgs ? configure : <Spinner message="Checking status..." />}
        </Box>
        {configs && configs.length > 0 && (
          <>
            <Box sx={{ gridArea: 'list' }}>
              <List configs={configs} filterConfigs={this.filterConfigs} />
            </Box>
            <Box sx={{ gridArea: 'divider' }}>
              <Box bg="borderGrey" width="2px" height="100%" />
            </Box>
            <Box sx={{ gridArea: 'repos' }}>
              <Repos
                configs={filteredConfigs}
                editConfig={this.editConfig}
                deleteConfig={this.deleteConfig}
              />
            </Box>
          </>
        )}
      </CILayout>
    );
  }
}

export default CI;
