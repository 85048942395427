import React, {
  useContext,
  useEffect,
  useState,
  useMemo,
  FC,
  FormEvent
} from 'react';
import nanoid from 'nanoid';
import { Select } from '@rebass/forms';
import { Flex, Heading, Text, Link } from 'rebass';
import { useFormState } from 'react-use-form-state';
import { IconButton, toaster, Tooltip } from 'evergreen-ui';

import styled from '../../utils/styled';
import { Group, Type, Visibility } from '../../models/groups';
import { GroupsIoContext } from './index';
import { usePut, useDelete } from '../../hooks/use-itx-api';
import { useDialog } from '../../hooks/use-dialog';
import { TooltipText } from '../UI/TooltipText';
import { FloatingCard } from '../UI/FloatingCard';
import { TooltipIcon } from '../UI/TooltipIcon';
import ReactTooltip from 'react-tooltip';

const tooltips = {
  list: {
    name: 'List of group names',
    visibility: (
      <>
        <p>
          The visibility settings for the list. Private lists cannot be changed
          back to public lists.
        </p>
        <ul>
          <li>Public: Anyone can discover, join, or view list emails.</li>
          <li>
            Private: The list and its emails are only visible to members, and
            new members must be added or approved by the moderators.
          </li>
          <li>
            Custom: These settings have been modified outside of ITX and don't
            match a default configuration. Modifications from ITX will override
            and reset custom configuration.
          </li>
        </ul>
      </>
    ),
    type: (
      <>
        <p>The list type:</p>
        <ul>
          <li>
            Moderated Discussion: New members cannot post to the list without
            their emails being approved by a moderator.
          </li>
          <li>
            Open Discussion: New members can immediately post to the list.
          </li>
          <li>Announcement: Only moderators can post to the list.</li>
          <li>
            Custom: These settings have been modified outside of ITX and don't
            match a default configuration. Modifications from ITX will override
            and reset custom configuration.
          </li>
        </ul>
      </>
    ),
    description: "A brief descriptive explanation of the list's purpose."
  },
  actions: {
    edit: 'Edit Group',
    delete: 'Delete Group',
    save: 'Save changes',
    cancel: 'Abort changes',
    link: 'Open on Groups.io'
  }
};

const humanVisibility = (v: Visibility) => {
  switch (v) {
    case Visibility.Private:
      return 'Private';
    case Visibility.Public:
      return 'Public';
    default:
      return 'Custom';
  }
};

const humanType = (t: Type) => {
  switch (t) {
    case Type.Announcement:
      return 'Announcement';
    case Type.DiscussionModerated:
      return 'Moderated Discussion';
    case Type.DiscussionOpen:
      return 'Open Discussion';
    default:
      return 'Custom';
  }
};

// tslint:disable-next-line:max-func-body-length
const ListItem = (props: { group: Group }) => {
  const nameId = useMemo(() => nanoid(), []);
  const descriptionId = useMemo(() => nanoid(), []);
  const { hasAccess, project, reloadGroups } = useContext(GroupsIoContext);
  const [editing, setEditing] = useState(false);
  const [group, setGroup] = useState<Group>(props.group);
  const [updateGroup] = usePut<Group, void>(
    `groupsio/${project.shortName}/lists/{groupName}`
  );
  const [deleteGroup] = useDelete<undefined, void>(
    `groupsio/${project.shortName}/lists/{groupName}`
  );
  const formId = useMemo(nanoid, []);
  const [formState, { select }] = useFormState<{
    visibility: Visibility;
    type: Type;
  }>({
    visibility: props.group.visibility,
    type: props.group.type
  });

  const updateGroupAndReloadGroups = async () => {
    try {
      await updateGroup(
        {
          groupName: group.groupName,
          visibility: formState.values.visibility,
          type: formState.values.type
        },
        props.group
      );
      setEditing(false);
      toaster.success('Group successfully updated');
      reloadGroups();
    } catch (error) {
      if (error.name === 'AbortError') return;
      console.log(error);
    }
  };
  const [privateWarningDialog, showPrivateWarningDialog] = useDialog({
    title: `Change "${group.groupName}" to Private`,
    intent: 'warning',
    message: (
      <>
        Are you sure you want to make this group "${group.groupName}" Private?
        <br />
        Private groups cannot be changed to Public later.
      </>
    ),
    async: true,
    onConfirm: updateGroupAndReloadGroups
  });

  const deleteGroupAndReloadGroups = async () => {
    try {
      await deleteGroup(undefined, props.group);
      toaster.success('Group successfully deleted');
      reloadGroups();
    } catch (error) {
      if (error.name === 'AbortError') return;
      console.log(error);
    }
  };
  const [deleteWarningDialog, showDeleteWarningDialog] = useDialog({
    title: `Remove ${props.group.groupName}?`,
    intent: 'danger',
    message: (
      <>
        <p>Are you sure you want to delete group "{props.group.groupName}"?</p>
        <p>
          This permanently removes the entire email archive, the subscriber
          list, and any extras (like a calendar) associated with the list.
        </p>
      </>
    ),
    async: true,
    onConfirm: deleteGroupAndReloadGroups
  });

  useEffect(() => {
    setGroup(props.group);
  }, [props.group]);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (
      formState.values.visibility !== props.group.visibility ||
      formState.values.type !== props.group.type
    ) {
      if (
        props.group.visibility === Visibility.Public &&
        formState.values.visibility === Visibility.Private
      ) {
        showPrivateWarningDialog();
      } else {
        updateGroupAndReloadGroups();
      }
    }
  };

  const externalLink = (
    <a href={props.group.url} target="_blank" rel="noopener noreferrer">
      <Tooltip content={tooltips.actions.link}>
        <IconButton appearance="minimal" iconSize={20} icon="export" />
      </Tooltip>
    </a>
  );
  let actions: JSX.Element;
  if (editing) {
    actions = (
      <Actions>
        {privateWarningDialog}
        <form id={formId} onSubmit={handleSubmit}>
          <Tooltip content={tooltips.actions.save}>
            <IconButton appearance="minimal" iconSize={20} icon="floppy-disk" />
          </Tooltip>
        </form>
        <Tooltip content={tooltips.actions.cancel}>
          <IconButton
            appearance="minimal"
            iconSize={20}
            intent="warning"
            icon="cross"
            onClick={() => {
              setGroup(props.group);
              formState.reset();
              setEditing(false);
            }}
          />
        </Tooltip>
        {externalLink}
      </Actions>
    );
  } else {
    actions = (
      <Actions>
        {privateWarningDialog}
        <Tooltip content={tooltips.actions.edit}>
          <IconButton
            appearance="minimal"
            iconSize={20}
            icon="edit"
            onClick={() => setEditing(true)}
          />
        </Tooltip>
        {deleteWarningDialog}
        <Tooltip content={tooltips.actions.delete}>
          <IconButton
            appearance="minimal"
            iconSize={20}
            intent="danger"
            icon="trash"
            onClick={showDeleteWarningDialog}
          />
        </Tooltip>
        {externalLink}
      </Actions>
    );
  }

  return (
    <>
      <Divider hasAccess={hasAccess} />
      {props.group.flags ? <Flags flags={props.group.flags} /> : <div />}
      <GroupName>
        {props.group.groupName.length > 15 ? (
          <>
            <span data-tip data-for={nameId}>
              <Text>{props.group.groupName.substring(0, 12)}...</Text>
            </span>
            <ReactTooltip id={nameId} effect="solid">
              <Text variant="tooltip">{props.group.groupName}</Text>
            </ReactTooltip>
          </>
        ) : (
          <Text>{props.group.groupName}</Text>
        )}
      </GroupName>
      <GroupVisibility>
        {editing && props.group.visibility !== Visibility.Private ? (
          <Select form={formId} {...select('visibility')}>
            <option value={Visibility.Public}>Public</option>
            <option value={Visibility.Private}>Private</option>
            {props.group.visibility === Visibility.Custom && (
              <option value={Visibility.Custom}>Custom</option>
            )}
          </Select>
        ) : (
          humanVisibility(props.group.visibility)
        )}
      </GroupVisibility>
      <GroupType>
        {editing ? (
          <Select form={formId} {...select('type')}>
            <option value={Type.DiscussionModerated}>
              Moderated Discussion
            </option>
            <option value={Type.DiscussionOpen}>Open Discussion</option>
            <option value={Type.Announcement}>Announcement</option>
            {props.group.type === Type.Custom && (
              <option value={Type.Custom}>Custom</option>
            )}
          </Select>
        ) : (
          humanType(props.group.type)
        )}
      </GroupType>
      <GroupDescription>
        {editing ? (
          <Link
            href={`${props.group.url}/settings`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Edit on Groups.io
          </Link>
        ) : props.group.description.length < 40 ? (
          props.group.description
        ) : (
          <>
            <span data-tip data-for={descriptionId}>
              <Text>{props.group.description.substring(0, 41)}...</Text>
            </span>
            <ReactTooltip id={descriptionId} effect="solid">
              <Text variant="tooltip">{props.group.description}</Text>
            </ReactTooltip>
          </>
        )}
      </GroupDescription>
      {hasAccess && actions}
    </>
  );
};

interface GridElementProps {
  hasAccess: boolean;
}

const Grid = styled.div<GridElementProps>`
  display: grid;
  grid-template-columns: max-content max-content max-content max-content ${props =>
      props.hasAccess ? '1fr max-content' : 'auto'};
  align-items: center;
  grid-column-gap: 32px;
  grid-row-gap: 18px;
`;

const Flags: FC<{ flags: string[] }> = props => {
  const tip = (
    <ul>
      {props.flags.map(f => (
        <li key={f}>{f}</li>
      ))}
    </ul>
  );

  return (
    <TooltipIcon
      icon="warning-sign"
      size={16}
      tip={tip}
      color="intent.warning"
    />
  );
};

const GroupName = styled.div``;
const GroupVisibility = styled.div``;
const GroupType = styled.div``;
const GroupDescription = styled.div``;

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Divider = styled.div<GridElementProps>`
  grid-column-end: span ${props => (props.hasAccess ? '6' : '5')};
  height: 1px;
  background: ${props => props.theme.colors.borderGrey};
`;

export const List = (props: { groups: Group[] }) => {
  const { hasAccess } = useContext(GroupsIoContext);

  return (
    <FloatingCard title="List of Groups">
      {props.groups.length > 0 ? (
        <Grid hasAccess={hasAccess}>
          <div />
          <GroupName>
            <Heading fontSize={3}>
              <TooltipText tip={tooltips.list.name}>Name</TooltipText>
            </Heading>
          </GroupName>
          <GroupVisibility>
            <Heading fontSize={3}>
              <TooltipText tip={tooltips.list.visibility}>
                Visibility
              </TooltipText>
            </Heading>
          </GroupVisibility>
          <GroupType>
            <Heading fontSize={3}>
              <TooltipText tip={tooltips.list.type}>Type</TooltipText>
            </Heading>
          </GroupType>
          <GroupDescription>
            <Heading fontSize={3}>
              <TooltipText tip={tooltips.list.description}>
                Description
              </TooltipText>
            </Heading>
          </GroupDescription>
          {hasAccess && (
            <Actions>
              <Heading fontFamily="sans" fontSize={3}>
                Actions
              </Heading>
            </Actions>
          )}
          {props.groups.map(g => (
            <ListItem group={g} key={g.groupName} />
          ))}
        </Grid>
      ) : (
        <Flex justifyContent="center">
          <Text fontFamily="sans" my={3}>
            This project has no groups
          </Text>
        </Flex>
      )}
    </FloatingCard>
  );
};
