import { Auth0Client } from '@auth0/auth0-spa-js';

const requestScopes =
  'manage:projects read:projects manage:domains manage:forwards manage:github manage:groupsio manage:ci manage:awsaccount manage:dockerhub manage:confluence manage:jira manage:serviceinventory manage:zoom';

export const client = new Auth0Client({
  domain: process.env.REACT_APP_AUTH0_DOMAIN || '',
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
  redirect_uri: window.location.origin,
  audience: process.env.REACT_APP_AUTH0_AUDIENCE || '',
  scope: requestScopes,
  useRefreshTokens: true
});

export async function canWe(
  action: 'read' | 'manage',
  scope: string
): Promise<boolean> {
  const token = await client.getTokenSilently();

  const parts = token.split('.');
  if (parts.length === 3) {
    const encodedPayload = parts[1];
    const decodedPayload = atob(encodedPayload);
    const jsonPayload = JSON.parse(decodedPayload);
    if (jsonPayload.scope) {
      const scopes = jsonPayload.scope.split(' ');
      if (scopes.includes('manage:projects')) return true;
      return scopes.includes(`${action}:${scope}`);
    }
  }

  return false;
}

export function cleanUrl() {
  window.history.replaceState(null, '', '/');
}
