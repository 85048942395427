import { ProjectShortName } from '../project';

/**
 * This status describes whether or not ITX has control of the Source object, typically an Organization.
 * "not_created" means the Source does not exist yet,
 * "none" means ITX has no access,
 * "partial" means it has non-admin access,
 * and full means full admin access.
 */
export enum LinkStatus {
  NotCreated = 'not_created',
  None = 'none',
  Partial = 'partial',
  Full = 'full'
}

/**
 * The shape of an organization returned from the API.
 */
export interface Org {
  project: ProjectShortName;
  organization: string;
  linkedStatus: LinkStatus;
}

/**
 * The expected shape of an Org outgoing to the API endpoint for association.
 */
export interface AssociateOrg extends Org {
  createIfNotExists: boolean;
}
