import React, { FC } from 'react';
import { createPortal } from 'react-dom';
import { Button } from 'rebass';

interface NextButtonProps {
  variant?: string;
  onClick?: () => void;
}

const NextButton: FC<NextButtonProps> = props => {
  const target = document.getElementById('next-button-root');

  if (target) {
    return createPortal(
      <Button variant={props.variant} width={1} onClick={props.onClick}>
        {props.children}
      </Button>,
      target
    );
  }

  return null;
};

export default NextButton;
