export type State = {
  tabs: string[];
  active: string;
};

export type Action =
  | { type: 'register'; label: string }
  | { type: 'setActive'; label: string };

export const initialState = { tabs: [], active: '' };

export const tabReducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'register':
      if (state.tabs.includes(action.label)) return state;
      return {
        ...state,
        tabs: state.tabs.concat([action.label])
      };
    case 'setActive':
      return {
        ...state,
        active: action.label
      };
    default:
      return state;
  }
};
