import React, { FunctionComponent } from 'react';

import Invite from './Invite';
import Create from './Create';
import { Tab, Tabs } from '../../../UI/Tabs';
import { FloatingCard } from '../../../UI/FloatingCard';

interface AddProps {
  hasManagedAccount: boolean;
  inviteAccount: (accountId: string, description: string) => Promise<void>;
  createAccount: (
    description: string,
    email?: string,
    accountType?: string
  ) => Promise<void>;
}

const Add: FunctionComponent<AddProps> = props => {
  return (
    <FloatingCard title="Add an Account">
      <Tabs>
        <Tab label="Create New">
          <Create
            hasManagedAccount={props.hasManagedAccount}
            createAccount={props.createAccount}
          />
        </Tab>
        <Tab label="Invite Existing">
          <Invite inviteAccount={props.inviteAccount} />
        </Tab>
      </Tabs>
    </FloatingCard>
  );
};

export default Add;
