import { lighten, darken } from 'polished';

import { colors } from './colors';

const baseButtonStyles = {
  minWidth: 'initial',
  fontFamily: 'sans',
  fontSize: '14px',
  fontWeight: 600,
  color: 'white',
  borderRadius: 0,
  cursor: 'pointer',
  transition: '0.2s ease-in-out'
};

export const buttons = {
  primary: {
    ...baseButtonStyles,
    bg: 'primary.blue',
    ':hover': {
      bg: darken(0.05, colors.primary.blue)
    }
  },
  confirm: {
    ...baseButtonStyles,
    bg: 'primary.green',
    ':hover': {
      bg: darken(0.05, colors.primary.green)
    }
  },
  danger: {
    ...baseButtonStyles,
    bg: 'intent.danger',
    ':hover': {
      bg: darken(0.1, colors.intent.danger)
    }
  },
  warning: {
    ...baseButtonStyles,
    bg: 'intent.warning',
    color: 'black',
    ':hover': {
      bg: lighten(0.15, colors.intent.warning)
    }
  },
  disabled: {
    ...baseButtonStyles,
    bg: 'borderGrey',
    color: 'grey',
    cursor: 'not-allowed'
  },
  outline: {
    ...baseButtonStyles,
    bg: 'white',
    color: 'black',
    border: '2px solid grey',
    borderColor: 'grey',
    ':hover': {
      bg: darken(0.07, colors.white)
    }
  },
  flat: {
    ...baseButtonStyles,
    py: 1,
    px: 2,
    color: 'black',
    bg: 'white',
    ':hover': {
      bg: darken(0.07, colors.white)
    },
    active: {
      ...baseButtonStyles,
      py: 1,
      px: 2,
      color: 'black',
      bg: 'accent.blue'
    }
  }
};
