import React, { FC } from 'react';
import { Text, Box } from 'rebass';
import { motion } from 'framer-motion';
import { Spinner as Indicator } from 'evergreen-ui';

interface SpinnerProps {
  inline?: boolean;
  message?: string;
}

const Spinner: FC<SpinnerProps> = props => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
        transition: {
          delay: 0.2
        }
      }}
      exit={{ opacity: 0 }}
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: props.inline ? '0' : '32px 0'
      }}
    >
      <Box mb={props.message ? 2 : 0}>
        <Indicator />
      </Box>
      {props.message && (
        <Text
          fontSize={1}
          textAlign="center"
          color="primary.grey"
          fontWeight={700}
          sx={{ maxWidth: '200px' }}
        >
          {props.message}
        </Text>
      )}
    </motion.div>
  );
};

export default Spinner;
