import React, { FC, Component, useReducer, useEffect } from 'react';

import { TabProps } from './Tab';
import { TabContext } from './context';
import { TabLabels } from './TabLabels';
import { tabReducer, initialState } from './reducer';

interface TabsProps {
  children: JSX.Element | JSX.Element[];
}

export const Tabs: FC<TabsProps> = props => {
  const [state, dispatch] = useReducer(tabReducer, initialState);

  useEffect(() => {
    React.Children.forEach(props.children, child => {
      dispatch({
        type: 'register',
        label: ((child as unknown) as Component<TabProps>).props.label
      });
    });
  }, [props.children]);

  let activeTab = React.Children.toArray(props.children).filter(
    tab =>
      ((tab as unknown) as Component<TabProps>).props.label === state.active
  );

  return (
    <TabContext.Provider value={{ state, dispatch }}>
      <TabLabels activeTab={state.active} tabs={state.tabs} />
      {activeTab}
    </TabContext.Provider>
  );
};
