import React, { FC } from 'react';
import { AlertOctagon, AlertTriangle, CheckCircle } from 'react-feather';
import { Box } from 'rebass';

import { AtlassianServiceStatuses } from '../../models/other-services';

interface StatusPillProps {
  status: AtlassianServiceStatuses;
}

export const StatusPill: FC<StatusPillProps> = props => {
  let variant;
  let icon;
  let message;
  switch (props.status) {
    case AtlassianServiceStatuses.Pending:
      variant = 'pill.icon.warning';
      icon = <AlertTriangle size={16} />;
      message = 'Pending';
      break;
    case AtlassianServiceStatuses.NotAccessible:
      variant = 'pill.icon.danger';
      icon = <AlertOctagon size={16} />;
      message = 'Not Accessible';
      break;
    case AtlassianServiceStatuses.Accessible:
      variant = 'pill.icon.confirm';
      icon = <CheckCircle size={16} />;
      message = 'Configured';
      break;
    default:
      return null;
  }

  return (
    <Box variant={variant}>
      {message}
      {icon}
    </Box>
  );
};
