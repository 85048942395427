/**
 * We'll be serializing/deserializing object properties on request and on
 * response so we can maintain the camelcase convention within our source
 * code, but use snakecase in our requests.
 */

// Short version of the project name that will be passed to other services. This should be machine readable. Valid chars include all alphanumerics, '_', and '-'.
// pattern: [a-zA-Z0-9_\-]
export type ProjectShortName = string;

// The project name that users will see
// example: Academy Software Foundation
export type ProjectPrettyName = string;

// Formation status of the project. Projects that are in formation (forming) might still be private, and services should change their behavior based on this
export enum ProjectFormationStatus {
  Forming = 'forming',
  Formed = 'formed'
}

// The primary domain used by a project, where websites and other services are hosted. Once set, this cannot be changed.
// example: aswf.io
export type ProjectCanonicalDomain = string;

export interface Project {
  shortName: ProjectShortName;
  prettyName: ProjectPrettyName;
  formationStatus: ProjectFormationStatus;
  canonicalDomain: ProjectCanonicalDomain;
  seriesName?: string;
}
