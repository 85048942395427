import React, { FunctionComponent } from 'react';
import { Box } from 'rebass';

import Info from './Info';
import AddSite from './Add';
import { FloatingCard } from '../../UI/FloatingCard';
import { Confluence as ConfluenceModel } from '../../../models/other-services';
import { usePermission } from '../../../hooks/use-permission';

interface ConfluenceProps {
  sites: ConfluenceModel[];
  createConfluence: () => Promise<void>;
  trackConfluence: (url: string) => Promise<void>;
  hasManagedAccount: boolean;
  primaryDomain: string;
}

const Confluence: FunctionComponent<ConfluenceProps> = props => {
  const canManage = usePermission('manage', 'confluence');

  if (props.sites.length > 0) {
    return (
      <>
        {props.sites.map(site => (
          <Box mb={4} key={site.id}>
            <Info info={site} />
          </Box>
        ))}
      </>
    );
  }

  if (canManage) {
    return (
      <AddSite
        trackConfluence={props.trackConfluence}
        createConfluence={props.createConfluence}
        hasPrimaryDomain={!!props.primaryDomain}
        hasManagedAccount={props.hasManagedAccount}
      />
    );
  }

  return (
    <FloatingCard title="Not Configured">
      This service has not been configured. Please file a ticket to add a
      Confluence site to this project.
    </FloatingCard>
  );
};

export default Confluence;
