import { HTTPError } from 'ky';
import { useQuery, useMutation, queryCache } from 'react-query';

import { useHTTPErrorToast, useSuccessToast } from '../toasting';
import {
  getUsersByProject,
  deleteUserById,
  inviteUserWithInfo
} from './methods';

export function useZoom(project: string) {
  const {
    status: queryStatus,
    data: users,
    error: queryError,
    isFetching
  } = useQuery(['zoom', project], getUsersByProject);
  const [
    inviteUser,
    { status: inviteStatus, error: inviteError, reset: inviteReset }
  ] = useMutation(inviteUserWithInfo(project), {
    onSuccess: () => {
      queryCache.refetchQueries(['zoom', project]);
    }
  });
  const [
    deleteUser,
    { status: deleteStatus, error: deleteError, reset: deleteReset }
  ] = useMutation(deleteUserById, {
    onSuccess: () => {
      queryCache.refetchQueries(['zoom', project]);
    }
  });

  useSuccessToast('Zoom user invited successfully', inviteStatus, inviteReset);
  useSuccessToast('Zoom user deleted successfully', deleteStatus, deleteReset);

  useHTTPErrorToast(
    'Error fetching Zoom users',
    queryError as HTTPError | undefined
  );
  useHTTPErrorToast(
    'Error inviting Zoom user',
    inviteError as HTTPError | undefined
  );
  useHTTPErrorToast(
    'Error deleting Zoom user',
    deleteError as HTTPError | undefined
  );

  return {
    users,
    inviteUser,
    deleteUser,
    isFetching,
    status: {
      query: queryStatus,
      invite: inviteStatus,
      delete: deleteStatus
    },
    errors: {
      query: queryError,
      invite: inviteError,
      delete: deleteError
    }
  };
}
