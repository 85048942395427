import React from 'react';
import { Box, Flex } from 'rebass';
import { Tooltip, Icon } from 'evergreen-ui';

const tooltips = {
  recipient: {
    delete: 'Remove recipient'
  }
};

export const Recipient = (props: {
  deletable?: boolean;
  onDelete?: (recipient: string) => void;
  children: string;
}) => {
  return (
    <Box
      m="4px"
      px="10px"
      py="6px"
      fontSize="14px"
      color="white"
      bg="primary.blue"
      sx={{
        display: 'inline-block',
        borderRadius: 0
      }}
    >
      <Flex alignItems="center" justifyContent="center">
        {props.children}
        <Flex justifyContent="center" alignItems="center" ml={2}>
          {props.deletable && (
            <Tooltip content={tooltips.recipient.delete}>
              <Icon
                color="white"
                icon="delete"
                onClick={
                  props.onDelete
                    ? () => props.onDelete && props.onDelete(props.children)
                    : undefined
                }
              />
            </Tooltip>
          )}
        </Flex>
      </Flex>
    </Box>
  );
};
