import { lighten } from 'polished';

import { colors } from './colors';
import { baseHeadingStyles } from './text';

const inputStyles = {
  color: 'black',
  fontFamily: 'sans',
  padding: '7px',
  border: 'none',
  borderWidth: '2px',
  borderBottomStyle: 'solid',
  borderColor: 'accent.blue',
  fontSize: '16px',
  width: '100%',
  transition: '0.2s ease-in-out',
  '::placeholder': {
    color: lighten(0.3, colors.primary.grey),
    opacity: 1
  },
  ':focus': {
    borderColor: 'brand',
    outline: 'none',
    bg: lighten(0.22, colors.accent.blue)
  }
};

export const forms = {
  label: {
    ...baseHeadingStyles,
    display: 'flex',
    alignItems: 'center',
    color: 'primary.grey',
    fontSize: '12px',
    width: '100%'
  },
  input: {
    ...inputStyles,
    flexGrow: 1
  },
  textarea: {
    ...inputStyles,
    borderTopStyle: 'solid',
    borderLeftStyle: 'solid',
    borderRightStyle: 'solid',
    borderTopColor: 'borderGrey',
    borderLeftColor: 'borderGrey',
    borderRightColor: 'borderGrey',
    resize: 'vertical'
  },
  select: {
    ...inputStyles,
    borderRadius: '0px',
    ':disabled': {
      color: 'gainsboro',
      borderBottomColor: 'gainsboro'
    }
  },
  checkbox: {
    cursor: 'pointer',
    color: 'primary.green',
    display: {
      cursor: 'cursor',
      color: 'black'
    }
  }
};
