import { integer, TimeBase } from '.';

/**
 * We'll be serializing/deserializing object properties on request and on
 * response so we can maintain the camelcase convention within our source
 * code, but use snakecase in our requests.
 */

// minimum: 0, default: 3600
export type TTL = integer;

export enum ZoneRecordType {
  A = 'A',
  AAAA = 'AAAA',
  ALIAS = 'ALIAS',
  CAA = 'CAA',
  CNAME = 'CNAME',
  DNSKEY = 'DNSKEY',
  DS = 'DS',
  HINFO = 'HINFO',
  MX = 'MX',
  NAPTR = 'NAPTR',
  NS = 'NS',
  POOL = 'POOL',
  PTR = 'PTR',
  SOA = 'SOA',
  SPF = 'SPF',
  SRV = 'SRV',
  SSHFP = 'SSHFP',
  TXT = 'TXT',
  URL = 'URL'
}

export enum ZoneRecordRegion {
  SV1 = 'SV1',
  ORD = 'ORD',
  IAD = 'IAD',
  AMS = 'AMS',
  TKO = 'TKO'
}

interface ZoneBase extends TimeBase {
  id: integer;
  name: string;
}

// Represents a DNS Zone
export interface Zone extends ZoneBase {
  reverse: boolean;
}

// Represents a DNS record
// tslint:disable is for `type` attribute
// tslint:disable:no-reserved-keywords
export interface ZoneRecord extends ZoneBase {
  zoneId: string;
  content: string;
  ttl: TTL;
  priority: integer; // default: 0
  type: ZoneRecordType;
  regions: [ZoneRecordRegion] | [];
  systemRecord: boolean; // Returns true if a record is not modifiable
  createdAt: string;
  updatedAt: string;
}

export enum ZoneTemplateValues {
  Pantheon = 'pantheon_multisite',
  Netlify = 'netlify',
  GitHubPages = 'github_pages'
}

export interface ZoneTemplate extends ZoneBase {
  content: string;
  template: ZoneTemplateValues;
  domain: string;
}

export interface ZoneTemplateBody {
  name: string;
  template: ZoneTemplateValues;
  content?: string;
}
