import React, { FC } from 'react';
import { Box, Link } from 'rebass';
import { ExternalLink } from 'react-feather';

interface LinkPillProps {
  href: string;
}

export const LinkPill: FC<LinkPillProps> = props => (
  <Link href={props.href} sx={{ textDecoration: 'none' }}>
    <Box variant="pill">
      Ticket
      <ExternalLink size={16} />
    </Box>
  </Link>
);
