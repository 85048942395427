import React, { ReactNode } from 'react';
import { Flex, Text, TextProps } from 'rebass';
import { Icon } from 'evergreen-ui';
import { withTheme } from 'emotion-theming';
import { lighten } from 'polished';
import ReactTooltip from 'react-tooltip';
import nanoid from 'nanoid';

interface TooltipTextProps extends TextProps {
  children: ReactNode;
  theme: any;
  tip: string | JSX.Element;
}

export const TooltipText = withTheme((props: TooltipTextProps) => {
  const { tip, children, theme, css, ...rest } = props;
  const id = nanoid();
  return (
    <Flex alignItems="center">
      <Text mr={1} fontFamily="sans" {...rest}>
        {children}
      </Text>
      {tip && (
        <>
          <span data-tip data-for={id} style={{ lineHeight: 'inherit' }}>
            <Icon
              icon="info-sign"
              size={12}
              color={lighten(0.2, theme!.colors!.primary.grey!)}
            />
          </span>
          <ReactTooltip id={id} effect="solid">
            <Text variant="tooltip">{tip}</Text>
          </ReactTooltip>
        </>
      )}
    </Flex>
  );
});
