import { toaster } from 'evergreen-ui';
import { toCamel, toSnake } from 'snake-camel';
import ky, { BeforeRequestHook, AfterResponseHook } from 'ky';

import { client } from './auth';

const beforeRequest: BeforeRequestHook[] = [
  async (_input, options): Promise<void> => {
    const token = await client.getTokenSilently();
    const headers = options.headers as Headers;
    // tslint:disable-next-line: no-backbone-get-set-outside-model
    headers.set('Authorization', `Bearer ${token}`);
  },
  (_input, options): void => {
    if (options.body) {
      const json = JSON.parse(options.body as string);
      const snake = toSnake(json);
      options.body = JSON.stringify(snake);
    }
  }
];

const afterResponse: AfterResponseHook[] = [
  async (_input, _options, response) => {
    const init: ResponseInit = {
      headers: response.headers,
      status: response.status,
      statusText: response.statusText
    };

    let body;
    if (response.status !== 204) {
      try {
        const json = await response.json();
        const camel = parseBodyToCamel(json);
        if (!response.ok) {
          init.statusText = camel.message;
        }
        body = JSON.stringify(camel);
      } catch (e) {
        toaster.danger(`Error decoding response: ${JSON.stringify(e)}`);
      }
    }

    return new Response(body, init);
  }
];

function getApiBaseUrl() {
  const domain = process.env.REACT_APP_API_BASE_URL || '';

  return new URL(domain);
}

function parseBodyToCamel(body: object) {
  let camelBody;

  if (Array.isArray(body)) {
    camelBody = body.map(e => {
      return toCamel(e);
    });
  } else {
    camelBody = toCamel(body);
  }

  return camelBody;
}

export default ky.extend({
  prefixUrl: getApiBaseUrl(),
  retry: 1,
  timeout: 30000, // NOTE: `ky` Timeout is in milliseconds
  hooks: {
    beforeRequest,
    afterResponse
  }
});
