import React, { FC } from 'react';
import { Box } from 'rebass';
import { AnimatePresence } from 'framer-motion';

interface CardAreaProps {
  gridArea: string;
}

const CardArea: FC<CardAreaProps> = props => (
  <Box sx={{ gridArea: props.gridArea }}>
    <AnimatePresence exitBeforeEnter>{props.children}</AnimatePresence>
  </Box>
);

export default CardArea;
