import React, { useCallback, FunctionComponent } from 'react';
import { Flex, Button, Box } from 'rebass';
import { Input, Label } from '@rebass/forms';
import { useFormState } from 'react-use-form-state';

interface InviteFormValues {
  accountId: string;
  description: string;
}

interface InviteProps {
  inviteAccount: (accountId: string, description: string) => Promise<void>;
}

const Invite: FunctionComponent<InviteProps> = props => {
  const [formData, { text }] = useFormState<InviteFormValues>();
  const onSubmit = useCallback(
    async e => {
      e.preventDefault();
      props.inviteAccount(
        formData.values.accountId,
        formData.values.description
      );
    },
    [formData.values.accountId, formData.values.description, props]
  );

  return (
    <Box as="form" mt={3} onSubmit={onSubmit}>
      <Flex mb={2}>
        <Box width={1 / 3} mr={3}>
          <Label>Account ID</Label>
          <Input {...text('accountId')} required placeholder="123456789012" />
        </Box>
        <Box width={2 / 3} ml={3}>
          <Label>Description</Label>
          <Input
            {...text('description')}
            required
            placeholder="Account used for..."
          />
        </Box>
      </Flex>
      <Flex justifyContent="flex-end">
        <Button variant="confirm">Invite</Button>
      </Flex>
    </Box>
  );
};

export default Invite;
