import React, { FC } from 'react';
import { IconButton, Tooltip as IconTooltip, Position } from 'evergreen-ui';
import { Box, Flex, Card, Heading, Text } from 'rebass';

import { Config, ServiceTypes } from '../../../models/ci';
import {
  stringToColors,
  formatName,
  capitalize
} from '../../../utils/string-handlers';
import Tooltip from '../../UI/Tooltip';
import { TooltipText } from '../../UI/TooltipText';
import { useDialog } from '../../../hooks/use-dialog';

interface RepoProps {
  editConfig: (id: number) => void;
  deleteConfig: (id: number) => Promise<void>;
  config: Config;
}

const tooltips = {
  status: (
    <ul>
      <li>
        Not Ready: There is an issue with a dependent services for the project.
      </li>
      <li>
        Ready: All services exist and the CI configuration is ready to be
        submitted to the repository.
      </li>
      <li>Submitted: The CI configuration has been successfully submitted.</li>
      <li>
        Verified: The CI configuration was successfully verified by the
        platform.
      </li>
      <li>
        Merged: The CI configuration was successfully merged into the
        repository.
      </li>
    </ul>
  ),
  issues: `These issues must be resolved for this configuration to be 'Ready'.`
};

function getStatusFormat(status: string) {
  switch (status) {
    case 'submitted':
      return {
        style: 'pill',
        label: 'Submitted'
      };
    case 'notready':
      return {
        style: 'pill.warning',
        label: 'Not Ready'
      };
    case 'ready':
      return {
        style: 'pill.confirm',
        label: 'Ready'
      };
    case 'error':
      return {
        style: 'pill.danger',
        label: 'Error'
      };
    case 'merged':
      return {
        style: 'pill.confirm',
        label: 'Merged'
      };
    case 'verified':
      return {
        style: 'pill',
        label: 'Verified'
      };
    default:
      return {
        style: 'pill',
        label: capitalize(status)
      };
  }
}

const Repo: FC<RepoProps> = props => {
  const { style, label } = getStatusFormat(props.config.status.state);

  const [dialog, showDialog] = useDialog({
    title: 'Delete Configuration',
    message: `Are you sure you want to delete the CI/CD configuration for "${props.config.repository.repo}"?`,
    async: true,
    intent: 'danger',
    confirmLabel: 'Delete',
    onConfirm: async () => props.deleteConfig(props.config.id)
  });

  return (
    <Card bg="white" pb={3} sx={{ boxShadow: 0 }}>
      {dialog}
      <Flex
        justifyContent="space-between"
        alignItems="center"
        p={2}
        sx={{ borderBottom: '1px solid gainsboro', borderColor: 'borderGrey' }}
      >
        <Tooltip tip={tooltips.status}>
          <Box variant={style}>{label}</Box>
        </Tooltip>
        <Flex alignItems="center">
          <IconTooltip content="Edit Config" position={Position.TOP}>
            <IconButton
              icon="edit"
              appearance="minimal"
              onClick={() => props.editConfig(props.config.id)}
            />
          </IconTooltip>
          <IconTooltip content="Delete Config" position={Position.TOP}>
            <IconButton
              icon="trash"
              appearance="minimal"
              intent="danger"
              onClick={showDialog}
            />
          </IconTooltip>
        </Flex>
      </Flex>
      <Heading
        mt={3}
        mx={3}
        fontSize={3}
        fontFamily="sans"
        sx={{ lineHeight: 1 }}
      >
        {props.config.repository.repo}
      </Heading>
      <Flex flexWrap="wrap" p={3}>
        <Box pr={2} width={1 / 2} mb={2}>
          <Text color="primary.grey" fontSize={1} mb={1}>
            Platform
          </Text>
          <Box
            {...stringToColors(props.config.platform)}
            p={2}
            sx={{ textAlign: 'center', lineHeight: 1.2, borderRadius: '3px' }}
          >
            {formatName(props.config.platform)}
          </Box>
        </Box>
        <Box pl={2} width={1 / 2} mb={2}>
          <Text color="primary.grey" fontSize={1} mb={1}>
            Language
          </Text>
          <Box
            {...stringToColors(props.config.language)}
            p={2}
            sx={{ textAlign: 'center', lineHeight: 1.2, borderRadius: '3px' }}
          >
            {formatName(props.config.language)}
          </Box>
        </Box>

        <Box pr={2} width={1 / 2} mb={2}>
          <Text color="primary.grey" fontSize={1} mb={1}>
            Build Tool
          </Text>
          <Box
            {...stringToColors(props.config.buildtool)}
            p={2}
            sx={{ textAlign: 'center', lineHeight: 1.2, borderRadius: '3px' }}
          >
            {formatName(props.config.buildtool)}
          </Box>
        </Box>

        {props.config.services && (
          <>
            {props.config.services
              .filter(service => service.type.includes(ServiceTypes.Artifacts))
              .map(service => (
                <Box pl={2} width={1 / 2} mb={2} key={service.name}>
                  <Text color="primary.grey" fontSize={1} mb={1}>
                    Artifact Storage
                  </Text>
                  <Box
                    {...stringToColors(service.name)}
                    p={2}
                    sx={{
                      textAlign: 'center',
                      lineHeight: 1.2,
                      borderRadius: '3px'
                    }}
                  >
                    {formatName(service.name)}
                  </Box>
                </Box>
              ))}

            {props.config.services
              .filter(service =>
                service.type.includes(ServiceTypes.Distribution)
              )
              .map(service => (
                <Box pr={2} width={1 / 2} mb={2} key={service.name}>
                  <Text color="primary.grey" fontSize={1} mb={1}>
                    Distribution
                  </Text>
                  <Box
                    {...stringToColors(service.name)}
                    p={2}
                    sx={{
                      textAlign: 'center',
                      lineHeight: 1.2,
                      borderRadius: '3px'
                    }}
                  >
                    {formatName(service.name)}
                  </Box>
                </Box>
              ))}

            {props.config.services
              .filter(service => service.type.includes(ServiceTypes.Containers))
              .map(service => (
                <Box pr={2} width={1 / 2} mb={2} key={service.name}>
                  <Text color="primary.grey" fontSize={1} mb={1}>
                    Containers
                  </Text>
                  <Box
                    {...stringToColors(service.name)}
                    p={2}
                    sx={{
                      textAlign: 'center',
                      lineHeight: 1.2,
                      borderRadius: '3px'
                    }}
                  >
                    {formatName(service.name)}
                  </Box>
                </Box>
              ))}

            {props.config.services
              .filter(service =>
                service.type.includes(ServiceTypes.Documentation)
              )
              .map(service => (
                <Box pr={2} width={1 / 2} mb={2} key={service.name}>
                  <Text color="primary.grey" fontSize={1} mb={1}>
                    Documentation
                  </Text>
                  <Box
                    {...stringToColors(service.name)}
                    p={2}
                    sx={{
                      textAlign: 'center',
                      lineHeight: 1.2,
                      borderRadius: '3px'
                    }}
                  >
                    {formatName(service.name)}
                  </Box>
                </Box>
              ))}
          </>
        )}
      </Flex>
      {props.config.status?.issues?.length && (
        <Box px={3}>
          <Heading color="primary.grey" fontSize={2}>
            <TooltipText tip={tooltips.issues}>Issues</TooltipText>
          </Heading>
          <Box as="ul" py={2}>
            {props.config.status.issues.map(issue => (
              <li key={issue}>{issue}</li>
            ))}
          </Box>
        </Box>
      )}
    </Card>
  );
};

export default Repo;
